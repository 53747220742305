import styled from 'styled-components';

import fonts from '../../style/fonts';
import colors from '../../style/colors';
import mq from '../../style/mq';

export const Wrapper = styled.div`
  display: block;
  width: 60%;
  position: fixed;
  top: -12px;
  left: 20px;
  padding-top: 25px;
  background-color: ${props => props.backgroundColor};
  z-index: 4;

  ${mq.medium`
    padding-top: 30px;
    top: 0;
    left: 40px;
  `}
`;

export const InputField = styled.input`
  padding: 20px 20px 5px 0;
  width: 100%;
  height: 100%;
  border: 0;
  background: none;
  font-family: ${fonts.RobotoBlack};
  font-size: 2em;
  color: ${colors.black};
  box-sizing: border-box;

  &:-webkit-autofill,
  &:-internal-autofill-selected {
    background-color: transparent !important;
  }

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${colors.black};
    opacity: 0.3; /* Firefox */
  }

  ${mq.medium`
    font-size: 4em;
    padding: 20px 20px 20px 0;
  `}
`;
