import React, { useContext, useRef } from 'react';
import { useSpring, useChain, useTransition, animated } from 'react-spring';

import { VideosStore } from '../../stores/VideosStore';
import { GlobalStore } from '../../stores/GlobalStore';

import { ReactComponent as TaglineRepareer } from '../../assets/svg/repareer-het.svg';
import { ReactComponent as TaglineWaardeer } from '../../assets/svg/waardeer-het.svg';

import { Wrapper, Item } from './styles';

const AnimatedWrapper = animated(Wrapper);
const AnimatedItem = animated(Item);

const TaglinesBig = props => {
  const { toggleShowMenu } = useContext(GlobalStore);
  const { color, showTaglines } = useContext(VideosStore);

  const springRef = useRef();
  const { opacity, ...rest } = useSpring({
    ref: springRef,
    from: { opacity: 0 },
    to: { opacity: showTaglines ? 1 : 0 },
  });

  const items = ['waardeer', 'repareer'];

  const transRef = useRef();
  const transitions = useTransition(showTaglines ? items : [], item => item, {
    ref: transRef,
    unique: true,
    trail: showTaglines ? 1200 / items.length : 0,
    duration: showTaglines ? 150 : 25,
    from: { opacity: 0, transform: `translate3d(0, -15px, 0)` },
    enter: { opacity: 1, transform: `translate3d(0, -40px, 0)` },
    leave: { opacity: 0, transform: `translate3d(0, -40px, 0)` },
    onDestroyed: () => {
      toggleShowMenu(true);
      props.history.push('/repareren');
    },
  });

  useChain(showTaglines ? [springRef, transRef] : [transRef, springRef]);

  return (
    <AnimatedWrapper style={{ ...rest, opacity }} backgroundColor={color}>
      {transitions.map(({ item, key, props }) => (
        <AnimatedItem key={key} style={{ ...props }}>
          {item === 'repareer' && <TaglineRepareer />}
          {item === 'waardeer' && <TaglineWaardeer />}
        </AnimatedItem>
      ))}
    </AnimatedWrapper>
  );
};

export default TaglinesBig;
