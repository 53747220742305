import styled from 'styled-components';

import fonts from '../../style/fonts';
import colors from '../../style/colors';
import mq from '../../style/mq';

export const Content = styled.span`
  position: relative;
  display: inline-block;
  font-family: ${fonts.RobotoBlack};
  font-size: 26px;
  line-height: 34px;
  margin-right: 5px;
  color: ${props => (props.option ? colors.white : colors.black)};
  cursor: ${props => (props.option ? 'pointer' : 'auto')};

  &:after {
    display: ${props => (props.option ? 'block' : 'none')};
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    background-color: ${colors.white};
    height: 3px;
    width: 100%;
    transition: height 0.1s linear;
  }

  &:hover {
    &:after {
      height: 6px;
    }
  }

  ${mq.medium`
    font-size: 60px;
    line-height: 70px;
    margin-right: 10px;
  `}
`;
