import React, { useContext } from 'react';

import { RepairStore } from '../../stores/RepairStore';
import { OverlayStore } from '../../stores/OverlayStore';
import { GlobalStore } from '../../stores/GlobalStore';
import { VideosStore } from '../../stores/VideosStore';
import { useMaps } from '../../stores/maps';

import { goToResultEvent, addOptionToStoryEvent } from '../../helpers/analytics';

import Button from '../../components/Button';
import ButtonLink from '../../components/ButtonLink';
import { MapOverview } from '../../components/MapOverview';

import { Wrapper } from './styles';

const getUrl = (option, selectedProduct) => {
  switch (true) {
    case option.link === 'youtube':
      return `https://www.youtube.com/results?search_query=${selectedProduct}+repareren`;
    case option.link === 'peerby':
      return `https://www.peerby.com/`;
    case option.link === 'google-maps-gereedschap':
      return `https://www.google.nl/maps/search/gereedschap+${selectedProduct}+repareren`;
    case option.link === 'google-maps-repair-cafe':
      return `https://www.google.nl/maps/search/${selectedProduct}+repair+cafe`;
    case option.link === 'google-maps':
      return `https://www.google.nl/maps/search/${selectedProduct}+repareren`;
    default:
      return '';
  }
};

const handleClickOption = (option, addToStory, toggleOverlay, setProduct, selectedProduct, setRandomColor, cookies) => {
  const about = option.nextStory === 98;
  const search = option.nextStory === 99;
  const link = option.nextStory === 100;
  const product = option.product;

  switch (true) {
    case about:
      toggleOverlay('about', true);
      if (!cookies) {
        addOptionToStoryEvent('Meer info', 1);
      }
      break;
    case search:
      toggleOverlay('search', true);
      break;
    case link:
      if (option.link === 'youtube' && !cookies) {
        goToResultEvent('Ik heb alles', 'Youtube');
      } else if (option.link === 'peerby' && !cookies) {
        goToResultEvent('Lenen', 'Peerby');
      }

      const url = getUrl(option, selectedProduct);
      window.open(url, '_blank');
      break;
    case product:
      addToStory(option.text, true, option.nextStory, false, cookies);
      setRandomColor();
      setProduct(option.text);
      break;
    default:
      addToStory(option.text, true, option.nextStory, false, cookies);
      setRandomColor();
      break;
  }
};

const Options = () => {
  const { audio, currentAudio, addToStory, setProduct, selectedProduct } = useContext(RepairStore);
  const { setRandomColor } = useContext(VideosStore);
  const { toggleOverlay } = useContext(OverlayStore);
  const { cookies } = useContext(GlobalStore);
  const { query, setQuery } = useMaps();

  const options = audio[currentAudio].options;

  return (
    <Wrapper>
      {options.map((option, i) => {
        // option = link (youtube/peerby)
        if (option.nextStory === 100) {
          return (
            <ButtonLink
              key={`option-${i}`}
              isActive
              option={option}
              onClick={() =>
                handleClickOption(option, addToStory, toggleOverlay, setProduct, selectedProduct, null, cookies)
              }
            />
          );
        }

        // option = repair cafes
        if (option.nextStory === 101) {
          if (query) {
            setQuery(null);
          }
          return <MapOverview key={`option-${i}`} query={false} />;
        }

        if (option.nextStory === 102) {
          if (!query || (query && selectedProduct !== query.product)) {
            setQuery({ product: selectedProduct, category: 'experts' });
          }
          return <MapOverview key={`option-${i}`} query={selectedProduct} />;
        }

        // option = google maps (buy tools)
        if (option.nextStory === 103) {
          if (!query || (query && selectedProduct !== query.product)) {
            setQuery({ product: selectedProduct, category: 'gereedschap' });
          }
          return <MapOverview key={`option-${i}`} query={selectedProduct} />;
        } else {
          // option = product
          return (
            <Button
              key={`option-${i}`}
              isActive
              delayIndex={i}
              onClick={() =>
                handleClickOption(option, addToStory, toggleOverlay, setProduct, selectedProduct, setRandomColor)
              }
            >
              {option.text}
            </Button>
          );
        }
      })}
    </Wrapper>
  );
};

export default Options;
