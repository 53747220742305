export function addSearchOptionToStoryEvent(option) {
  if (window) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'sireButton',
      eventCategory: 'Button Click',
      eventAction: 'Fase: Productkeuze',
      eventLabel: `Knop: ${option}`,
    });
  }
}
