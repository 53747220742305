import React, { useContext } from 'react';

import { videos } from '../data/videos';
import colors from '../style/colors';

import { getQuery } from '../helpers/getQuery';
import checkIfMobile from '../helpers/checkIfMobile';

export const VideosStore = React.createContext();

export class VideosProvider extends React.Component {
  state = {
    video: null,
    loading: true,
    playing: false,
    color: '',
    colorRgb: '',
    showTaglines: false,
    videoEnded: false,
  };

  isMobile = checkIfMobile();

  colorsArr = [
    {
      color: colors.yellow,
      colorRgb: colors.yellowRgb,
    },
    {
      color: colors.red,
      colorRgb: colors.redRgb,
    },
    { color: colors.purple, colorRgb: colors.purpleRgb },
    {
      color: colors.blue,
      colorRgb: colors.blueRgb,
    },
  ];

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    this.setRandomVideo();
  }

  getRandomVideoIndex = color => {
    switch (true) {
      case color === 'blue':
        return 0;
      case color === 'red':
        return 1;
      case color === 'purple':
        return 2;
      case color === 'yellow':
        return 3;
      default:
        return Math.floor(Math.random() * videos.length);
    }
  };

  /**
   * setRandomVideo - Set a random video
   */
  setRandomVideo = () => {
    const params = getQuery();
    const color = params ? params.color : '';
    const randomIndex = this.getRandomVideoIndex(color);

    const video = videos[randomIndex];

    // set body color
    document.body.style.backgroundColor = video.color;

    this.setState({
      video,
      color: video.color,
      colorRgb: video.colorRgb,
      loading: true,
    });
  };

  setRandomColor = () => {
    const filteredArr = this.colorsArr.filter(item => {
      return item.color !== this.state.color;
    });
    const randomIndex = Math.floor(Math.random() * filteredArr.length);
    const item = filteredArr[randomIndex];

    document.body.style.backgroundColor = item.color;

    this.setState(prevState => ({
      ...prevState,
      color: item.color,
      colorRgb: item.colorRgb,
    }));
  };

  /**
   * startVideo - Start the video
   */
  startVideo = () => {
    this.setState({
      playing: true,
    });
  };

  /**
   * Toggle video loading - Toggle the video loading
   */
  toggleVideoLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  };

  /**
   * handleVideoProgress
   *
   * @param {Number} playedSeconds - The amount of seconds the audio has played
   */
  handleVideoProgress = (playedSeconds, toggleShowMenu) => {
    if (!this.isMobile) {
      if (playedSeconds > 10 && !this.state.showTaglines && !this.state.videoEnded) {
        this.toggleTaglines(true);
      }

      if (playedSeconds > 13) {
        this.toggleTaglines(false);
      }
    }

    if (this.isMobile) {
      if (playedSeconds > 5 && !this.state.showTaglines && !this.state.videoEnded && this.state.color !== '#a84d97') {
        this.toggleTaglines(true);
      }

      if (playedSeconds > 4 && !this.state.showTaglines && !this.state.videoEnded && this.state.color === '#a84d97') {
        this.toggleTaglines(true);
      }

      if (playedSeconds > 7 && this.state.color === '#a84d97') {
        this.toggleTaglines(false);
      }

      if (playedSeconds > 8) {
        this.toggleTaglines(false);
      }
    }
  };

  handleVideoEnded = () => {
    this.setState({
      videoEnded: true,
    });
  };

  toggleTaglines = value => {
    this.setState({
      showTaglines: value,
    });
  };

  render() {
    return (
      <VideosStore.Provider
        value={{
          startVideo: this.startVideo,
          setRandomColor: this.setRandomColor,
          toggleVideoLoading: this.toggleVideoLoading,
          handleVideoProgress: this.handleVideoProgress,
          handleVideoEnded: this.handleVideoEnded,
          toggleTaglines: this.toggleTaglines,
          currentVideo: this.state.video,
          playing: this.state.playing,
          loading: this.state.loading,
          color: this.state.color,
          colorRgb: this.state.colorRgb,
          showTaglines: this.state.showTaglines,
          videoEnded: this.state.videoEnded,
        }}
      >
        {this.props.children}
      </VideosStore.Provider>
    );
  }
}

export const videosConsumer = VideosStore.Consumer;

export const useVideosStore = () => useContext(VideosStore);
