import React, { useContext } from 'react';

import { VideosStore } from '../../stores/VideosStore';

import { LoaderWrapper, Spinner } from './styles';

const Loader = props => {
  const { colorRgb } = useContext(VideosStore);

  return (
    <LoaderWrapper>
      <Spinner small={props.small} color={colorRgb} />
    </LoaderWrapper>
  );
};

export default Loader;
