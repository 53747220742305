import React, { useContext } from 'react';

import { setLocalStorage, getLocalStorage } from '../helpers/localStorage';

export const GlobalStore = React.createContext();

export class GlobalProvider extends React.Component {
  state = {
    soundMuted: false,
    showMenu: true,
    cookies: false,
  };

  componentDidMount() {
    const cookies = getLocalStorage('sire-cookies');

    if (!cookies) {
      this.setState({
        cookies: true,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  /**
   * toggleCookieBar
   */
  toggleCookieBar = () => {
    this.setState({
      cookies: !this.state.cookies,
    });
  };

  /**
   * setCookies
   */
  setCookies = () => {
    setLocalStorage('sire-cookies', 'all');

    this.setState({
      cookies: false,
    });
  };

  /**
   * toggleSoundVolume - Toggle the sound volume
   */
  toggleSoundVolume = () => {
    this.setState({
      soundMuted: !this.state.soundMuted,
    });
  };

  /**
   * toggleShowMenu - Toggle the show menu
   */
  toggleShowMenu = value => {
    this.setState({
      showMenu: value,
    });
  };

  render() {
    return (
      <GlobalStore.Provider
        value={{
          toggleSoundVolume: this.toggleSoundVolume,
          toggleCookieBar: this.toggleCookieBar,
          setCookies: this.setCookies,
          toggleShowMenu: this.toggleShowMenu,
          showMenu: this.state.showMenu,
          soundMuted: this.state.soundMuted,
          cookies: this.state.cookies,
        }}
      >
        {this.props.children}
      </GlobalStore.Provider>
    );
  }
}

export const globalConsumer = GlobalStore.Consumer;

export const useGlobalStore = () => useContext(GlobalStore);
