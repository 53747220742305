import React, { useContext } from 'react';

import { VideosStore } from '../stores/VideosStore';

import Container from '../components/Container';
import Story from '../components/Story';

const Repair = () => {
  const { color } = useContext(VideosStore);

  return (
    <Container backgroundColor={color}>
      <Story />
    </Container>
  );
};

export default Repair;
