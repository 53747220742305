import React, { useContext } from 'react';
import { useSpring, animated } from 'react-spring';

import { useMaps } from '../../stores/maps';
import { GlobalStore } from '../../stores/GlobalStore';

import { goToResultEvent } from '../../helpers/analytics';

import { ReactComponent as LocationIcon } from '../../assets/svg/location.svg';
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';

import {
  LocationCard,
  LocationCardAddress,
  LocationCardFooter,
  LocationCardPhone,
  LocationCardTitle,
  LocationCardWarning,
  LocationWebsite,
  MapHeader,
  MapHeaderLocation,
  MapHeaderClose,
  BackButton,
} from './styles';

import { MapComponent } from '../MapComponent';
import { LocationButton } from '../LocationButton';

const AnimatedMapHeader = animated(MapHeader);

export const MapOverview = () => {
  const {
    activeMarker,
    locationData,
    mapConfig,
    getGeoLocation,
    locationLoading,
    locations,
    setMapActive,
    setActiveMarker,
    setLocationData,
    mapActive,
    setShowLocations,
    setLocations,
    query,
  } = useMaps();
  const { cookies } = useContext(GlobalStore);

  const handleOnClickClose = () => {
    setMapActive(false);
    setActiveMarker(null);
    setLocationData(null);
    setLocations(null);
  };

  const { opacity, xyz } = useSpring({
    from: { opacity: 0, xyz: [0, 0, 0] },
    to: { opacity: mapActive ? 1 : 0, xyz: [0, mapActive ? 0 : -10, 0] },
    delay: mapActive ? 500 : 0,
    onRest: () => {
      mapActive ? setShowLocations(true) : setShowLocations(false);
    },
  });

  return (
    <div>
      <AnimatedMapHeader
        style={{
          opacity: opacity,
          transform: xyz.interpolate((x, y, z) => `translate3d(${x}px, ${y}px, ${z}px)`),
        }}
      >
        {locations && locations.length === 1 && `${locations.length} resultaat`}
        {locations && locations.length > 1 && `${locations.length} resultaten`}

        <MapHeaderLocation>
          <LocationButton disabled={locationLoading} onClick={getGeoLocation}>
            <LocationIcon />
          </LocationButton>
        </MapHeaderLocation>

        <MapHeaderClose>
          <BackButton onClick={() => handleOnClickClose()}>
            <CloseIcon />
          </BackButton>
        </MapHeaderClose>
      </AnimatedMapHeader>

      <MapComponent
        query={query}
        locations={locations}
        center={mapConfig ? mapConfig.center : null}
        zoom={mapConfig ? mapConfig.zoom : null}
      />

      {locationData && query && (
        <LocationCard positionFixed={true} active={activeMarker}>
          <LocationCardFooter>
            <div>
              {locationData.name && <LocationCardTitle>{locationData.name}</LocationCardTitle>}
              {locationData.formatted_address && (
                <LocationCardAddress>{locationData.formatted_address}</LocationCardAddress>
              )}
              {locationData.formatted_phone_number && (
                <LocationCardPhone href={`tel:${locationData.formatted_phone_number}`}>
                  {locationData.formatted_phone_number}
                </LocationCardPhone>
              )}
            </div>
          </LocationCardFooter>
          {locationData.website && (
            <LocationWebsite
              href={locationData.website}
              onClick={() => {
                if (!cookies) {
                  if (query.category === 'gereedschap') {
                    goToResultEvent('Kopen', 'Naar website');
                  } else {
                    goToResultEvent('Laten', 'Naar website');
                  }
                }
              }}
              target="_blank"
            >
              Naar website
            </LocationWebsite>
          )}
        </LocationCard>
      )}

      {locationData && !query && (
        <LocationCard positionFixed={true} active={activeMarker}>
          <LocationCardFooter>
            <div>
              {locationData.NAME && <LocationCardTitle>{locationData.NAME}</LocationCardTitle>}
              {locationData.ADDRESS && <LocationCardAddress>{locationData.ADDRESS}</LocationCardAddress>}
              {locationData.PHONE && (
                <LocationCardPhone href={`tel:${locationData.PHONE}`}>{locationData.PHONE}</LocationCardPhone>
              )}
              {locationData.WARNING && <LocationCardWarning>{locationData.WARNING}</LocationCardWarning>}
            </div>
          </LocationCardFooter>
          {locationData.WEBSITE && (
            <LocationWebsite
              href={locationData.WEBSITE}
              onClick={!cookies ? () => goToResultEvent('Samen', 'Naar website') : null}
              target="_blank"
            >
              Naar website
            </LocationWebsite>
          )}
        </LocationCard>
      )}
    </div>
  );
};
