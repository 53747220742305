import React, { useContext } from 'react';
import { useSpring, animated } from 'react-spring';

import { GlobalStore } from '../../stores/GlobalStore';
import { VideosStore } from '../../stores/VideosStore';
import { RepairStore } from '../../stores/RepairStore';

import checkIfMobile from '../../helpers/checkIfMobile';
import { getDayOfWeek } from '../../helpers/getDayOfWeek';
import useRouter from '../../helpers/useRouter';

import Button from '../../components/Button';

import { ContentWrapper, ButtonsWrapper, Paragraph } from './styles';

const AnimatedContentWrapper = animated(ContentWrapper);

const CookieBar = () => {
  const { startVideo } = useContext(VideosStore);
  const { goToNextStory } = useContext(RepairStore);
  const { cookies, toggleCookieBar, setCookies, toggleShowMenu } = useContext(GlobalStore);
  const { location } = useRouter();

  const isMobile = checkIfMobile();
  const isHome = location.pathname === '/';

  const spring = useSpring({
    opacity: cookies ? 1 : 0,
    xyz: [0, cookies ? 0 : 900, 0],
  });

  const closeCookies = () => {
    toggleCookieBar();

    if (isHome) {
      startVideo();
      toggleShowMenu(false);
    } else if (!isHome) {
      const nextStory = getDayOfWeek();
      goToNextStory(nextStory);
    }
  };

  const acceptCookies = () => {
    const nextStory = getDayOfWeek();

    if (!isMobile && isHome) {
      startVideo();
      toggleShowMenu(false);
      setCookies();
    } else if (isMobile && !isHome) {
      setCookies();
      goToNextStory(nextStory);
    } else if (isMobile && isHome) {
      startVideo();
      toggleShowMenu(false);
      setCookies();
    } else if (!isMobile && !isHome) {
      goToNextStory(nextStory);
      setCookies();
    } else {
      setCookies();
    }
  };

  return (
    <AnimatedContentWrapper
      style={{
        opacity: spring.opacity,
        transform: spring.xyz.interpolate(
          (x, y, z) => `translateY(-50%) translateX(-50%) translate3d(${x}px, ${y}px, ${z}px)`
        ),
      }}
    >
      <Paragraph>
        Wij maken op deze website gebruik van cookies om jouw bezoek makkelijker en persoonlijker te maken. Hiermee
        kunnen wij en derde partijen advertenties aanpassen aan jouw interesses. Door akkoord te gaan accepteer je alle
        cookies.
      </Paragraph>
      <ButtonsWrapper>
        <Button isActive fullWidth secondary onClick={() => acceptCookies()}>
          Akkoord
        </Button>
        <Button isActive fullWidth onClick={() => closeCookies()}>
          Sluiten
        </Button>
      </ButtonsWrapper>
    </AnimatedContentWrapper>
  );
};

export default CookieBar;
