import React, { useContext, useRef } from 'react';
import { animated, useTransition, useSpring, useChain } from 'react-spring/web.cjs';
import styled from 'styled-components';

import { OverlayStore } from '../stores/OverlayStore';
import { VideosStore } from '../stores/VideosStore';

import Overlay from '../components/Overlay';
import Container from '../components/Container';
import Content from '../components/Content';
import Text from '../components/Text';
import Title from '../components/Title';
import Button from '../components/Button';

import checkIfMobile from '../helpers/checkIfMobile';

import mq from '../style/mq';

const AnimatedOverlay = animated(Overlay);
const AnimatedContent = animated(Content);

export const LogoLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 32px;
    width: 60px;
  }

  ${mq.medium`
    svg {
      width: 80px;
    }
  `}
`;

const About = () => {
  const { aboutOverlayActive } = useContext(OverlayStore);
  const { color } = useContext(VideosStore);

  const isMobile = checkIfMobile();

  const overlayRef = useRef();
  const overlayTransitions = useTransition(aboutOverlayActive, null, {
    ref: overlayRef,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 100,
    },
  });

  const contentRef = useRef();
  const contentTransition = useSpring({
    ref: contentRef,
    transform: aboutOverlayActive ? 'translate3d(0, 0px, 0)' : 'translate3d(0, 15px, 0)',
    opacity: aboutOverlayActive ? 1 : 0,
    config: {
      duration: 100,
    },
    delay: aboutOverlayActive ? 200 : 0,
  });

  useChain(aboutOverlayActive ? [overlayRef, contentRef] : [contentRef, overlayRef]);

  return (
    <>
      {overlayTransitions.map(
        ({ item, key, props }) =>
          item && (
            <AnimatedOverlay key={key} style={props}>
              <Container backgroundColor={color}>
                <AnimatedContent style={contentTransition}>
                  <Title>Over deze campagne.</Title>
                  <Text marginBottom={isMobile ? 20 : 40} small>
                    We gooien in Nederland veel waardevolle spullen weg. Wekelijks wordt alleen al 10,9 miljoen kilo
                    elektrische apparaten en textiel afgedankt. En nog veel meer spullen, die na een kleine reparatie
                    vaak nog prima mee kunnen.
                  </Text>
                  <Text marginBottom={isMobile ? 20 : 40} small>
                    Met de campagne ‘Waardeer het, Repareer het’ wil SIRE zoveel mogelijk Nederlanders bewegen hun
                    kapotte spullen te repareren door mensen bewust te maken van de waarde van hun spullen. Of het nu
                    gaat om een lekkend koffiezetapparaat, een kapotte koffer of een spijkerbroek met een gat.
                  </Text>
                  <Text marginBottom={isMobile ? 20 : 40} small>
                    En hoe leuk is het zelf een apparaat weer aan de praat te krijgen of zelf aan de slag te gaan met je
                    favoriete kledingstuk zodat deze weer jaren mee kan? Dat geeft niet alleen veel voldoening, maar
                    pakt ook positief uit voor de wereld en je portemonnee.
                  </Text>
                  <Text marginBottom={isMobile ? 20 : 40} small>
                    Ook jij kunt repareren. Op deze website helpen we je om in een paar stappen een kapot voorwerp te
                    herstellen. En heb je Google Home of een Google Assistant, dan is repareren makkelijker en
                    laagdrempeliger dan ooit met de repareer-assistent.
                  </Text>
                  <Text marginBottom={isMobile ? 20 : 40} small>
                    Dus vanaf nu hoef je beschadigde spullen nooit meer zomaar weg te gooien. Waardeer het. Repareer
                    het! Waar wacht je nog op?
                  </Text>
                  <Button isActive onClick={() => window.location.reload()}>
                    Begin hier
                  </Button>
                  <Text>&nbsp;</Text>

                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                    <Button isActive>
                      <LogoLink target="_blank" href={'https://giphy.com/SIRE_DOESLIEF'}>
                        Stickers
                      </LogoLink>
                    </Button>
                    <Button isActive>
                      <LogoLink target="_blank" href={'https://sire.nl/campagnes/waardeer-het-repareer-het'}>
                        Meer informatie
                      </LogoLink>
                    </Button>
                    <Button isActive>
                      <LogoLink target="_blank" href={`${process.env.PUBLIC_URL}/privacy-verklaring-sire.pdf`}>
                        Privacy
                      </LogoLink>
                    </Button>
                  </div>
                </AnimatedContent>
              </Container>
            </AnimatedOverlay>
          )
      )}
    </>
  );
};

export default About;
