import styled from 'styled-components';

import mq from '../../style/mq';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: calc(100% - 40px);
  z-index: 3;

  ${mq.medium`
    bottom: 40px;
    right: 40px;
    width: calc(100% - 80px);
  `}
`;
