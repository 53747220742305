import colors from '../style/colors';

export const videos = [
  {
    // Broek
    desktop: 'https://vimeo.com/357938172/bde54f1dc5',
    mobile: 'https://vimeo.com/358061207/65c255a17a',
    color: colors.blue,
    colorRgb: colors.blueRgb,
  },
  {
    // Koffie
    desktop: 'https://vimeo.com/357938247/3f70484ae7',
    mobile: 'https://vimeo.com/358061269/252fbc6e15',
    color: colors.red,
    colorRgb: colors.redRgb,
  },
  {
    // Koffer
    desktop: 'https://vimeo.com/357938221/21ae2fd958',
    mobile: 'https://vimeo.com/358061248/969a165d32',
    color: colors.purple,
    colorRgb: colors.purpleRgb,
  },
  {
    // Takel
    desktop: 'https://vimeo.com/357938198/77c96ef4e2',
    mobile: 'https://vimeo.com/358061228/7eb9517e3c',
    color: colors.yellow,
    colorRgb: colors.yellowRgb,
  },
];
