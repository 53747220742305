import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import { VideosStore } from '../stores/VideosStore';
import { GlobalStore } from '../stores/GlobalStore';

import { startEvent } from '../helpers/analytics';

import Container from '../components/Container';
import Video from '../components/Video';
import Button from '../components/Button';
import Loader from '../components/Loader';
import TaglinesBig from '../components/TaglinesBig';

const Home = () => {
  const { startVideo, currentVideo, playing, loading, color, videoEnded } = useContext(VideosStore);
  const { toggleShowMenu, cookies } = useContext(GlobalStore);

  const handleStartVideo = () => {
    startVideo();
    toggleShowMenu(false);

    if (!cookies) {
      startEvent();
    }
  };

  return (
    <Route
      render={({ history }) => (
        <Container backgroundColor={color}>
          {currentVideo && (
            <span>
              <Video history={history} />
              {videoEnded && (
                <div
                  style={{
                    position: 'fixed',
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                    backgroundColor: color,
                    zIndex: '2',
                  }}
                />
              )}
              <TaglinesBig history={history} />
              {playing && (
                <Button
                  isActive
                  positionFixed
                  onClick={() => {
                    toggleShowMenu(true);
                    history.push('/repareren');
                  }}
                >
                  Sla over
                </Button>
              )}
              {loading && <Loader />}
              {!playing && !loading && (
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Button isActive onClick={() => handleStartVideo()}>
                    Start
                  </Button>
                </div>
              )}
            </span>
          )}
        </Container>
      )}
    />
  );
};

export default Home;
