import React, { useContext } from 'react';

import { audio } from '../data/audio';
import { search } from '../data/search';
import { storySuppliedProduct, storySuppliedProductWithSearch } from '../data/story';
import { getDayOfWeek } from '../helpers/getDayOfWeek';
import { addOptionToStoryEvent } from '../helpers/analytics';
import { capitalize } from '../helpers/capitalize';
import { getQuery } from '../helpers/getQuery';
import checkIfMobile from '../helpers/checkIfMobile';

export const RepairStore = React.createContext();

export class RepairProvider extends React.Component {
  words = null;
  wordIndex = 0;
  isMobile = checkIfMobile();
  player = React.createRef();
  start = [0, 1, 2, 3, 4, 5, 6];
  searchResults = search.sort();

  originalState = {
    audio: audio,
    audioPlaying: false,
    audioPaused: false,
    audioProgress: 0,
    audioLoaded: false,
    audioEnded: false,
    startStory: false,
    productSupplied: false,
    currentAudio: this.isMobile ? 200 : getDayOfWeek(),
    showOptions: false,
    story: [],
    searchResults: this.searchResults,
    selectedProduct: '',
    storyActive: false,
    storySkipping: false,
  };

  state = {
    ...this.originalState,
  };

  componentDidMount() {
    const params = getQuery();
    const product = params ? capitalize(params.product) : '';

    if (this.state.audio[this.state.currentAudio]) {
      this.words = [...this.state.audio[this.state.currentAudio].words];
    }

    if (product !== '' && search.includes(product)) {
      this.startStoryFromSuppliedProduct(product);
    } else if (product === 'Anders') {
      this.startStoryFromSuppliedProduct(product, true);
    } else {
      this.setState(prevState => ({
        ...prevState,
        startStory: true,
      }));
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    // go to position in track
    if (prevState.audioPaused !== this.state.audioPaused && !this.state.audioPaused && !this.state.audioEnded) {
      if (this.player && this.player.current) {
        this.player.current.seekTo(this.state.audioProgress, 'seconds');
      }
    }
  }

  startStoryFromSuppliedProduct = (product, openSearch = false) => {
    if (openSearch) {
      this.setState(
        prevState => ({
          ...prevState,
          startStory: true,
          productSupplied: true,
          story: storySuppliedProductWithSearch,
          showOptions: true,
          storyActive: true,
          storySkipping: false,
          audioEnded: true,
          currentAudio: 7,
        }),
        () => {
          const el = document.getElementById('main');
          const scrollHeight = el.scrollHeight;
          window.scrollTo(0, scrollHeight);
        }
      );
    } else {
      // eslint-disable-next-line
      storySuppliedProduct.map((item, i) => {
        if (item.word === 'Broek') {
          item.word = product;
        }
      });

      this.setState(
        prevState => ({
          ...prevState,
          startStory: true,
          productSupplied: true,
          story: storySuppliedProduct,
          storySkipping: false,
          audioEnded: true,
          showOptions: true,
          storyActive: true,
          currentAudio: 8,
        }),
        () => {
          const el = document.getElementById('main');
          const scrollHeight = el.scrollHeight;
          window.scrollTo(0, scrollHeight);
        }
      );
    }
  };

  toggleScrollEvents = toggle => {
    const storyEl = document.getElementById('story');

    if (toggle) {
      document.body.style.overflow = 'hidden';
      storyEl.style.userSelect = 'none';
      document.ontouchmove = function(e) {
        e.preventDefault();
      };
    } else {
      document.body.style.overflow = 'auto';
      storyEl.style.userSelect = 'auto';
      document.ontouchmove = function(e) {
        return true;
      };
    }
  };

  /**
   * handleAudioProgress
   *
   * @param {Number} playedSeconds - The amount of seconds the audio has played
   */
  handleAudioProgress = playedSeconds => {
    let word = {};

    if (this.words.length > 0) {
      word = this.words[0];
    }

    if (word && word.start < playedSeconds) {
      this.words.splice(0, 1);
      this.addToStory(word.part);
    }
  };

  /**
   * handleSearchProductsOnChange - Get the results of the search query for products
   *
   * @param {String} value - The search query
   */
  handleSearchProductsOnChange = value => {
    let results = [];

    if (value !== '') {
      results = search.filter(product => {
        const valueItem = value.toLowerCase();
        const productItem = product.toLowerCase();

        return productItem.includes(valueItem);
      });
    }

    this.setState(prevState => ({
      ...prevState,
      searchResults: results.length > 0 ? results : this.searchResults,
    }));
  };

  handleAudioLoaded = () => {
    if (this.start.includes(this.state.currentAudio)) {
      setTimeout(() => {
        this.setState(prevState => ({
          ...prevState,
          audioLoaded: true,
          audioPlaying: true,
        }));
      }, 800);
    } else {
      this.setState(prevState => ({
        ...prevState,
        audioLoaded: true,
        audioPlaying: true,
      }));
    }
  };

  /**
   * handleStartStory - Start a story
   */
  handleStartStory = () => {
    this.toggleScrollEvents(true);

    if (this.state.audioProgress === 0) {
      this.words = [...this.state.audio[this.state.currentAudio].words];
      this.wordIndex = 0;
    }

    this.setState(prevState => ({
      ...prevState,
      audioPaused: false,
      audioEnded: false,
      storyActive: true,
    }));
  };

  /**
   * handleEndStory - End a story
   */
  handleEndStory = () => {
    this.toggleScrollEvents(false);

    this.setState(prevState => ({
      ...prevState,
      showOptions: true,
      audioPlaying: false,
      audioPaused: false,
      audioEnded: true,
      audioProgress: 0,
    }));
  };

  /**
   * handlePauseStory - Pause a story
   */
  handlePauseStory = () => {
    if (this.player && this.player.current) {
      this.setState(prevState => ({
        ...prevState,
        audioPlaying: false,
        audioPaused: true,
        audioProgress: this.player.current.getCurrentTime(),
      }));
    }
  };

  handleUnPauseStory = () => {
    if (this.player && this.player.current) {
      this.setState(prevState => ({
        ...prevState,
        audioPaused: false,
      }));
    }
  };

  /**
   * handleSkipStory - When user wants to skip the story
   */
  handleSkipStory = () => {
    let leftOverWords = [];
    let amountOfUsedWordsForStory = 0;

    this.setState(prevState => ({
      ...prevState,
      audioPlaying: false,
      storySkipping: true,
      audioEnded: true,
    }));

    // count the words that are already in the story
    this.state.story.forEach((word, index) =>
      word.story === this.state.currentAudio ? amountOfUsedWordsForStory++ : null
    );

    // push the left over words into a array
    this.state.audio[this.state.currentAudio].words.forEach((word, index) =>
      index >= amountOfUsedWordsForStory ? leftOverWords.push(word.part) : null
    );

    // stagger the words and add them to the story
    const addLeftOverWords = setInterval(() => {
      if (leftOverWords.length !== 0) {
        this.addToStory(leftOverWords[0]);
        leftOverWords.shift();
      } else {
        // words ended
        this.setState(prevState => ({
          ...prevState,
          showOptions: true,
          storySkipping: false,
        }));
        this.toggleScrollEvents(false);
        clearInterval(addLeftOverWords);
      }
    }, 50);
  };

  /**
   * goToNextStory - Start the next story
   *
   * @param {Number} nextStory - Which story to go to
   */
  goToNextStory = nextStory => {
    this.words = [...this.state.audio[nextStory].words];
    this.wordIndex = 0;

    this.setState(prevState => ({
      ...prevState,
      currentAudio: nextStory,
      showOptions: false,
      audioLoaded: false,
      audioPlaying: false,
      audioEnded: false,
      audioProgress: 0,
    }));
  };

  /**
   * addToStory - Adding words to the story
   *
   * @param {String} word - The word to add to the story
   * @param {Boolean} option - Is the word a option which is chosen by the user at the end of a story
   * @param {Number} nextStore - Is the word a option which is chosen by the user at the end of a story
   */
  addToStory = (word, option = false, nextStory = 0, search = false, cookies = false) => {
    const newWord = {
      story: this.state.currentAudio,
      word,
      option,
    };

    this.setState(
      prevState => ({
        ...prevState,
        story: [...prevState.story, newWord],
        productSupplied: false,
      }),
      () => {
        if (!this.start.includes(this.state.currentAudio)) {
          const el = document.getElementById('main');
          const scrollHeight = el.scrollHeight;
          window.scrollTo(0, scrollHeight);
        }
      }
    );

    // when option is added, user is ready to go to the next story
    if (option) {
      // end of story
      if (this.state.currentAudio === 15 && word !== 'Nee, ik wil toch repareren!') {
        this.setState(prevState => ({
          ...prevState,
          showGiphy: true,
          showOptions: false,
        }));
      }

      if (!search && !cookies) {
        addOptionToStoryEvent(word, this.state.currentAudio);
      }

      // reset state
      if (word === 'Nee, ik wil toch repareren!') {
        this.setState({
          ...this.originalState,
          currentAudio: 7,
          startStory: true,
        });
      } else {
        this.goToNextStory(nextStory);
      }
    }
  };

  goBackToStory = storyId => {
    const newStory = [];

    this.state.story.forEach((word, index) => {
      if (word.story <= storyId) {
        newStory.push({
          story: word.story,
          word: word.word,
          option: word.option,
        });
      }
    });

    // remove last item from array - which is a selected option
    newStory.pop();

    this.setState(prevState => ({
      ...prevState,
      currentAudio: storyId,
      story: newStory,
      showOptions: true,
      audioLoaded: false,
      audioPlaying: false,
      audioEnded: true,
      showGiphy: false,
    }));
  };

  /**
   * setProduct - set the chosen product
   *
   * @param {String} product - The chosen product
   */
  setProduct = product => {
    this.setState(prevState => ({
      ...prevState,
      selectedProduct: product,
    }));
  };

  render() {
    return (
      <RepairStore.Provider
        value={{
          audio: this.state.audio,
          story: this.state.story,
          currentAudio: this.state.currentAudio,
          audioPlaying: this.state.audioPlaying,
          audioEnded: this.state.audioEnded,
          audioProgress: this.state.audioProgress,
          showOptions: this.state.showOptions,
          searchResults: this.state.searchResults,
          selectedProduct: this.state.selectedProduct,
          storyActive: this.state.storyActive,
          storySkipping: this.state.storySkipping,
          showGiphy: this.state.showGiphy,
          startStory: this.state.startStory,
          productSupplied: this.state.productSupplied,
          handleStartStory: this.handleStartStory,
          handlePauseStory: this.handlePauseStory,
          handleUnPauseStory: this.handleUnPauseStory,
          handleEndStory: this.handleEndStory,
          handleSkipStory: this.handleSkipStory,
          handleAudioLoaded: this.handleAudioLoaded,
          handleAudioProgress: this.handleAudioProgress,
          goToNextStory: this.goToNextStory,
          updateStory: this.updateStory,
          addToStory: this.addToStory,
          goBackToStory: this.goBackToStory,
          setProduct: this.setProduct,
          nextCurrentAudio: this.nextCurrentAudio,
          handleSearchProductsOnChange: this.handleSearchProductsOnChange,
          player: this.player,
        }}
      >
        {this.props.children}
      </RepairStore.Provider>
    );
  }
}

export const repairConsumer = RepairStore.Consumer;

export const useRepairStore = () => useContext(RepairStore);
