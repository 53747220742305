import React from 'react';

import { HamburgerWrapper, HamburgerBox, HamburgerInner } from './styles';

const Hamburger = props => {
  return (
    <HamburgerWrapper {...props}>
      <HamburgerBox>
        <HamburgerInner {...props} />
      </HamburgerBox>
    </HamburgerWrapper>
  );
};

export default Hamburger;
