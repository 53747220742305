export const search = [
  'Winterjas',
  'Jas',
  'Laarzen',
  'Jurk',
  'Trui',
  'T-shirt',
  'Broek',
  'Rits',
  'Tas',
  'Rugzak',
  'Spijkerbroek',
  'Knoop',
  'Kraag',
  'Elastiek',
  'Voering',
  'Jeans',
  'Overhemd',
  'Blouse',
  'Aanhanger',
  'Camper',
  'Versnellingsbak auto',
  'Koppeling auto',
  'Startmotor auto',
  'Versnellingsbak auto',
  'Wiellager auto',
  'Airco auto',
  'Katalysator auto',
  'Band lek auto',
  'Lambdasonde auto',
  'Accu auto',
  'Remmen auto',
  'Turbo auto',
  'Motor auto',
  'Lichten auto',
  'Koeling auto',
  'iPhone',
  'iPad',
  'Samsung Galaxy',
  'eReader',
  'Telefoon',
  'Tablet',
  'GSM',
  'Mobiel',
  'Scherm Apple',
  'Scherm telefoon',
  'Scherm telefoon',
  'Accu telefoon',
  'Home button',
  'Display telefoon',
  'Connector telefoon',
  'iPad',
  'Scherm Apple',
  'Scherm iPhone',
  'Home button iPhone',
  'Scherm iPad',
  'Home button iPad',
  'Espressoapparaat',
  'Volautomaat',
  'Halfautomaat',
  'Pomp espressomachine',
  'Thermostaat espressomachine',
  'Filter espressomachine',
  'Senseo',
  'Dolce Gusto',
  'Koffiezetapparaat',
  'Koffiemachine',
  'Brouwunit koffiezetapparaat',
  'Pomp koffiezetapparaat',
  'Module koffiezetapparaat',
  'Televisie',
  'LED TV',
  'LCD TV',
  'OLED TV',
  'Flatscreen',
  'Plasmascherm',
  'Smart TV',
  'Apple TV',
  'Chromecast',
  'Monitor',
  'Digitale TV',
  'TV ontvanger',
  'Schaatsen',
  'Laarzen',
  'Zolen',
  'Veters',
  'Schoenreparatie',
  'Sandalen',
  'Wandelschoenen',
  'Hardloopschoenen',
  'Sportschoenen',
  'Sneakers',
  'Gymschoenen',
  'Pantoffels',
  'Bergschoenen',
  'Trouwschoenen',
  'Slippers',
  'Laptop',
  'PC',
  'Mac',
  'MacBook',
  'iMac',
  'Computer Apple',
  'Harde schijf',
  'Hard drive',
  'SSD',
  'Printer',
  'Muis',
  'Toetsenbord',
  'Windows',
  'Moederbord',
  'Koeling laptop',
  'Accu laptop',
  'Processor',
  'USB-poort',
  'Lader laptop',
  'MacBook',
  'iMac',
  'Computer Apple',
  'Scherm MacBook',
  'Accu MacBook',
  'Harde schijf Macbook',
  'Lader MacBook',
  'Thunderbolt poort',
  'Touch Bar',
  'Fornuis',
  'Koelkast',
  'Oven',
  'Vaatwasser',
  'Wasmachine',
  'Afzuigkap',
  'Droger',
  'Magnetron',
  'Wasdroger',
  'Wasautomaat',
  'Diepvriezer',
  'Kookplaat',
  'Inductie kookplaat',
  'Inbouwkoelkast',
  'Inbouwoven',
  'Inbouwvaatwasser',
  'Mountainbike',
  'Fiets',
  'Racefiets',
  'Kinderfiets',
  'Fietsband',
  'Fietsverlichting',
  'Versnellingen fiets',
  'Ketting fiets',
  'Zadel fiets',
  'Trapper fiets',
  'Zadel fiets',
  'E-bike',
  'Elektrische fiets',
  'Vespa',
  'Bromfiets',
  'Brommer',
  'Snorscooter',
  'Accu scootercarburateur scooter',
  'Remmen scooter',
  'Schokbreker scooter',
  'Uitlaat scooter',
  'Motordelen scooter',
  'Hogedrukreiniger',
  'Koffer',
  'Kärcher',
  'Steelstofzuiger',
  'Dyson',
  'Kruimeldief',
  'Spiegelreflex camera',
  'Camera',
  'GoPro',
  'Action cam',
  'Apple watch',
  'Versterker',
  'Stereotoren',
  'CD-speler',
  'Cassetterecorder',
  'Platenspeler',
  'Computer',
  'Laptop',
  'Kleding',
  'Rits',
  'Lamp',
  'Radio/cd-speler',
  'Speelgoed',
  'Stofzuiger',
  'Nespresso',
  'Senseo',
];
