import React from 'react';
import { animated, useTransition } from 'react-spring/web.cjs';

import { Content } from './styles';

const AnimatedContent = animated(Content);

const Word = props => {
  const { children, isActive, option, onClick } = props;

  const wordTransitions = useTransition(isActive, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      tension: 250,
      friction: 15,
      duration: 250,
    },
  });

  return (
    <>
      {wordTransitions.map(
        ({ item, key, props }) =>
          item && (
            <AnimatedContent key={key} style={props} option={option} onClick={onClick}>
              {children}
            </AnimatedContent>
          )
      )}
    </>
  );
};

export default Word;
