export const storySuppliedProduct = [
  { story: 5, word: '...En', option: false },
  { story: 5, word: 'repareren,', option: false },
  { story: 5, word: 'dat', option: false },
  { story: 5, word: 'begint', option: false },
  { story: 5, word: 'hier.', option: false },
  { story: 5, word: 'In', option: false },
  { story: 5, word: 'een', option: false },
  { story: 5, word: 'paar', option: false },
  { story: 5, word: 'simpele', option: false },
  { story: 5, word: 'stappen,', option: false },
  { story: 5, word: 'help', option: false },
  { story: 5, word: 'ik', option: false },
  { story: 5, word: 'je', option: false },
  { story: 5, word: 'op', option: false },
  { story: 5, word: 'weg.', option: false },
  { story: 5, word: 'Wat', option: false },
  { story: 5, word: 'wil', option: false },
  { story: 5, word: 'je', option: false },
  { story: 5, word: 'doen', option: false },
  { story: 5, word: 'op', option: false },
  { story: 5, word: 'deze', option: false },
  { story: 5, word: 'mooie', option: false },
  { story: 5, word: 'vrijdag?', option: false },
  { story: 5, word: 'Ik wil iets repareren!', option: true },
  { story: 7, word: 'Fantastisch!', option: false },
  { story: 7, word: 'Repareren', option: false },
  { story: 7, word: 'is', option: false },
  { story: 7, word: 'goed', option: false },
  { story: 7, word: 'voor', option: false },
  { story: 7, word: 'de', option: false },
  { story: 7, word: 'portemonnee,', option: false },
  { story: 7, word: 'voor', option: false },
  { story: 7, word: 'het', option: false },
  { story: 7, word: 'milieu', option: false },
  { story: 7, word: 'en', option: false },
  { story: 7, word: 'voor', option: false },
  { story: 7, word: 'je', option: false },
  { story: 7, word: 'trots.', option: false },
  { story: 7, word: 'Wat', option: false },
  { story: 7, word: 'wil', option: false },
  { story: 7, word: 'je', option: false },
  { story: 7, word: 'repareren?', option: false },
  { story: 7, word: 'Broek', option: true },
  { story: 8, word: 'Wat', option: false },
  { story: 8, word: 'heb', option: false },
  { story: 8, word: 'je', option: false },
  { story: 8, word: 'daarmee', option: false },
  { story: 8, word: 'gedaan?', option: false },
  { story: 8, word: 'Goed,', option: false },
  { story: 8, word: 'dat', option: false },
  { story: 8, word: 'is', option: false },
  { story: 8, word: 'niet', option: false },
  { story: 8, word: 'mijn', option: false },
  { story: 8, word: 'zaak.', option: false },
  { story: 8, word: 'Jij', option: false },
  { story: 8, word: 'waardeert', option: false },
  { story: 8, word: 'je', option: false },
  { story: 8, word: 'spullen.', option: false },
  { story: 8, word: 'En', option: false },
  { story: 8, word: 'dat', option: false },
  { story: 8, word: 'waardeer', option: false },
  { story: 8, word: 'ik.', option: false },
  { story: 8, word: 'Hoe', option: false },
  { story: 8, word: 'wil', option: false },
  { story: 8, word: 'je', option: false },
  { story: 8, word: 'het', option: false },
  { story: 8, word: 'repareren?', option: false },
];

export const storySuppliedProductWithSearch = [
  { story: 5, word: '...En', option: false },
  { story: 5, word: 'repareren,', option: false },
  { story: 5, word: 'dat', option: false },
  { story: 5, word: 'begint', option: false },
  { story: 5, word: 'hier.', option: false },
  { story: 5, word: 'In', option: false },
  { story: 5, word: 'een', option: false },
  { story: 5, word: 'paar', option: false },
  { story: 5, word: 'simpele', option: false },
  { story: 5, word: 'stappen,', option: false },
  { story: 5, word: 'help', option: false },
  { story: 5, word: 'ik', option: false },
  { story: 5, word: 'je', option: false },
  { story: 5, word: 'op', option: false },
  { story: 5, word: 'weg.', option: false },
  { story: 5, word: 'Wat', option: false },
  { story: 5, word: 'wil', option: false },
  { story: 5, word: 'je', option: false },
  { story: 5, word: 'doen', option: false },
  { story: 5, word: 'op', option: false },
  { story: 5, word: 'deze', option: false },
  { story: 5, word: 'mooie', option: false },
  { story: 5, word: 'vrijdag?', option: false },
  { story: 5, word: 'Ik wil iets repareren!', option: true },
  { story: 7, word: 'Fantastisch!', option: false },
  { story: 7, word: 'Repareren', option: false },
  { story: 7, word: 'is', option: false },
  { story: 7, word: 'goed', option: false },
  { story: 7, word: 'voor', option: false },
  { story: 7, word: 'de', option: false },
  { story: 7, word: 'portemonnee,', option: false },
  { story: 7, word: 'voor', option: false },
  { story: 7, word: 'het', option: false },
  { story: 7, word: 'milieu', option: false },
  { story: 7, word: 'en', option: false },
  { story: 7, word: 'voor', option: false },
  { story: 7, word: 'je', option: false },
  { story: 7, word: 'trots.', option: false },
  { story: 7, word: 'Wat', option: false },
  { story: 7, word: 'wil', option: false },
  { story: 7, word: 'je', option: false },
  { story: 7, word: 'repareren?', option: false },
];
