import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { VideosStore } from '../stores/VideosStore';

import Container from '../components/Container';
import Text from '../components/Text';

const NotFound = () => {
  const { color } = useContext(VideosStore);

  return (
    <Container backgroundColor={color}>
      <Text>
        Helaas, deze pagina bestaat niet. <Link to="/">Keer terug.</Link>
      </Text>
    </Container>
  );
};

export default NotFound;
