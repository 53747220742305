import React, { useContext } from 'react';

import { getQuery } from '../helpers/getQuery';
import { capitalize } from '../helpers/capitalize';
import checkIfMobile from '../helpers/checkIfMobile';

export const OverlayStore = React.createContext();

export class OverlayProvider extends React.Component {
  state = {
    aboutOverlayActive: false,
    cookiesOverlayActive: false,
    searchOverlayActive: false,
  };

  isMobile = checkIfMobile();

  componentDidMount() {
    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });

    const params = getQuery();
    const product = params ? capitalize(params.product) : '';

    if (product === 'Anders') {
      this.toggleOverlay('search');
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  /**
   * toggleOverlay - Hide or show the overlay
   *
   * @param {String} type - Which type of overlay
   * @param {Boolean} scroll - Scroll after setting state
   */
  toggleOverlay = (type, scroll = false) => {
    if (type === 'about') {
      this.state.aboutOverlayActive ? this.overlayClosed() : this.overlayOpened();

      this.setState({ aboutOverlayActive: !this.state.aboutOverlayActive }, () => {
        if (scroll) {
          this.state.aboutOverlayActive ? this.scrollToTop() : this.scrollToBottom();
        }
      });
    }

    if (type === 'cookies') {
      this.state.cookiesOverlayActive ? this.overlayClosed() : this.overlayOpened();

      this.setState({ cookiesOverlayActive: !this.state.cookiesOverlayActive }, () => {
        if (scroll) {
          this.state.cookiesOverlayActive ? this.scrollToTop() : this.scrollToBottom();
        }
      });
    }

    if (type === 'search') {
      this.state.searchOverlayActive ? this.overlayClosed() : this.overlayOpened();

      this.setState({ searchOverlayActive: !this.state.searchOverlayActive }, () => {
        if (scroll) {
          this.state.searchOverlayActive ? this.scrollToTop() : this.scrollToBottom();
        }
      });
    }
  };

  overlayOpened = () => {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}`;
  };

  overlayClosed = () => {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  };

  scrollToTop = () => {
    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

    if (this.isMobile && scrollPos < 1) {
      window.scrollTo(0, 1);
    } else {
      window.scrollTo(0, 0);
    }
  };

  scrollToBottom = () => {
    const clientHeight = document.documentElement.clientHeight;
    const scrollHeight = document.body.scrollHeight;

    if (this.isMobile) {
      window.scrollTo(0, clientHeight);
    } else {
      window.scrollTo(0, scrollHeight);
    }
  };

  render() {
    return (
      <OverlayStore.Provider
        value={{
          aboutOverlayActive: this.state.aboutOverlayActive,
          cookiesOverlayActive: this.state.cookiesOverlayActive,
          searchOverlayActive: this.state.searchOverlayActive,
          toggleOverlay: this.toggleOverlay,
        }}
      >
        {this.props.children}
      </OverlayStore.Provider>
    );
  }
}

export const overlayConsumer = OverlayStore.Consumer;

export const useOverlayStore = () => useContext(OverlayStore);
