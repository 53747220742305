import React, { useContext, useRef } from 'react';
import { animated, useTransition, useSpring, useChain } from 'react-spring/web.cjs';

import { OverlayStore } from '../stores/OverlayStore';
import { VideosStore } from '../stores/VideosStore';

import Overlay from '../components/Overlay';
import Container from '../components/Container';
import Content from '../components/Content';
import Text from '../components/Text';
import Title from '../components/Title';

import checkIfMobile from '../helpers/checkIfMobile';

const AnimatedOverlay = animated(Overlay);
const AnimatedContent = animated(Content);

const Cookies = () => {
  const { cookiesOverlayActive } = useContext(OverlayStore);
  const { color } = useContext(VideosStore);

  const isMobile = checkIfMobile();

  const overlayRef = useRef();
  const overlayTransitions = useTransition(cookiesOverlayActive, null, {
    ref: overlayRef,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 100,
    },
  });

  const contentRef = useRef();
  const contentTransition = useSpring({
    ref: contentRef,
    transform: cookiesOverlayActive ? 'translate3d(0, 0px, 0)' : 'translate3d(0, 15px, 0)',
    opacity: cookiesOverlayActive ? 1 : 0,
    config: {
      duration: 100,
    },
    delay: cookiesOverlayActive ? 200 : 0,
  });

  useChain(cookiesOverlayActive ? [overlayRef, contentRef] : [contentRef, overlayRef]);

  return (
    <>
      {overlayTransitions.map(
        ({ item, key, props }) =>
          item && (
            <AnimatedOverlay key={key} style={props}>
              <Container backgroundColor={color}>
                <AnimatedContent style={contentTransition}>
                  <Title>Cookies.</Title>
                  <Text marginBottom={isMobile ? 20 : 40} small>
                    Wij maken op deze website gebruik van cookies. Een cookie is een eenvoudig klein bestandje dat met
                    pagina’s van deze website wordt meegestuurd en door uw browser op uw harde schrijf van uw computer
                    wordt opgeslagen
                  </Text>
                </AnimatedContent>
              </Container>
            </AnimatedOverlay>
          )
      )}
    </>
  );
};

export default Cookies;
