import React, { useContext } from 'react';

import { VideosStore } from '../stores/VideosStore';
import { RepairStore } from '../stores/RepairStore';
import { OverlayStore } from '../stores/OverlayStore';

import Overlay from '../components/Overlay';
import Container from '../components/Container';
import Content from '../components/Content';
import Input from '../components/Input';
import SearchResults from '../components/SearchResults';

const SearchProducts = () => {
  const { color } = useContext(VideosStore);
  const { handleSearchProductsOnChange } = useContext(RepairStore);
  const { searchOverlayActive } = useContext(OverlayStore);

  return (
    <>
      {searchOverlayActive && (
        <Overlay>
          <Input onChange={value => handleSearchProductsOnChange(value)} />
          <Container backgroundColor={color}>
            <Content>
              <SearchResults />
            </Content>
          </Container>
        </Overlay>
      )}
    </>
  );
};

export default SearchProducts;
