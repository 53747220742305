import styled, { css } from 'styled-components';
import { CardWrapper } from '../ButtonLink/styles';
import mq from '../../style/mq';
import colors from '../../style/colors';

export const MapHeader = styled.header`
  box-sizing: border-box;
  align-items: center;
  display: flex;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 25px 20px;
  font-size: 2rem;
  line-height: 1;

  ${mq.medium`
    flex-direction: row;
    padding: 40px;
    font-size: 4rem;
  `};
`;

export const MapHeaderLocation = styled.div`
  margin-left: auto;
`;

export const MapHeaderClose = styled.div`
  margin-left: 10px;

  ${mq.medium`
    margin-left: 20px;
  `};
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

export const LocationCard = styled(CardWrapper)`
  flex-direction: column;
  cursor: initial;
  align-items: stretch;
  transition: 200ms ease-out;
  transition-property: opacity, transform;
  opacity: 0;
  transform: translateY(50px);
  text-align: left;
  max-width: 500px;

  ${props =>
    props.active
      ? css`
          pointer-events: all;
          opacity: 1;
          transform: translateY(0px);
        `
      : css`
          pointer-events: none;
          opacity: 0;
          transform: translateY(50px);
        `};

  ${mq.medium`
      align-items: flex-end;
  `};
`;

export const LocationCardFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mq.medium`
    flex-direction: row;
  `};
`;

export const LocationCardTitle = styled.h3`
  margin: 0 0 10px 0;
  font-family: 'Roboto bold';

  ${mq.medium`
    margin: 0 0 20px 0;
  `};
`;

export const LocationCardAddress = styled.span`
  display: block;
  margin: 0 0 10px 0;
  color: ${colors.grey};
  font-family: 'Roboto regular';

  ${mq.medium`
    margin: 0 0 20px 0;
  `};
`;

export const LocationCardPhone = styled.a`
  display: block;
  margin: 0 0 10px 0;
  font-family: 'Roboto bold';

  ${mq.medium`
    margin: 0 0 20px 0;
  `};
`;

export const LocationCardWarning = styled.span`
  display: block;
  color: ${colors.black};
  font-family: 'Roboto regular';
`;

export const LocationWebsite = styled.a`
  display: inline-flex;
  margin-top: 30px;
  height: 60px;
  width: auto;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 20px;
  background-color: ${colors.black};
  color: ${colors.white};

  ${mq.medium`
    margin-top: 0;
    align-self: flex-end;
    height: 120px;
    font-size: 2rem;
    border-radius: 40px;
    padding: 0 40px;
    flex-shrink: 0;
    margin-left: auto;
    height: 80px;
    width: 190px;
  `}
`;
