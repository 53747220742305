import styled from 'styled-components';

import mq from '../../style/mq';

export const Wrapper = styled.div`
  display: block;
  padding-bottom: 150px;

  ${mq.medium`
    padding-bottom: 300px;
  `}
`;

export const StartStoryWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
