import styled from 'styled-components';

import mq from '../../style/mq';

export const Box = styled.div`
  display: block;
  min-height: 100vh;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : '#fff')};
  box-sizing: border-box;
  padding: 80px 25px 25px;
  transition: background-color 0.4s ease;

  ${mq.medium`
    padding: 150px 50px 40px;

    &:before {
      height: 120px;
    }
  `}
`;
