import React from 'react';

import { Wrapper, Gif } from './styles';

const Giphy = () => {
  return (
    <Wrapper>
      <Gif alt="Zonde!" src="https://media.giphy.com/media/3oEduUJgrJnTH093yw/giphy.gif" />
    </Wrapper>
  );
};

export default Giphy;
