import styled from 'styled-components';

import fonts from '../../style/fonts';
import mq from '../../style/mq';

export const Header = styled.span`
  display: block;
  font-family: ${fonts.RobotoBlack};
  font-size: 3.2em;
  margin-bottom: 30px;
  line-height: 1.2em;

  ${mq.medium`
    font-size: 5em;
  `}

  ${mq.large`
    font-size: 8em;
  `}
`;
