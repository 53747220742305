import React, { useState, useEffect } from 'react';

import { MapsContext } from './MapsContext';
import { usePrevious } from '../../helpers/usePrevious';

import checkIfMobile from '../../helpers/checkIfMobile';

export function MapsProvider({ children }) {
  const [mapActive, setMapActive] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [showLocations, setShowLocations] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [locationLoading, setLocationLoading] = useState(false);
  const [locations, setLocations] = useState(null);
  const [mapConfig, setMapConfig] = useState(null);
  const [query, setQuery] = useState(null);

  const isMobile = checkIfMobile();

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    styles: [
      {
        featureType: 'administrative.land_parcel',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.neighborhood',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.business',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.local',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  };

  const markerOptions = {
    url: isMobile ? '/cluster_icon_small.png' : '/cluster_icon.png',
    height: isMobile ? 75 : 120,
    width: isMobile ? 75 : 120,
    textSize: isMobile ? 30 : 40,
    fontWeight: 700,
    fontFamily: 'Roboto Bold',
  };

  const getGeoLocation = () => {
    setLocationLoading(true);
    setActiveMarker(null);

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          setLocationLoading(false);
          mapInstance.setZoom(12);
          mapInstance.panTo({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          setLocationLoading(false);
        }
      );
    }
  };

  const previousMarker = usePrevious(activeMarker);

  const fetchPlaceDetails = (location, map) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        const google = window.google;
        const userLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

        const request = {
          placeId: location.place_id,
          fields: ['name', 'website', 'formatted_phone_number', 'formatted_address', 'opening_hours'],
        };

        setMapConfig({
          center: userLocation,
          zoom: 13,
        });

        const service = new google.maps.places.PlacesService(map);

        service.getDetails(request, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            setLocationData(place);
          }
        });
      });
    } else {
      const google = window.google;

      const request = {
        placeId: location.place_id,
        fields: ['name', 'website', 'formatted_phone_number', 'formatted_address', 'opening_hours'],
        location: 'Amsterdam',
      };

      setMapConfig({
        center: 'Amsterdam',
        zoom: 13,
      });

      const service = new google.maps.places.PlacesService(map);

      service.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setLocationData(place);
        }
      });
    }
  };

  const fetchPlaces = (query, map) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        const google = window.google;
        const location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        const queryItem = query.category === 'experts' ? `${query.product} repareren` : `bouwmarkt`;

        const request = {
          query: queryItem,
          fields: ['name', 'formatted_address', 'geometry'],
        };

        setMapConfig({
          center: location,
          zoom: 13,
        });

        const service = new google.maps.places.PlacesService(map);

        service.textSearch(request, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            setLocations(results);
          }
        });
      });
    } else {
      const google = window.google;
      const queryItem = query.category === 'experts' ? `${query.product} repareren` : `${query.product} bouwmarkt`;

      const request = {
        query: queryItem,
        fields: ['name', 'formatted_address', 'geometry'],
        location: 'Amsterdam',
      };

      setMapConfig({
        center: 'Amsterdam',
        zoom: 13,
      });

      const service = new google.maps.places.PlacesService(map);

      service.textSearch(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setLocations(results);
        }
      });
    }
  };

  const fetchNewPlaces = (query, map) => {
    if (map && map.center) {
      const google = window.google;
      const queryItem = query.category === 'experts' ? `${query.product} repareren` : `bouwmarkt`;
      const location = map.center;

      const request = {
        query: queryItem,
        fields: ['name', 'formatted_address', 'geometry'],
        location: location,
      };

      const service = new google.maps.places.PlacesService(map);

      service.textSearch(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (locations) {
            const tempLocations = [...locations, ...results];
            const newLocations = tempLocations.reduce((acc, current) => {
              const x = acc.find(item => item.place_id === current.place_id);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
            setLocations(newLocations);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (previousMarker) {
      let icon = '';

      if (query && query.category === 'experts') {
        icon = isMobile ? `${process.env.PUBLIC_URL}/ShopWhiteSmall.png` : `${process.env.PUBLIC_URL}/ShopWhiteBig.png`;
      } else if (query && query.category === 'gereedschap') {
        icon = isMobile ? `${process.env.PUBLIC_URL}/ToolWhiteSmall.png` : `${process.env.PUBLIC_URL}/ToolWhiteBig.png`;
      } else {
        icon = isMobile ? `${process.env.PUBLIC_URL}/CafeWhiteSmall.png` : `${process.env.PUBLIC_URL}/CafeWhiteBig.png`;
      }

      previousMarker.setIcon(icon);
    }

    if (activeMarker) {
      let icon = '';

      if (query && query.category === 'experts') {
        icon = isMobile ? `${process.env.PUBLIC_URL}/ShopBlackSmall.png` : `${process.env.PUBLIC_URL}/ShopBlackBig.png`;
      } else if (query && query.category === 'gereedschap') {
        icon = isMobile ? `${process.env.PUBLIC_URL}/ToolBlackSmall.png` : `${process.env.PUBLIC_URL}/ToolBlackBig.png`;
      } else {
        icon = isMobile ? `${process.env.PUBLIC_URL}/CafeBlackSmall.png` : `${process.env.PUBLIC_URL}/CafeBlackBig.png`;
      }

      activeMarker.setIcon(icon);
    }
  }, [activeMarker, previousMarker, locationData, query, isMobile]);

  const store = {
    mapInstance,
    setMapInstance,
    activeMarker,
    setActiveMarker,
    setLocations,
    setMapActive,
    setMapConfig,
    locationData,
    setLocationData,
    mapOptions,
    markerOptions,
    mapConfig,
    mapActive,
    locationLoading,
    getGeoLocation,
    fetchPlaces,
    fetchPlaceDetails,
    locations,
    showLocations,
    setShowLocations,
    setQuery,
    query,
    firstLoad,
    setFirstLoad,
    fetchNewPlaces,
  };

  return <MapsContext.Provider value={store}>{children}</MapsContext.Provider>;
}
