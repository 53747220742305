import React, { useContext } from 'react';
import { useSpring, animated } from 'react-spring';

import { GlobalStore } from '../../stores/GlobalStore';
import { OverlayStore } from '../../stores/OverlayStore';
import { RepairStore } from '../../stores/RepairStore';
import { VideosStore } from '../../stores/VideosStore';
import { useMaps } from '../../stores/maps';

import useRouter from '../../helpers/useRouter';

import Hamburger from '../Hamburger';
import Taglines from '../Taglines';

import { ReactComponent as SireLogo } from '../../assets/svg/sire-logo.svg';
import { ReactComponent as SoundOnIcon } from '../../assets/svg/sound-on.svg';
import { ReactComponent as SoundOffIcon } from '../../assets/svg/sound-off.svg';

import { Wrapper, Content, Elements, Element, Divider } from './styles';

import checkIfMobile from '../../helpers/checkIfMobile';

const Header = () => {
  const { handlePauseStory, handleUnPauseStory, storyActive } = useContext(RepairStore);
  const { toggleOverlay, aboutOverlayActive, searchOverlayActive, cookiesOverlayActive } = useContext(OverlayStore);
  const { color } = useContext(VideosStore);
  const { toggleSoundVolume, soundMuted, showMenu } = useContext(GlobalStore);
  const { mapActive } = useMaps();
  const { location } = useRouter();

  const overlayTypeActive = aboutOverlayActive ? 'about' : 'search';
  const isOverlayActive = aboutOverlayActive || searchOverlayActive || cookiesOverlayActive;
  const showDivider = location.pathname !== '/' || aboutOverlayActive;
  const isHome = location.pathname === '/';
  const showBgColor = storyActive || aboutOverlayActive;
  const isMobile = checkIfMobile();

  const handleOnClickHamburger = () => {
    if (isOverlayActive) {
      // TODO: Check the window height, only scroll down when there is something to scroll
      if (cookiesOverlayActive) {
        toggleOverlay('cookies');
      } else {
        toggleOverlay(overlayTypeActive, storyActive);
      }

      if (storyActive) {
        handleUnPauseStory();
      }
    } else {
      // TODO: Check the window height, only scroll down when there is something to scroll
      toggleOverlay('about', storyActive);

      if (storyActive) {
        handlePauseStory();
      }
    }
  };

  const containerWidth = () => {
    if (isMobile) {
      return searchOverlayActive ? 'calc(50% - 45px)' : 'calc(100% - 45px)';
    } else {
      return searchOverlayActive ? 'calc(50% - 80px)' : 'calc(100% - 80px)';
    }
  };

  const containerStyles = {
    position: 'fixed',
    top: '0',
    width: containerWidth(),
    right: isMobile ? '25px' : '40px',
    zIndex: 5,
  };

  const spring = useSpring({
    opacity: mapActive || !showMenu ? 0 : 1,
    xyz: [0, mapActive || !showMenu ? -100 : 0, 0],
  });

  return (
    <animated.div
      style={{
        opacity: spring.opacity,
        transform: spring.xyz.interpolate((x, y, z) => `translate3d(${x}px, ${y}px, ${z}px)`),
        ...containerStyles,
      }}
    >
      <Wrapper small={searchOverlayActive} backgroundColor={showBgColor ? color : 'transparent'}>
        {!searchOverlayActive && <Content flex="flex-start">{!isHome && <Taglines />}</Content>}
        <Content flex="flex-end">
          {!searchOverlayActive && (
            <>
              <Elements style={{ display: 'flex' }}>
                {!isMobile && (
                  <Element>
                    <SireLogo />
                  </Element>
                )}

                <Element cursorPointer>
                  {soundMuted ? (
                    <SoundOffIcon onClick={() => toggleSoundVolume()} />
                  ) : (
                    <SoundOnIcon onClick={() => toggleSoundVolume()} />
                  )}
                </Element>
              </Elements>
            </>
          )}
          <Hamburger isActive={isOverlayActive} onClick={() => handleOnClickHamburger()} />
        </Content>
        {showDivider && <Divider small={searchOverlayActive} />}
      </Wrapper>
    </animated.div>
  );
};

export default Header;
