import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';

import useRouter from '../helpers/useRouter';

import Home from './Home';
import Repair from './Repair';
import About from './About';
import SearchProducts from './SearchProducts';
import Cookies from './Cookies';
import NotFound from './NotFound';
import Header from '../components/Header';
import CookieBar from '../components/CookieBar';

const Main = () => {
  const { location } = useRouter();
  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    duration: 500,
  });

  const routeStyles = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    minHeight: '100%',
    width: '100%',
  };

  return (
    <div>
      <CookieBar />
      <Header location={location.pathname} />
      <About />
      <Cookies />
      <SearchProducts />
      {transitions.map(({ item, props, key }) => (
        <animated.div id="main" key={key} style={{ ...props, ...routeStyles }}>
          <Switch location={item}>
            <Route exact path="/" component={Home} />
            <Route path="/repareren" component={Repair} />
            <Route component={NotFound} />
          </Switch>
        </animated.div>
      ))}
    </div>
  );
};

export default Main;
