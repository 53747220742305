import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 2;

  > .video-player > div {
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : '#fff')} !important;
  }
`;

export const VideoStyles = {
  position: 'fixed',
  top: '0',
  left: '',
  width: '100%',
  height: '100%',
  zIndex: '-1',
  pointerEvents: 'none',
  overflow: 'hidden',
};
