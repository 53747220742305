/**
 * Check if the current window width is Mobile
 * @returns {boolean}
 */
export default function checkIfMobile(includeTablet = false) {
  if (typeof window !== 'undefined') {
    return (
      ((includeTablet ? window.innerWidth <= 1366 : window.innerWidth < 768) &&
        (typeof window !== 'undefined' && typeof window.orientation !== 'undefined')) ||
      navigator.userAgent.indexOf('IEMobile') !== -1
    );
  }

  return false;
}
