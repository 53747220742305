export default {
  white: '#ffffff',
  black: '#000000',
  blue: '#56a7dd',
  yellow: '#f0a31f',
  purple: '#a84d97',
  red: '#eb5c3c',
  grey: 'rgba(0, 0, 0, 0.4)',

  blueRgb: [86, 168, 221],
  yellowRgb: [240, 165, 31],
  purpleRgb: [168, 77, 151],
  redRgb: [235, 92, 60],
};
