import { fontFace, normalize } from 'polished';
import { createGlobalStyle } from 'styled-components';

import fonts from './fonts';
import mq from './mq';

export const GlobalStyle = createGlobalStyle`
    ${normalize()}

    ${fontFace({
      fontFamily: 'Roboto Regular',
      fontFilePath: '/fonts/Roboto-Regular',
      fontWeight: 'normal',
    })}
    
    ${fontFace({
      fontFamily: 'Roboto Bold',
      fontFilePath: '/fonts/Roboto-Bold',
      fontWeight: 'bold',
    })}

    ${fontFace({
      fontFamily: 'Roboto Black',
      fontFilePath: '/fonts/Roboto-Black',
      fontWeight: 900,
    })}

    * {
      -webkit-overflow-scrolling: touch;
    }

    html, body {
      height: 100%;
      scroll-behavior: smooth;
    }

    body {
      font-family: ${fonts.RobotoBold};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      line-height: 1.8;
      min-width: 320px;
      width: 100%;
      font-size: 10px;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    iframe {
      width: 100% !important;
      height: 56.25% !important; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
      min-height: 110% !important;
      min-width: 177.77% !important; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      ${mq.medium`
        width: 100vw !important;
        height: 56.25vw !important; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh !important;
        min-width: 177.77vh !important; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
      `}
    }
`;
