import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
`;

export const Gif = styled.img`
  display: block;
  height: 100%;
  width: 100%;
`;
