export function goToResultEvent(currentPhase, buttonText) {
  if (window) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'sireButton',
      eventCategory: 'Button Click',
      eventAction: `Fase: ${currentPhase}`,
      eventLabel: `Knop: ${buttonText}`,
    });
  }
}
