export const repairCafes = [
  {
    NAME: 'Repair Café Schijndel',
    ADDRESS: 'Steeg 9',
    LATITUDE: 51.622219,
    LONGITUDE: 5.437339,
    PHONE: '',
    EMAIL: 'm.stegenga@welzijndemeierij.nl',
    WEBSITE: 'http://www.welzijndemeierij.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede donderdag van de maand, van 13.30 tot 16.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Culemborg',
    ADDRESS: 'Beesdseweg 1C',
    LATITUDE: 51.942902,
    LONGITUDE: 5.227813,
    PHONE: '',
    EMAIL: 'culemborg@milieudefensie.nl',
    WEBSITE: 'https://repaircafeculemborg.wordpress.com/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Houten',
    ADDRESS: 'Zonnehout 36',
    LATITUDE: 52.016003,
    LONGITUDE: 5.173809,
    PHONE: '',
    EMAIL: 'repaircafe.houten@gmail.com',
    WEBSITE: 'http://www.repaircafehouten.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand (niet in juli en augustus), van 10.00 tot 14.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Amersfoort',
    ADDRESS: 'Kleine Koppel 40',
    LATITUDE: 52.162659,
    LONGITUDE: 5.377002,
    PHONE: '',
    EMAIL: 'repaircafe@dewar.nl',
    WEBSITE: 'http://dewar.nl/repaircafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de oneven maand, van 10.30 tot 14.30 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Amsterdam-Zuid De Pijp',
    ADDRESS: 'Lutmastraat 61A',
    LATITUDE: 52.35067,
    LONGITUDE: 4.89529,
    PHONE: '',
    EMAIL: 'repaircafedepijp@gmail.com',
    WEBSITE: 'http://nmtzuid.nl/project/repair-cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zondag van de maand (behalve in juli en december), van 12.00 tot 16.00 uur. Reparaties worden tot 15.30 uur aangenomen. Voor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Amsterdam-Oost',
    ADDRESS: 'Balistraat 48a',
    LATITUDE: 52.363037,
    LONGITUDE: 4.933897,
    PHONE: '',
    EMAIL: 'repaircafe.demeevaart@gmail.com',
    WEBSITE: 'https://repaircafe-demeevaart-amsterdam-oost.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste, tweede, derde woensdag van de maand,\n\nvan 14.00 tot 16.30 uur, en elke laatste zaterdag van de maand, van 14.00 tot 16.30 uur.',
  },
  {
    NAME: 'Repair Café Amsterdam-Zuidoost',
    ADDRESS: 'Kraaiennest 68',
    LATITUDE: 52.31723,
    LONGITUDE: 4.980034,
    PHONE: '',
    EMAIL: 'Henk.Postma@simpc.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste vrijdag van de maand, van 16.00 tot 18.00 uur.',
  },
  {
    NAME: 'Repair Café Arnhem',
    ADDRESS: 'Hommelseweg 41',
    LATITUDE: 51.986599,
    LONGITUDE: 5.914139,
    PHONE: '',
    EMAIL: 'repairarnhem@gmail.com',
    WEBSITE: 'https://repaircafearnhem.wordpress.com/over-repair-cafe-arnhem/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Naast het Repair Cafés in St. Marten zijn er in Arnhem nog 7 andere Repair Cafés.\n\nVoor openingstijden en adressen zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Bilthoven/De Bilt',
    ADDRESS: 'Talinglaan 10',
    LATITUDE: 52.124901,
    LONGITUDE: 5.187161,
    PHONE: '',
    EMAIL: 'repaircafedebilt@casema.nl',
    WEBSITE: 'https://repaircafebilthoven.wordpress.com/over-repair-cafe-bilthoven/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Castricum',
    ADDRESS: 'Dorpsstraat 30',
    LATITUDE: 52.545876,
    LONGITUDE: 4.661472,
    PHONE: '',
    EMAIL: 'repaircafe@transitiecastricum.nl',
    WEBSITE: 'http://transitiecastricum.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website.',
  },
  {
    NAME: 'Repair Café Delft-Science Centre',
    ADDRESS: 'Mijnbouwstraat 120',
    LATITUDE: 52.007275,
    LONGITUDE: 4.369437,
    PHONE: '',
    EMAIL: 'info@repaircafedelft.nl',
    WEBSITE: 'http://repaircafedelft.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste zaterdag van de maand van 11.00 tot circa 14.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Groningen',
    ADDRESS: 'Munnekenholm 4',
    LATITUDE: 53.215767,
    LONGITUDE: 6.561839,
    PHONE: '',
    EMAIL: 'adrigroenendal@gmail.com',
    WEBSITE: 'https://repaircafegroningen.wordpress.com/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag in de ‘oneven’ maanden, van 13.00 tot 17.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Haarlem-West',
    ADDRESS: 'Leidseplein 49',
    LATITUDE: 52.379761,
    LONGITUDE: 4.62392,
    PHONE: '',
    EMAIL: 'rchaarlemwest@xs4all.nl',
    WEBSITE: 'https://repaircafehaarlemwest.wordpress.com/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste woensdag van de maand, van 20.00 tot 22.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café IJsselstein',
    ADDRESS: 'Poortdijk 101 (zij-ingang pleinzijde)',
    LATITUDE: 52.023643,
    LONGITUDE: 5.050558,
    PHONE: '',
    EMAIL: 'info@stichting-pulse.nl',
    WEBSITE: 'https://www.stichting-pulse.nl/clubs',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke dinsdag, van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Leiden',
    ADDRESS: '-',
    LATITUDE: 52.160114,
    LONGITUDE: 4.49701,
    PHONE: '',
    EMAIL: 'repaircafeleiden@gmail.com',
    WEBSITE: 'https://repaircafeleiden.wordpress.com/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand van 13.00 tot 16.00 uur. Voor exacte data en locaties, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Maastricht',
    ADDRESS: 'President Rooseveltlaan 213',
    LATITUDE: 50.851311,
    LONGITUDE: 5.711573,
    PHONE: '',
    EMAIL: 'maastrichtrepaircafe@gmail.com',
    WEBSITE: 'http://www.werkhuismaastricht.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag van de maand, van 14.00 tot 17.00 uur. Gesloten in juli, augustus en december. Voor exacte data zie de link hieronder.',
  },
  {
    NAME: 'Repair Café Middelharnis',
    ADDRESS: 'Simon Stevinweg 49',
    LATITUDE: 51.760178,
    LONGITUDE: 4.190132,
    PHONE: '',
    EMAIL: 'info@repaircafemiddelharnis.nl',
    WEBSITE: 'http://www.repaircafemiddelharnis.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand van 10.00 tot 13.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Mijdrecht',
    ADDRESS: 'G. van Aemstelstraat 5',
    LATITUDE: 52.205185,
    LONGITUDE: 4.865005,
    PHONE: '',
    EMAIL: 'a.keja@stdb.nl',
    WEBSITE: 'http://www.tympaan-debaat.nl/repair-cafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede en vierde donderdag van de maand, van 14.00 tot 16.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Nijmegen-Oost',
    ADDRESS: 'Prof. Cornelissenstr 2',
    LATITUDE: 51.833633,
    LONGITUDE: 5.865992,
    PHONE: '',
    EMAIL: 'repaircafenijmegen@hotmail.com',
    WEBSITE: 'http://www.repaircafenijmegen.nl/oost/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde zaterdag van de maand, van 11.00 tot 14.00 uur. Niet in juli, augustus en december.\n\nVoor exacte data zie de website en Facebookpagina hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Oldenzaal',
    ADDRESS: '-',
    LATITUDE: 52.311653,
    LONGITUDE: 6.926828,
    PHONE: '',
    EMAIL: 'k.lubbers@impuls-oldenzaal.nl',
    WEBSITE: 'http://www.impuls-oldenzaal.nl/act/i2/9/1136/1112',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      '<em>Elke eerste dinsdag van de maand, van 14.00 tot 15.30 uur.<br />\n\n</em>Breedwijs, Helmichstraat 42-b, Oldenzaal</p>\n\n<p><em>Elke tweede woensdag van de maand, van 10.00 tot 12.00 uur.<br />\n\n</em>Ontmoetingsruimte Walgaardenflat,<br />\n\nDe Walgaarden 98, 7571 GA, Oldenzaal</p>\n\n<p><em>Elke derde donderdag van de maand, van 10.00 tot 12.00 uur.<br />\n\n</em>Kulturhus Paal 12, Vondellaan 12</p>\n\n<p>Voor exacte data zie de website hieronder.</p>\n\n<p><strong>Website<br />\n\n</strong><a href="http://www.impuls-oldenzaal.nl/act/i2/9/1136/1112">Repair Caf&eacute; Oldenzaal</a></p>',
  },
  {
    NAME: 'Repair Café Tilburg-Centrum',
    ADDRESS: 'Boomstraat 81',
    LATITUDE: 51.558792,
    LONGITUDE: 5.073235,
    PHONE: '',
    EMAIL: 'repaircafe@deboomtak.nl',
    WEBSITE: 'http://deboomtak.nl/activiteiten-repair-cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Locatie 1:\n\n\n\n<em>In de Boomtak</em>\n\nBoomstraat 81\n\n5038GP Tilburg\n\n\n\nLocatie 2:\n\n<em>Tongerlose Hoef</em>\n\nReitse Hoevenstraat 129\n\n5042 ED Tilburg\n\n\n\nVoor openingstijden zie de website.',
  },
  {
    NAME: 'Repair Café Utrecht-Oost',
    ADDRESS: 'Mecklenburglaan 3-5',
    LATITUDE: 52.084496,
    LONGITUDE: 5.137767,
    PHONE: '',
    EMAIL: 'repaircafeutrecht@gmail.com',
    WEBSITE: 'http://www.repaircafe-utrecht.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag van de maand, inloop van 13.30 tot 16.00 uur. \n\nIn juli is het Repair Café gesloten.\n\nVoor exacte data zie de website of Facebookpagina.',
  },
  {
    NAME: 'Repair Café Wageningen',
    ADDRESS: 'Harnjesweg 84',
    LATITUDE: 51.970688,
    LONGITUDE: 5.673042,
    PHONE: '',
    EMAIL: 'repaircafe@solidez.nl',
    WEBSITE: 'http://www.repaircafewageningen.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste en derde vrijdag van de maand, van 13.30 tot 16.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Parkstad Limburg',
    ADDRESS: 'Mijnzetellaan 6',
    LATITUDE: 50.899067,
    LONGITUDE: 5.95827,
    PHONE: '',
    EMAIL: 'repaircafeparkstadlimburg@gmail.com',
    WEBSITE: 'http://www.repaircafeparkstad.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Let op! Repair Café Parkstad Limburg wordt op verschillende locaties gehouden. Behalve in Buurtcentrum Sam Sam vindt het ook plaats in:\n\n\n\nCentrum Op de Boor\n\nWilhelminastraat 19\n\nBocholtz\n\n\n\nVoor openingstijden op de verschillende locaties zie de website.',
  },
  {
    NAME: 'Repair Café Haarlem-Oost',
    ADDRESS: 'Bijvoetstraat 5',
    LATITUDE: 52.377689,
    LONGITUDE: 4.666443,
    PHONE: '',
    EMAIL: 'rkhalil@dock.nl',
    WEBSITE: 'http://haarlem.repaircafe.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede woensdag van de maand, van 19.00 tot 21.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Rotterdam-Prins Alexander-Lage Land',
    ADDRESS: 'Duikerstraat 29-31',
    LATITUDE: 51.94479,
    LONGITUDE: 4.546004,
    PHONE: '',
    EMAIL: 'm.ginneken@live.nl',
    WEBSITE: 'http://makersendoeners.ning.com/page/repaircafe-s',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Om de week op maandag van 14.00 tot 16.00 uur. Voor exacte data, zie de website hieronder.\n\n\n\nEr is ook een Repair Café in <a href="https://repaircafe.org/locatie/repair-cafe-rotterdam-prins-alexander-zevenkamp/">Rotterdam-Prins Alexander - Zevenkamp</a>.',
  },
  {
    NAME: 'Repair Café Zeewolde',
    ADDRESS: 'Kerkplein 20',
    LATITUDE: 52.331509,
    LONGITUDE: 5.541532,
    PHONE: '',
    EMAIL: 'wim.karin@zonnet.nl',
    WEBSITE: 'http://www.repaircafezeewolde.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste vrijdag van de maand, van 09.30 tot 12.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Haarlem-Meetingz!',
    ADDRESS: 'Floris van Adrichemlaan 15',
    LATITUDE: 52.364738,
    LONGITUDE: 4.663688,
    PHONE: '',
    EMAIL: 'janesnelting@hotmail.nl',
    WEBSITE: 'https://repaircafemeetingz.wordpress.com/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde dinsdag van de maand, van 19.30 tot 21.30 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Amsterdam-Centrum Oost',
    ADDRESS: 'Kleine Wittenburgerstraat 201',
    LATITUDE: 52.37151,
    LONGITUDE: 4.921735,
    PHONE: '',
    EMAIL: 'mchukum@dock.nl',
    WEBSITE: 'https://repaircafeamsterdamcentrumoost.wordpress.com/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Hilversumse Meent',
    ADDRESS: 'De Meent 5',
    LATITUDE: 52.271336,
    LONGITUDE: 5.136944,
    PHONE: '',
    EMAIL: 'wim.wolfswinkel@yahoo.com',
    WEBSITE: 'http://www.hilversumsemeent.nl/wegwijzer/repair-cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede maandag van de maand van 20.00 tot 22.00 uur.\n\nNiet in juli en augustus.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Amsterdam-West De Baarsjes',
    ADDRESS: 'Corantijnstraat 25',
    LATITUDE: 52.361683,
    LONGITUDE: 4.851214,
    PHONE: '',
    EMAIL: 'repacafe@xs4all.nl',
    WEBSITE: 'http://www.buurtkamercorantijn.nl/repair_cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag van de maand, van 14.00 tot 17.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Vechtdal-Ommen',
    ADDRESS: 'Strangeweg 23',
    LATITUDE: 52.525837,
    LONGITUDE: 6.432363,
    PHONE: '',
    EMAIL: 'dookvangils@hotmail.com',
    WEBSITE: 'http://vechtdal.repaircafe.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Vlaardingen',
    ADDRESS: 'Professor Teldersstraat 10',
    LATITUDE: 51.907574,
    LONGITUDE: 4.319581,
    PHONE: '',
    EMAIL: 'repaircafevlaardingen@gmail.com',
    WEBSITE: 'http://www.repaircafevlaardingen.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand van 09.30 tot 12.00 uur. Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Deventer',
    ADDRESS: 'Harderwijkerstraat 25',
    LATITUDE: 52.246826,
    LONGITUDE: 6.177582,
    PHONE: '',
    EMAIL: 'repaircafedeventer@gmail.com',
    WEBSITE: 'https://repaircafedeventertwello.wordpress.com/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Almere',
    ADDRESS: 'Meerdere locaties',
    LATITUDE: 0,
    LONGITUDE: 0,
    PHONE: '',
    EMAIL: 'repaircafe@deschoor.nl',
    WEBSITE: 'http://www.repaircafe-almere.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      '<p><strong>Openingstijden</strong><br />\nVoor openingstijden zie de website hieronder.</p>\n<p><strong>Website</strong><br />\n<a href="http://www.repaircafe-almere.nl/">Repair Caf&eacute; Almere</a></p>\n<p><strong>Facebook</strong><br />\n<a href="https://www.facebook.com/RepairCafeAlmere">Repair Caf&eacute; Almere</a></p>\n<p><strong>Contactgegevens</strong><br />\nE-mail: repaircafe [at] deschoor.nl<br />\nTelefoonnummer: +31(0)6 5242 0103 (maandag, dinsdag en donderdag)<br />\nElke locatie heeft haar eigen contactpersoon, zie website.</p>',
  },
  {
    NAME: 'Repair Café Zeist',
    ADDRESS: 'Hoog Kanje 186',
    LATITUDE: 52.076763,
    LONGITUDE: 5.264638,
    PHONE: '',
    EMAIL: 'repaircafezeist@gmail.com',
    WEBSITE: 'http://rc-zeist.nl.greenhostpreview.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 10.00 tot 14.00 uur. Tijdens vakanties en feestdagen gesloten.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Hoogeveen',
    ADDRESS: 'Kanaalweg 2',
    LATITUDE: 52.728065,
    LONGITUDE: 6.489319,
    PHONE: '',
    EMAIL: 'info@repaircafe-hoogeveen.nl',
    WEBSITE: 'http://www.repaircafe-hoogeveen.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag van de maand, van 11.00 tot 15.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Tiel',
    ADDRESS: 'Spoorstraat 15',
    LATITUDE: 51.887203,
    LONGITUDE: 5.420248,
    PHONE: '',
    EMAIL: 'kringloop@secunda.nl',
    WEBSITE: 'http://www.repaircafetiel.nl/cm/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Voor openingstijden zie de website hieronder.</p>\n\n\n\nDe bijeenkomsten vinden plaats in het grijze gebouw naast Kringloopwinkel Secunda, gebruik alsjeblieft de hoofdingang van dit gebouw.\n\n',
  },
  {
    NAME: 'Repair Café Gaasterland',
    ADDRESS: 'Dubbelstraat 10',
    LATITUDE: 52.898121,
    LONGITUDE: 5.583708,
    PHONE: '',
    EMAIL: 'theo@mijngaasterland.nl',
    WEBSITE: 'http://mijngaasterland.nl/mijnrepaircafe.html',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede vrijdag van de maand, van 14.00 tot 16.00 uur, behalve in de zomermaanden. Voor exacte data zie de website hieronder.</p>',
  },
  {
    NAME: 'Repair Café Amstelveen',
    ADDRESS: 'Orion 3',
    LATITUDE: 52.281425,
    LONGITUDE: 4.855162,
    PHONE: '',
    EMAIL: 'r.dewerker@kpnmail.nl',
    WEBSITE: 'https://repaircafeamstelveendemeent.wordpress.com/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 11.00 tot 14.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Zwolle-Berkum',
    ADDRESS: 'Erasmuslaan 50',
    LATITUDE: 52.524498,
    LONGITUDE: 6.139981,
    PHONE: '',
    EMAIL: 'cabos@solcon.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 10.00 tot 12.00 uur.',
  },
  {
    NAME: 'Repair Café Stichtse Vecht',
    ADDRESS: 'Wisselende locatie in omgeving',
    LATITUDE: 52.16814,
    LONGITUDE: 4.999558,
    PHONE: '',
    EMAIL: 'secretaris@repaircafe-stichtsevecht.nl',
    WEBSITE: 'http://www.repaircafe-stichtsevecht.nl/locaties.html',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor data en locaties zie de website.',
  },
  {
    NAME: 'Repair Café Gouda',
    ADDRESS: 'Gildenburg 1',
    LATITUDE: 52.0326,
    LONGITUDE: 4.702557,
    PHONE: '',
    EMAIL: 'repaircafegouda@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 10.00 tot 14.00 uur. Voor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Steenwijkerland',
    ADDRESS: 'Boterweg 5',
    LATITUDE: 52.793121,
    LONGITUDE: 6.098012,
    PHONE: '',
    EMAIL: 'repaircafesteenwijkerland@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste en derde zaterdag van de maand, van 10.00 tot 14.00 uur.',
  },
  {
    NAME: 'Repair Café Piershil (Korendijk)',
    ADDRESS: 'Voorstraat 26',
    LATITUDE: 51.793171,
    LONGITUDE: 4.315824,
    PHONE: '',
    EMAIL: 'barendbierman@hotmail.com',
    WEBSITE: 'http://www.welzijnhoekschewaard.nl/Bij-u-in-de-buurt/Repair-Cafes',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 10.00 tot 12.00 uur, behalve in juli en augustus. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Zevenaar',
    ADDRESS: 'Steenhuizen 6',
    LATITUDE: 51.930733,
    LONGITUDE: 6.08089,
    PHONE: '',
    EMAIL: 'b.vanhall@caleidoz.nl',
    WEBSITE: 'http://repaircafe.caleidoz.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede en vierde woensdag van de maand, van 14.00 tot 16.30 uur.\n\nNiet in juli en augustus.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Alkmaar-De Hoef',
    ADDRESS: 'Bart van der Leckstraat 1',
    LATITUDE: 52.626389,
    LONGITUDE: 4.722175,
    PHONE: '',
    EMAIL: 'info-dehoef@repaircafealkmaar.nl',
    WEBSITE: 'http://www.repaircafealkmaar.nl/index.html',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 13.00 tot 15.30 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Rotterdam- Zuid Feijenoord Ruilwinkel',
    ADDRESS: 'Polderlaan 48',
    LATITUDE: 51.896507,
    LONGITUDE: 4.508522,
    PHONE: '',
    EMAIL: 'ruilwinkel.feijenoord@gmail.com',
    WEBSITE: 'http://ruilwinkel1.nl/paginas/indexbody.php',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Maandag t/m vrijdag: 10.00 tot 16.00 uur\n\nElke eerste zaterdag v/d maand: 10.00 tot 13.00 uur. \n\n\n\nVoor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Heerenveen',
    ADDRESS: 'Coehoorn van Scheltingaweg 1',
    LATITUDE: 52.956841,
    LONGITUDE: 5.93004,
    PHONE: '',
    EMAIL: 'info@repaircafeheerenveen.nl',
    WEBSITE: 'http://www.repaircafeheerenveen.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste en derde woensdag van de maand, van 9.30 tot 12 uur. Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Enschede-Zuiderspeeltuin',
    ADDRESS: 'Kuipersdijk 83',
    LATITUDE: 52.213943,
    LONGITUDE: 6.896892,
    PHONE: '',
    EMAIL: '',
    WEBSITE: 'https://repaircafeenschedezuiderspeeltuin.wordpress.com/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke woensdag, van 13.00 tot 15.30 uur. \n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Hardinxveld-Giessendam',
    ADDRESS: 'Breedeway 1a',
    LATITUDE: 51.823692,
    LONGITUDE: 4.842291,
    PHONE: '',
    EMAIL: 'repaircafe@christengemeente.nl',
    WEBSITE: 'http://www.christengemeente.nl/activiteiten/repair-cafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: '11 november 2017, 13 januari, 10 maart en 5 mei 2018 van 14.00 tot 17.00 uur.',
  },
  {
    NAME: 'Repair Café Leeuwarden-Oost',
    ADDRESS: 'Larixstraat 16',
    LATITUDE: 53.206219,
    LONGITUDE: 5.823763,
    PHONE: '',
    EMAIL: 'toosterhof@pj.nl',
    WEBSITE: 'http://www.meetingpoint-pj.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Zwijndrecht',
    ADDRESS: 'Perkstraat 57B',
    LATITUDE: 51.810074,
    LONGITUDE: 4.6383,
    PHONE: '',
    EMAIL: 'repaircafe@repaircafe-zwijndrecht.nl',
    WEBSITE: 'http://repaircafe-zwijndrecht.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand van 10.00 tot 14.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Purmerend',
    ADDRESS: 'Triton 73',
    LATITUDE: 52.51041,
    LONGITUDE: 4.965737,
    PHONE: '',
    EMAIL: 'repaircafepurmerend@gmail.com',
    WEBSITE: 'http://www.repaircafepurmerend.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      '<em>Elke eerste donderdag van de maand, van 19.30 tot 21.30 uur.</em>\n\nWijkplein Where (Tritongebouw)\n\nTriton 73, Purmerend\n\n\n\n<em>elke tweede dinsdag van de maand, van 14.00 tot 16.00 uur.</em>\n\nHeel Europa\n\nGenuahaven 48, Purmerend\n\n\n\n<em>Een keer per kwartaal op een dinsdag, van 11.00 tot 14.00 uur.</em>\n\nWillem Eggert Centrum\n\n\n\nVoor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Joure',
    ADDRESS: 'Brugstraat 1',
    LATITUDE: 52.967052,
    LONGITUDE: 5.794627,
    PHONE: '',
    EMAIL: 'anjodekker@hotmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde vrijdag van de maand, van 19.00 tot 21.00 uur. Niet in de zomermaanden. Voor exacte data zie de Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Oud-Beijerland (Hoeksche Waard)',
    ADDRESS: 'Koninginneplein 12',
    LATITUDE: 51.823338,
    LONGITUDE: 4.421041,
    PHONE: '',
    EMAIL: 'oudbeijerland@repaircafe-hoekschewaard.nl',
    WEBSITE: 'https://www.repaircafe-hoekschewaard.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag van de maand van 10.00 tot 12.00 uur, behalve in juli en augustus.',
  },
  {
    NAME: 'Repair Café Nieuwegein',
    ADDRESS: 'Geinoord 9',
    LATITUDE: 52.021862,
    LONGITUDE: 5.082839,
    PHONE: '',
    EMAIL: 'repaircafenieuwegein@gmail.com',
    WEBSITE: 'http://repaircafenieuwegein.jouwweb.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 11.00 tot 14.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Emmeloord-Espelervaart',
    ADDRESS: 'Atlantischestraat 18',
    LATITUDE: 52.713085,
    LONGITUDE: 5.732457,
    PHONE: '',
    EMAIL: 'espelervaartbuurthuis@gmail.com',
    WEBSITE: 'http://www.wijkplatformespelervaart.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde donderdag van de maand, van 19.00 tot 21.30 uur.\n\nVoor exacte data zie de website hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Emmeloord-Revelsant',
    ADDRESS: 'Pallasstraat 65',
    LATITUDE: 52.703667,
    LONGITUDE: 5.739278,
    PHONE: '',
    EMAIL: 'g.goldmann51@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste donderdag van de maand, van 14.00 tot 16.00 uur.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Breda-Oost',
    ADDRESS: 'Teteringsedijk 2',
    LATITUDE: 51.593964,
    LONGITUDE: 4.791409,
    PHONE: '',
    EMAIL: 'repaircafebreda@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag van de maand, van 12.00 tot 16.00 uur. Voor exacte data zie de facebookpagina.',
  },
  {
    NAME: 'Repair Café Nijmegen-West',
    ADDRESS: 'Nieuwe Nonnendaalseweg 98',
    LATITUDE: 51.840996,
    LONGITUDE: 5.84359,
    PHONE: '',
    EMAIL: 'Tineke.Kleinhuis@legerdesheils.nl',
    WEBSITE: 'http://www.repaircafenijmegen.nl/west/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste zaterdag van de maand, van 11.00 tot 14.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Huizen',
    ADDRESS: 'Draaikom 2',
    LATITUDE: 52.297283,
    LONGITUDE: 5.268346,
    PHONE: '',
    EMAIL: 'contact@repaircafehuizen.nl',
    WEBSITE: 'http://www.repaircafehuizen.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Bijna elke laatste zaterdag van de maand, van 10 tot 13 uur.\n\nNiet in juli, augustus en december. Voor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Eindhoven-Woensel',
    ADDRESS: 'Firmamentlaan 1',
    LATITUDE: 51.470177,
    LONGITUDE: 5.492348,
    PHONE: '',
    EMAIL: 'repaircafewoensel@gmail.com',
    WEBSITE: 'http://www.repaircafeeindhoven.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vrijdag, van 14.00 tot 17.00 uur. In juni, juli en augustus gesloten.',
  },
  {
    NAME: 'Repair Café Rijswijk',
    ADDRESS: '-',
    LATITUDE: 52.037697,
    LONGITUDE: 4.321974,
    PHONE: '',
    EMAIL: 'info@vrijwilligerscentrum.nl',
    WEBSITE: 'http://www.vrijwilligerscentrum.nl/index.php?id=460',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Zie de website voor de openingstijden en locaties.',
  },
  {
    NAME: 'Repair Café Lelystad',
    ADDRESS: 'Rode Klif 211',
    LATITUDE: 52.522446,
    LONGITUDE: 5.484935,
    PHONE: '',
    EMAIL: 'rcl@repair-cafe-lelystad.nl',
    WEBSITE: 'http://www.repair-cafe-lelystad.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Op woensdag in de ONEVEN WEKEN, van 10.00 tot 12.00 uur (Rode Klif 211 of op locatie in Lelystad).\n\nElke laatste zaterdag van de maand, van 13.00 tot 15.00 uur (Rode Klif 211).\n\n\n\nVoor exacte data, locaties en overige informatie zie de website en Facebookpagina hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Uden',
    ADDRESS: 'Klarinetstraat 4',
    LATITUDE: 51.670757,
    LONGITUDE: 5.616526,
    PHONE: '',
    EMAIL: 'repaircafeuden@gmail.com',
    WEBSITE: 'http://www.repaircafeuden.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede woensdag van de maand, van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Wormerveer-Noord',
    ADDRESS: 'Vinkenstraat 2',
    LATITUDE: 52.497955,
    LONGITUDE: 4.788398,
    PHONE: '',
    EMAIL: 'repaircafewormerveer@gmail.com',
    WEBSITE: 'http://www.repaircafenoorderlichtwormerveer.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste en derde vrijdag van de maand, van 13.00 tot 16.00 uur.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Veenendaal',
    ADDRESS: 'Zonnebloemstraat 1',
    LATITUDE: 52.032532,
    LONGITUDE: 5.546044,
    PHONE: '',
    EMAIL: 'repaircafeveenendaal@gmail.com',
    WEBSITE: 'https://repaircafeveenendaal.wordpress.com/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde vrijdag van de maand, van 13.30 tot 16.30 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Het Hogeland (Winsum)',
    ADDRESS: 'Het Hooge Heem 75',
    LATITUDE: 53.331127,
    LONGITUDE: 6.517956,
    PHONE: '',
    EMAIL: 'repaircafehhl@gmail.com',
    WEBSITE: 'http://www.repaircafehhl.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag van de maand, van 10.00 tot 12.30 uur.\n\nNiet in juli en december. Voor exacte data zie de website of\n\nfacebookpagina.',
  },
  {
    NAME: 'Repair Café Haarlemmermeer-Hoofddorp',
    ADDRESS: 'Muiderbos 36',
    LATITUDE: 52.315208,
    LONGITUDE: 4.658289,
    PHONE: '',
    EMAIL: 'repaircafehmr@gmail.com',
    WEBSITE: 'http://www.repaircafehaarlemmermeer.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde woensdag van de maand, van 13.30 tot 16.00 uur.\n\nVoor exacte data zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Emmen-Emmermeer',
    ADDRESS: 'U.E.Bruiningstraat 7',
    LATITUDE: 52.801441,
    LONGITUDE: 6.889145,
    PHONE: '',
    EMAIL: 'l.valeton@gmail.com',
    WEBSITE: 'http://www.emmermeer.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke donderdag, van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Menameradiel',
    ADDRESS: 'Slappeterpsterdyk 13',
    LATITUDE: 53.208176,
    LONGITUDE: 5.626335,
    PHONE: '',
    EMAIL: '',
    WEBSITE: 'http://www.schingen-slappeterp.nl/repaircafe/120/repaircafe.html',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste zaterdag van de maand, van 10.00 tot 12.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Eindhoven-Stratum',
    ADDRESS: 'Hoefkestraat 42A',
    LATITUDE: 51.432133,
    LONGITUDE: 5.48838,
    PHONE: '',
    EMAIL: 'repaircafestratum@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand, van 14.00 tot 17.00 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Soest',
    ADDRESS: 'Smitsweg 311',
    LATITUDE: 52.170208,
    LONGITUDE: 5.289912,
    PHONE: '',
    EMAIL: '',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 10.00 tot 13.00 uur.\n\nNiet in juli en augustus. \n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Emmen',
    ADDRESS: 'Kapitein Grantstraat 24',
    LATITUDE: 52.770649,
    LONGITUDE: 6.917165,
    PHONE: '',
    EMAIL: 'repaircafe.emmen@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede en vierde vrijdag van de maand, van 10.00 tot 15.30 uur.\n\nVoor exacte data zie de Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Malden',
    ADDRESS: 'Bolder 18b',
    LATITUDE: 51.767967,
    LONGITUDE: 5.860163,
    PHONE: '',
    EMAIL: 'repaircafemalden@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede vrijdag van de maand, van 13.30 tot 16.30 uur.\n\nVoor exacte data zie de Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Vorden',
    ADDRESS: 'Raadhuisstraat 6',
    LATITUDE: 52.103638,
    LONGITUDE: 6.311877,
    PHONE: '',
    EMAIL: 'w.berns@bronckhorst.nl',
    WEBSITE: 'http://www.stichtingwelzijnvorden.nl/index.php/activiteiten/repair-cafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 10.00 tot 12.30 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Tytsjerksteradiel',
    ADDRESS: 'Lageweg 17',
    LATITUDE: 53.191738,
    LONGITUDE: 5.990649,
    PHONE: '',
    EMAIL: 'estaffetteburgum@omrin.nl',
    WEBSITE: 'http://www.t-diel.nl/oars/repaircafe_43225/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 09.00 tot 12.00 uur.',
  },
  {
    NAME: 'Repair Café Vechtdal-Hardenberg',
    ADDRESS: 'Vechtstraat 8',
    LATITUDE: 52.567291,
    LONGITUDE: 6.617842,
    PHONE: '',
    EMAIL: 'jannoorlander@ziggo.nl',
    WEBSITE: 'http://www.repaircafehardenberg.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Vechtdal-Dalfsen',
    ADDRESS: 'De Singel 19-23',
    LATITUDE: 52.510574,
    LONGITUDE: 6.268269,
    PHONE: '',
    EMAIL: 'van3l@sallandxs.net',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste maandag van de maand, van 13.00 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Maas en Waal',
    ADDRESS: 'Pastoor Jansstraat 2',
    LATITUDE: 51.884117,
    LONGITUDE: 5.516741,
    PHONE: '',
    EMAIL: 'info@meerwaardemaasenwaal.nl',
    WEBSITE: 'http://www.meerwaardemaasenwaal.nl/projecten/dorpenronde/repair-cafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste donderdag van de maand van 15.30 tot 17.30 uur. In juli, augustus en december gesloten.',
  },
  {
    NAME: 'Repair Café Oegstgeest',
    ADDRESS: 'Lijtweg 9',
    LATITUDE: 52.184097,
    LONGITUDE: 4.470142,
    PHONE: '',
    EMAIL: 'repaircafeoegstgeest@gmail.com',
    WEBSITE: 'http://www.repaircafeoegstgeest.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vierde zaterdag van de maand van 10.00 tot 13.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Haren',
    ADDRESS: 'Waterhuizerweg 36',
    LATITUDE: 53.169975,
    LONGITUDE: 6.627581,
    PHONE: '',
    EMAIL: 'repaircafeharen@gmail.com',
    WEBSITE: 'http://www.repaircafeharen.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand, van 10.00 tot 12.30 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Amsterdam-Noord Tuindorp Oostzaan',
    ADDRESS: 'Kometensingel 189',
    LATITUDE: 52.414375,
    LONGITUDE: 4.886544,
    PHONE: '',
    EMAIL: 'roblandzaat@kpnmail.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde zaterdag van de maand, van 10.00 tot 13.00 uur.\n\nVoor exacte data neem contact op met de organisator.',
  },
  {
    NAME: 'Repair Café Drunen',
    ADDRESS: 'Admiraalsweg 100',
    LATITUDE: 51.680595,
    LONGITUDE: 5.14066,
    PHONE: '',
    EMAIL: 'Repair-cafe@natuurenmilieuheusden.nl',
    WEBSITE: 'http://www.natuurenmilieuheusden.nl/nl/duurzaamheid/repair_cafe.php',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Eenmaal per twee maanden, op de even maanden, op een woensdagavond, van 19.00 uur tot 21.00 uur. Voor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Hilversum',
    ADDRESS: 'Zoutmanlaan 3',
    LATITUDE: 52.210484,
    LONGITUDE: 5.152688,
    PHONE: '',
    EMAIL: 'repaircafe@hilversumverbonden.nl',
    WEBSITE: 'http://www.hilversumverbonden.nl/repaircafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Epe',
    ADDRESS: 'Stationsstraat 25',
    LATITUDE: 52.347897,
    LONGITUDE: 5.988558,
    PHONE: '',
    EMAIL: 'repaircafeepe@gmail.com',
    WEBSITE: 'http://swo-epe.nl/repair_caf_agenda-nr250',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 09.30 tot 12.30 uur.\n\nVoor exacte data en locaties zie de website hieronder.\n\n<strong>Het bezoekadres dat op deze locatiepagina vermeld staat is géén repareerlocatie!</strong>\n\n\n\n&nbsp;\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Teylingen',
    ADDRESS: '-',
    LATITUDE: 52.224648,
    LONGITUDE: 4.52005,
    PHONE: '',
    EMAIL: 'stan@repaircafe-teylingen.nl',
    WEBSITE: 'http://www.repaircafe-teylingen.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Beek-Berg en Dal',
    ADDRESS: 'Roerdompstraat 6',
    LATITUDE: 51.831306,
    LONGITUDE: 5.92858,
    PHONE: '',
    EMAIL: 'repaircafebeek@upcmail.nl',
    WEBSITE: 'http://www.repaircafenijmegen.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 11.00 tot 14.00 uur.\n\nNiet in januari, juli en augustus.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Amsterdam-Oud West (De Havelaar)',
    ADDRESS: 'Douwes Dekkerstraat 2',
    LATITUDE: 52.36911,
    LONGITUDE: 4.865345,
    PHONE: '',
    EMAIL: 'a.elberse@abc-west.nl',
    WEBSITE: 'http://www.abc-west.nl/repair-cafe-3/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde vrijdag van de maand, van 14.00 tot 17.00 uur. U kunt zich met uw kapotte spullen aanmelden tot 16.00 uur.\n\nVoor exacte data zie de website en Facebookpagina.\n\n\n\nEen grote groep actieve buurtbewoners is betrokken bij het Repair Café Amsterdam Oud-West. Zij worden ondersteund door het sociale wijkteam in Oud-West van de ABC-Alliantie (onderdeel van Combiwel).',
  },
  {
    NAME: 'Repair Café Lieshout',
    ADDRESS: 'Grotenhof 2',
    LATITUDE: 51.517982,
    LONGITUDE: 5.593738,
    PHONE: '',
    EMAIL: 'giel.r@live.nl',
    WEBSITE: 'http://www.dorpsraad-lieshout.nl/activiteit.php',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website.',
  },
  {
    NAME: 'Repair Café Amsterdam-Zuid Buitenveldert',
    ADDRESS: 'A.J. Ernststraat 112',
    LATITUDE: 52.332798,
    LONGITUDE: 4.877867,
    PHONE: '',
    EMAIL: 'repaircafebuitenveldert@gmail.com',
    WEBSITE: 'http://www.hvdwbuitenveldert.nl/wocb.html',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand, van 13.00 tot 16.00 uur.\n\nvoor exacte data zie de link hieronder.',
  },
  {
    NAME: 'Repair Café Maassluis',
    ADDRESS: 'P.C. Hooftlaan 11A',
    LATITUDE: 51.921734,
    LONGITUDE: 4.258331,
    PHONE: '',
    EMAIL: '',
    WEBSITE: 'http://www.stoed.nl/aanbod/buurtcentrum-de-hooftzaak/deze-maand-in-de-hooftzaak/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede en vierde woensdag van de maand, van 13.30 tot 16.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Leeuwarden-Heechterp-Schieringen',
    ADDRESS: 'Egelantierstraat 5',
    LATITUDE: 53.208866,
    LONGITUDE: 5.821405,
    PHONE: '',
    EMAIL: 'bert.pool@telfort.nl',
    WEBSITE: 'https://www.wijkcentrumhs.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde vrijdag van de maand, van 10.00 tot 16.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Rotterdam-West',
    ADDRESS: 'Gaffelstraat 61b',
    LATITUDE: 51.918102,
    LONGITUDE: 4.467595,
    PHONE: '',
    EMAIL: '',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste en derde zaterdag van de maand van 13.00 tot 17.00. Voor exacte informatie, zie de link hieronder.',
  },
  {
    NAME: "Repair Café 's-Gravendeel / Binnenmaas",
    ADDRESS: "'t Weegje 1",
    LATITUDE: 51.77729,
    LONGITUDE: 4.615901,
    PHONE: '',
    EMAIL: 'w.leeuwenburg36@upcmail.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste zaterdag van de maand van 10.00 tot 12.30 uur. Voor exacte data, zie de Facebook pagina hieronder.',
  },
  {
    NAME: 'Repair Café Enschede-Stroinkshuis',
    ADDRESS: 'Het Stroink 64',
    LATITUDE: 52.188484,
    LONGITUDE: 6.900931,
    PHONE: '',
    EMAIL: 'r.c.enschedestroinkshuis@gmail.com',
    WEBSITE: 'https://www.stroinkshuis.nl/aanbieders/repair-cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke zaterdag, van 10.00 tot 12.30 uur. Voor eventuele sluiting wegens vakanties, neem contact op met de organisator.',
  },
  {
    NAME: 'Repair Café Edam-Volendam',
    ADDRESS: 'Dijkgraaf Poschlaan 8 - en andere locaties!',
    LATITUDE: 52.508739,
    LONGITUDE: 5.050373,
    PHONE: '',
    EMAIL: 'repaircafe0299@gmail.com',
    WEBSITE: 'http://www.vrijwilligerswerk-edamvolendam.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      '<strong>Locatie 1:</strong>\n\nElke derde dinsdag van de maand, van 14.00 tot 16.00 uur (m.u.v. juni, juli, augustus).\n\nWijksteunpunt\n\nDijkgraaf Poschlaan 8\n\n1135 GP Edam\n\n\n\n<strong>Locatie 2</strong>:\n\nElke laatste vrijdag van de maand, van 14.00 tot 16.00 uur (m.u.v. juni, juli, augustus).\n\nNoppes Kringloopwinkel\n\nJulianaweg 131\n\nVolendam\n\n\n\n<strong>Locatie 3:</strong>\n\nElke laatste vrijdag van de maand, van 14.00 tot 16.00 uur.\n\nWoonzorgcentrum De Seevanck\n\nRaadhuisstraat 5 1474 HS Oosthuizen',
  },
  {
    NAME: 'Repair Café Heerhugowaard',
    ADDRESS: 'Rozenlaan 2',
    LATITUDE: 52.662197,
    LONGITUDE: 4.838084,
    PHONE: '',
    EMAIL: 'info@repaircafeheerhugowaard.nl',
    WEBSITE: 'http://repaircafenh.wordpress.com',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Op de locatie Het Trefpunt, Rozenlaan 2: elke eerste dinsdag van de maand, van 14.00 tot 16.30 uur,\n\nen elke derde dinsdag van de maand, van 14.00 tot 16.30 uur.\n\nEr is ook een Repair Café-locatie in het Wijkkantoor, Spaarne 58a, en in het Gemeentehuis/Bibliotheek Kennemerwaard, Parelhof 1.\n\nVoor exacte data en openingstijden van alle locaties, zie de website of Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Lochem-Zuiderenk',
    ADDRESS: 'Markt 3',
    LATITUDE: 52.161556,
    LONGITUDE: 6.415107,
    PHONE: '',
    EMAIL: 'repaircafelochem@live.nl',
    WEBSITE: 'http://repaircafelochem.wix.com/online',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke dinsdag, donderdag en zaterdag, van 13.30 tot 16.00 uur.\n\nVoor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Oosterhout',
    ADDRESS: 'Bloemenhof 2 (Grenzend aan winkelcentrum Zuiderhout)',
    LATITUDE: 51.628437,
    LONGITUDE: 4.858735,
    PHONE: '',
    EMAIL: 'repaircafe.oosterhout@outlook.com',
    WEBSITE: 'http://www.repaircafe-oosterhout.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand, van 10.00 tot 14.00 uur. \n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Pijnacker-Nootdorp',
    ADDRESS: 'Kon. Julianastr 1',
    LATITUDE: 52.04554,
    LONGITUDE: 4.395348,
    PHONE: '',
    EMAIL: 'repaircafepn@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde zaterdag van de maand, van 10.00 tot 12.00 uur. Voor exacte data, zie de facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Overbetuwe-Elst',
    ADDRESS: 'Nieuwe Aamsestraat 42',
    LATITUDE: 51.915844,
    LONGITUDE: 5.863055,
    PHONE: '',
    EMAIL: '',
    WEBSITE: 'https://www.fortewelzijn.nl/home-en-actueel/overbetuwe/repair-caf%C3%A9/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste donderdag van de maand, van 14.00 tot 16.00 uur.\n\nVoor exacte data zie de website hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Oosterbeek',
    ADDRESS: 'Weverstraat 20-24',
    LATITUDE: 51.985683,
    LONGITUDE: 5.843337,
    PHONE: '',
    EMAIL: 'repaircafeoosterbeek@gmail.com',
    WEBSITE: 'http://www.kreekoosterbeek.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde zaterdag van de maand, van 13.00 tot 17.00 uur.\n\nVoor exacte data zie de website hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Rotterdam-Noord',
    ADDRESS: 'Banierstraat 1',
    LATITUDE: 51.930183,
    LONGITUDE: 4.47431,
    PHONE: '',
    EMAIL: 'repaircafe.rotterdam.noord@gmail.com',
    WEBSITE: 'http://repaircaferotterdamnoord.wordpress.com',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede vrijdag van de maand van 14.00 tot 18.00 uur, inname tot 17:15. Voor exacte data en overige informatie, zie de website hieronder of volg ons op Facebook.',
  },
  {
    NAME: 'Repair Café Twello',
    ADDRESS: 'Koppelstraat 57',
    LATITUDE: 52.228134,
    LONGITUDE: 6.086327,
    PHONE: '',
    EMAIL: 'rctwello@gmail.com',
    WEBSITE: 'https://repaircafedeventertwello.wordpress.com/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Voor openingstijden zie de websites en Facebookpagina hieronder.\n\n\n\nWebsite <a href="http://www.kringlooptwello.nl/">Kringloop Twello</a>',
  },
  {
    NAME: 'Repair Café Vianen',
    ADDRESS: 'Langeweg 6B',
    LATITUDE: 51.979385,
    LONGITUDE: 5.086432,
    PHONE: '',
    EMAIL: 'repaircafevianen@gmail.com',
    WEBSITE: 'http://www.stichtingmonnikenhof.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 09.00 tot 12.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Grave',
    ADDRESS: 'Koninginnedijk 252',
    LATITUDE: 51.761986,
    LONGITUDE: 5.736302,
    PHONE: '',
    EMAIL: 'repaircafegrave@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste woensdag van de maand, van 14.00 tot 17.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Oirsbeek',
    ADDRESS: 'Oirsbekerweg 2a',
    LATITUDE: 50.949806,
    LONGITUDE: 5.907763,
    PHONE: '',
    EMAIL: 'repaircafe.schepenbank@gmail.com',
    WEBSITE: 'http://www.schepenbankoirsbeek.nl/repaircafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 10.00 tot 14.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Hengelo (Overijssel)',
    ADDRESS: 'Wisselend adres',
    LATITUDE: 52.257412,
    LONGITUDE: 6.792772,
    PHONE: '',
    EMAIL: 'hengelorepaircafe@gmail.com',
    WEBSITE: 'http://www.repaircafehengelo.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      '<em>Buurthuis De Tempel</em>\n\nBooggang 2\n\nHengelo\n\n\n\n<em>Techniekmuseum Heim</em>\n\nIndustriestraat 9\n\nHengelo\n\n\n\n<em>Creatieve Fabriek (Maker Faire)</em>\n\nTuindorpstraat 61\n\nHengelo\n\n\n\nZie voor de exacte data en locaties de website.',
  },
  {
    NAME: 'Repair Café Nijmegen-Dukenburg',
    ADDRESS: 'Tolhuis 44 - 44',
    LATITUDE: 51.818001,
    LONGITUDE: 5.789709,
    PHONE: '',
    EMAIL: 'repaircafe@creatiefcentrumnijmegen-zuid.nl',
    WEBSITE: 'http://www.creatiefcentrumnijmegen-zuid.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 11.00 tot 14.00 uur.\n\nVoor exacte data zie de website hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Vught',
    ADDRESS: 'Watertorenlaan 15',
    LATITUDE: 51.64333,
    LONGITUDE: 5.306154,
    PHONE: '',
    EMAIL: 'repaircafevught@gmail.com',
    WEBSITE: 'http://repaircafevught.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag van de maand, van 11.00 tot 15.00 uur.\n\nVoor exacte data zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Laren (Noord-Holland)',
    ADDRESS: 'Eemnesserweg 42',
    LATITUDE: 52.255322,
    LONGITUDE: 5.233287,
    PHONE: '',
    EMAIL: 'repaircafe.laren@gmail.com',
    WEBSITE: 'http://www.repaircafelaren.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste zaterdag van de maand, van 10.00 tot 14.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Losser',
    ADDRESS: 'Vlasakker 51',
    LATITUDE: 52.259766,
    LONGITUDE: 7.002643,
    PHONE: '',
    EMAIL: 'losser.repaircafe@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vrijdag, van 10.00 tot 12.30 uur. Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Varsseveld',
    ADDRESS: 'Kerkplein 3',
    LATITUDE: 51.943584,
    LONGITUDE: 6.462729,
    PHONE: '',
    EMAIL: 'ljjmhw@outlook.com',
    WEBSITE: 'http://www.repaircafevarsseveld.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag van de maand, van 12.00 tot 15.00 uur.\n\nVoor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Hillegom',
    ADDRESS: 'Henri Dunantplein 28',
    LATITUDE: 52.293247,
    LONGITUDE: 4.578689,
    PHONE: '',
    EMAIL: 'l.klassen@welzijnhillegom.nl',
    WEBSITE: 'http://welzijnhillegom.nl/diensten/repair-cafe-hillegom/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand van 13.00 tot 15.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Hengelo (Gelderland)',
    ADDRESS: 'Kerkstraat 15',
    LATITUDE: 52.052658,
    LONGITUDE: 6.310398,
    PHONE: '',
    EMAIL: 'info@repaircafehengelogld.nl',
    WEBSITE: 'http://www.repaircafehengelogld.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste en derde donderdag van de maand, van 15.00 tot 20.00 uur.\n\nVoor exacte data zie de website en Facebookpagina hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Utrecht-Transwijk',
    ADDRESS: 'Van Bijnkershoeklaan 250',
    LATITUDE: 52.078609,
    LONGITUDE: 5.097919,
    PHONE: '',
    EMAIL: 'mmol@axioncontinu.nl',
    WEBSITE: 'http://www.repaircafe-utrecht.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website.',
  },
  {
    NAME: 'Repair Café Alphen aan den Rijn',
    ADDRESS: 'Energieweg 13',
    LATITUDE: 52.137642,
    LONGITUDE: 4.645211,
    PHONE: '',
    EMAIL: 'jneijman@kringloopalphen.nl',
    WEBSITE: 'http://www.kringloopalphen.nl/index.php?option=com_content&task=view&id=399&Itemid=1',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste donderdag van de maand van 10.00 tot 12.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Waalwijk',
    ADDRESS: 'Balade 1',
    LATITUDE: 51.690979,
    LONGITUDE: 5.08719,
    PHONE: '',
    EMAIL: 'wimsmits@contourdetwern.nl',
    WEBSITE: 'http://repaircafewaalwijk.wordpress.com',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede dinsdag van de maand, van 19.00 tot 21.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Roermond',
    ADDRESS: 'Dr. Philipslaan 49b',
    LATITUDE: 51.199802,
    LONGITUDE: 6.001275,
    PHONE: '',
    EMAIL: 'roermond@hetgoed.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Dit Repair Café is tijdelijk gesloten.',
  },
  {
    NAME: 'Repair Café Zaandam',
    ADDRESS: 'De weer 31',
    LATITUDE: 52.440376,
    LONGITUDE: 4.845137,
    PHONE: '',
    EMAIL: 'info@zaansnatuurmilieucentrum.nl',
    WEBSITE: 'http://zaansnatuurmilieucentrum.nl/projecten/portfolio/page/2/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde vrijdag van de maand, van 14.00 tot 16.00 uur. Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Zutphen',
    ADDRESS: 'De Waarden 309',
    LATITUDE: 52.129055,
    LONGITUDE: 6.193474,
    PHONE: '',
    EMAIL: 'info@stadswerkplaats-zutphen.nl',
    WEBSITE: 'http://www.stadswerkplaats-zutphen.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag van de maand, van 13.00 – 16.00 uur.\n\nvoor exacte data zie website en Facebookpagina hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Terschelling',
    ADDRESS: 'Torenstraat 42',
    LATITUDE: 53.35965,
    LONGITUDE: 5.214647,
    PHONE: '',
    EMAIL: '',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Op dit moment is deze locatie gesloten tot nader bericht.',
  },
  {
    NAME: 'Repair Café Middelburg',
    ADDRESS: 'Kruitmolenlaan 129',
    LATITUDE: 51.492935,
    LONGITUDE: 3.633778,
    PHONE: '',
    EMAIL: 'info@repaircafemiddelburg.nl',
    WEBSITE: 'http://www.repaircafemiddelburg.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Van eind april t/m september: 1x per maand op donderdag, van 19.00 tot 22.00 uur en 1x per maand op zaterdag, van 10.00 tot 14.00 uur. Van september t/m december: alleen op zaterdagen, van 10.00 tot 14.00 uur. Voor exacte data, zie de website of Facebookpagina.',
  },
  {
    NAME: 'Repair Café Valkenswaard',
    ADDRESS: 'Van Bruhezedal 1',
    LATITUDE: 51.356564,
    LONGITUDE: 5.43124,
    PHONE: '',
    EMAIL: 'desireepeeters@paladijn-welzijn.nl',
    WEBSITE: 'http://repaircafevalkenswaard.wordpress.com/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand, van 10.00 tot 14.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Baarn',
    ADDRESS: 'Kerkstraat 28',
    LATITUDE: 52.213547,
    LONGITUDE: 5.294541,
    PHONE: '',
    EMAIL: 'repaircafebaarn@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand, van 10:00 tot 13.00 uur.',
  },
  {
    NAME: 'Repair Café Leerdam',
    ADDRESS: 'Techniekweg 5',
    LATITUDE: 51.902229,
    LONGITUDE: 5.100854,
    PHONE: '',
    EMAIL: 'info@team2015-leerdam.nl',
    WEBSITE: 'http://www.team2015-leerdam.nl/index.html',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Amsterdam-Oost Betondorp',
    ADDRESS: 'Karnstraat 2',
    LATITUDE: 52.340691,
    LONGITUDE: 4.945482,
    PHONE: '',
    EMAIL: 'repairbeton@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede en vierde woensdag van de maand, van 14.00 tot 16.30 uur.\n\nVoor exacte data neem contact op met de organisator.',
  },
  {
    NAME: 'Repair Café Koog aan de Zaan',
    ADDRESS: 'Breestraat 110',
    LATITUDE: 52.462948,
    LONGITUDE: 4.809037,
    PHONE: '',
    EMAIL: 'repaircafehetbrandtweer@gmail.com',
    WEBSITE: 'http://www.hetbrandtweer.nl/wordpress/?s=repair',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Hellevoetsluis',
    ADDRESS: 'Opzoomerlaan 106',
    LATITUDE: 51.826488,
    LONGITUDE: 4.131923,
    PHONE: '',
    EMAIL: 'repaircafehellevoetsluis@gmail.com',
    WEBSITE: 'http://bibliotheekzuidhollandsedelta.nl/nieuws/vakantiesluiting-repair-cafe.html',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Iedere woensdagmiddag tussen 13 en 16 uur (m.u.v. de schoolvakanties). Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Langedijk - Het Behouden Huis',
    ADDRESS: 'Dorpsstraat 850',
    LATITUDE: 52.71389,
    LONGITUDE: 4.804818,
    PHONE: '',
    EMAIL: 'margreet_kruk@live.nl',
    WEBSITE: 'http://millenniumgemeentelangedijk.com/category/repaircafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      '<em>Elke tweede woensdag van de maand, van 13.30 tot 16.00 uur.</em>\n\nSociale Winkel in Winkel Centrum Broekerveiling\n\nMarktplein 35, Broek op Langedijk\n\n\n\n<em>Elke vierde woensdag van de maand, van 14.00 tot 16.00 uur.</em>\n\nHet Behouden Huis\n\nDorpsstraat 850, Oudkarspel\n\n\n\nBehalve in juli en augustus. Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Hattem',
    ADDRESS: 'Daendelsweg 2',
    LATITUDE: 52.468189,
    LONGITUDE: 6.070041,
    PHONE: '',
    EMAIL: 'info@swhattem.nl',
    WEBSITE: 'http://www.swhattem.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste maandag van de maand, van 13.00 tot 15.00 uur.',
  },
  {
    NAME: 'Repair Café Laren (Gelderland)',
    ADDRESS: 'Verwoldseweg 1',
    LATITUDE: 52.192749,
    LONGITUDE: 6.366639,
    PHONE: '',
    EMAIL: 'g.j.nijman.1@kpnmail.nl',
    WEBSITE: 'http://www.kulturhuslaren.nl/repair-cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Den Haag-Stationsbuurt',
    ADDRESS: 'Stationsweg 75',
    LATITUDE: 52.072891,
    LONGITUDE: 4.319066,
    PHONE: '',
    EMAIL: 'duurzaamstationsbuurt@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Wormerveer-West',
    ADDRESS: 'Industrieweg 1a (achter)',
    LATITUDE: 52.496078,
    LONGITUDE: 4.775677,
    PHONE: '',
    EMAIL: 'p.graas@planet.nl',
    WEBSITE: 'http://degrooteweiver.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde woensdag van de maand, van 13.30 tot 16.00 uur.\n\nVoor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Blauwhuis',
    ADDRESS: 'Vitusdijk 8',
    LATITUDE: 53.021671,
    LONGITUDE: 5.533806,
    PHONE: '',
    EMAIL: 'repaircafe@itri.nl',
    WEBSITE: 'http://jozefsrepaircafe.itri.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website en/of Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Leiderdorp',
    ADDRESS: 'Griffioen 15',
    LATITUDE: 52.16309,
    LONGITUDE: 4.531194,
    PHONE: '',
    EMAIL: 'repaircafe@gemiva-svg.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde woensdag van de maand van 13.30 tot 15.30 uur. Voor exacte data, zie de Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Buitenpost',
    ADDRESS: 'Schoolstraat 31',
    LATITUDE: 53.256077,
    LONGITUDE: 6.140025,
    PHONE: '',
    EMAIL: 'info@itkoartling.nl',
    WEBSITE: 'http://www.itkoartling.nl/pages/repaircafe.html',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Amersfoort-Kruiskamp',
    ADDRESS: 'Neptunusplein 66K',
    LATITUDE: 52.162685,
    LONGITUDE: 5.399938,
    PHONE: '',
    EMAIL: 'info@rc-kk.nl',
    WEBSITE: 'http://www.rc-kk.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde zaterdag van de maand, van 10.00 tot 13.00 uur.\n\nVoor exacte data zie de website.\n\n\n\nRepair Café Amersfoort-Kruiskamp is een initiatief van bewoners en kerken en WELZIN in de wijk Kruiskamp-Koppel.',
  },
  {
    NAME: 'Repair Café Amsterdam-Zuid Rivierenbuurt',
    ADDRESS: 'President Kennedylaan 142',
    LATITUDE: 52.340637,
    LONGITUDE: 4.906881,
    PHONE: '',
    EMAIL: 'beheerhvdwr@woor.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke dinsdag, van 11.00 tot 13.00 uur (inloop tot 12.30 uur).',
  },
  {
    NAME: 'Repair Café Sittard',
    ADDRESS: 'Bremstraat 7',
    LATITUDE: 50.99025,
    LONGITUDE: 5.848467,
    PHONE: '',
    EMAIL: 'rc-sittard-geleen@outlook.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke donderdag, van 13.00 tot 16:00 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Venlo-Oost',
    ADDRESS: 'Merelweg 1',
    LATITUDE: 51.354725,
    LONGITUDE: 6.186758,
    PHONE: '',
    EMAIL: 'repaircafevenlooost@ziggo.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag van de maand, van 12.00 tot 16.00 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Blaricum-Bijvanck',
    ADDRESS: 'Burgemeester Tydemanplein 3',
    LATITUDE: 52.287647,
    LONGITUDE: 5.265915,
    PHONE: '',
    EMAIL: 'repaircafe.blaricum@gmail.com',
    WEBSITE: 'http://www.repaircafeblaricum.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website.',
  },
  {
    NAME: 'Repair Café Middelrode',
    ADDRESS: 'Driezeeg 20',
    LATITUDE: 51.66301,
    LONGITUDE: 5.418016,
    PHONE: '',
    EMAIL: 'info@moerkoal.nl',
    WEBSITE: 'http://www.moerkoal.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste vrijdag van de maand, van 09.30 tot 12.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Rhenen',
    ADDRESS: 'Veerweg 1',
    LATITUDE: 51.957867,
    LONGITUDE: 5.562829,
    PHONE: '',
    EMAIL: 'repaircaferhenen@gmail.com',
    WEBSITE: 'https://www.repaircaferhenen.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke vierde vrijdag van de maand, van 13.30 tot 16.00 uur.\n\nVoor exacte data zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Oss',
    ADDRESS: 'De Vlasakkers 9',
    LATITUDE: 51.764076,
    LONGITUDE: 5.511172,
    PHONE: '',
    EMAIL: 'g.rutten28@gmail.com',
    WEBSITE: 'http://www.repaircafe-oss.nl/data/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Locatie 1:\n\n<em>Elke laatste woensdag van de maand, van 09.00 tot 11.30 uur.</em>\n\nDe Bonte Hoef\n\nDe Vlasakkers 9, Oss\n\n\n\n<em>Locatie 2:</em>\n\n<em>Elke tweede vrijdag van de maand, van 14.00 tot 17.00 uur.</em>\n\nWijkcentrum Schadewijk\n\nLeeuwerikstraat 2, Oss',
  },
  {
    NAME: 'Repair Café Lisse',
    ADDRESS: 'Floralisplein 69',
    LATITUDE: 52.258118,
    LONGITUDE: 4.559352,
    PHONE: '',
    EMAIL: 'm.vanderweijden@hltsamen.nl',
    WEBSITE: 'http://www.lisse.nl/repaircafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand van 13.00 tot 16.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Den Helder',
    ADDRESS: 'Seringenlaan 34',
    LATITUDE: 52.946011,
    LONGITUDE: 4.737301,
    PHONE: '',
    EMAIL: '',
    WEBSITE: 'http://www.buurtbeheerbedrijfdenhelder.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke dag van de week, van 13.00 tot 16.00 uur, neem voor specifieke informatie contact op met de organisatoren.',
  },
  {
    NAME: 'Repair Café Rijssen',
    ADDRESS: 'Willem de Zwijgerstraat 32',
    LATITUDE: 52.302246,
    LONGITUDE: 6.507183,
    PHONE: '',
    EMAIL: '',
    WEBSITE: 'http://www.repaircaferijssen.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede donderdag van de maand, van 14.00 tot 17.00 uur.',
  },
  {
    NAME: 'Repair Café Woerden',
    ADDRESS: 'Jozef Israellaan 20A',
    LATITUDE: 52.087711,
    LONGITUDE: 4.872622,
    PHONE: '',
    EMAIL: 'repaircafewoerden@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 09.30 tot 12.00 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Uithoorn-Buurtnest',
    ADDRESS: 'Arthur van Schendellaan 59',
    LATITUDE: 52.247658,
    LONGITUDE: 4.833946,
    PHONE: '',
    EMAIL: 'repaircafeuithoorn@gmail.com',
    WEBSITE: 'https://sites.google.com/site/repairuithoorn/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste en derde woensdag van de maand, van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Emmen-Bargeres',
    ADDRESS: 'Holtingerbrink 62',
    LATITUDE: 52.76495,
    LONGITUDE: 6.879018,
    PHONE: '',
    EMAIL: 'a.looijen@treant.nl',
    WEBSITE: 'https://www.treant.nl/wonen-zorg/locaties/holtingerhof/voorzieningen-2',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke woensdag, van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Terneuzen',
    ADDRESS: 'Leliestraat 30, ingang Dokweg',
    LATITUDE: 51.328228,
    LONGITUDE: 3.833144,
    PHONE: '',
    EMAIL: 'bennie@de-kameleon.biz',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Harderwijk',
    ADDRESS: 'Zuiderbreedte 15',
    LATITUDE: 52.324608,
    LONGITUDE: 5.613455,
    PHONE: '',
    EMAIL: 'info@repaircafeharderwijk.nl',
    WEBSITE: 'http://www.repaircafeharderwijk.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste vrijdag van de maand, van 9.30 tot 12.30 uur. Spullen kunnen aangeboden worden tot 12.00 uur.\n\nTijdens de zomerperiode van mei t/m september ook elke laatste zaterdag van de maand, van 10.30 tot 13.00 uur, op de Rietmeen (bij het tunneltje), Ouverturebaan 1-3.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Heiloo',
    ADDRESS: 'Abraham du Bois-hof 2',
    LATITUDE: 52.601933,
    LONGITUDE: 4.696787,
    PHONE: '',
    EMAIL: 'w.vasbinder@welzijnheiloo.nl',
    WEBSITE: 'http://www.welzijnheiloo.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand.\n\nVoor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Sliedrecht',
    ADDRESS: 'Scheldelaan 1',
    LATITUDE: 51.823624,
    LONGITUDE: 4.771768,
    PHONE: '',
    EMAIL: 'b.geenhuizen@welzijnswerksliedrecht.nl',
    WEBSITE: 'http://www.welzijnswerksliedrecht.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand van 10.00 tot 13.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Waalre',
    ADDRESS: 'De Pracht 2',
    LATITUDE: 51.396393,
    LONGITUDE: 5.486361,
    PHONE: '',
    EMAIL: 'depracht@depracht.nl',
    WEBSITE: 'http://www.depracht.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag van de maand, van 9.00 tot 12.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Asten / Someren',
    ADDRESS: 'Beatrixlaan',
    LATITUDE: 51.39991,
    LONGITUDE: 5.73431,
    PHONE: '',
    EMAIL: '',
    WEBSITE: 'http://www.oniswelzijn.nl/index.php/samen-actief-in-je-dorp/repair-cafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede woensdag van de maand, van 14.00 tot 16.00 uur (in juli en augustus gesloten). \n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Capelle aan den IJssel',
    ADDRESS: 'Wiekslag 3',
    LATITUDE: 51.935226,
    LONGITUDE: 4.590768,
    PHONE: '',
    EMAIL: 'repaircafecapelle@gmail.com',
    WEBSITE: 'http://www.repaircafecapelle.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke donderdag van 14.00 tot 17.00 uur.',
  },
  {
    NAME: 'Repair Café Akkrum',
    ADDRESS: 'Leppedyk 37',
    LATITUDE: 53.051395,
    LONGITUDE: 5.835213,
    PHONE: '',
    EMAIL: 'repaircafeakkrum@upcmail.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste en derde woensdag van de maand, van 13.30 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Gorinchem',
    ADDRESS: 'Dukatenplein 2',
    LATITUDE: 51.841164,
    LONGITUDE: 4.968977,
    PHONE: '',
    EMAIL: 'repaircafe.gorinchem@gmail.com',
    WEBSITE: 'https://repaircafegorinchem.wordpress.com/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste zaterdag van de maand van 10.00 tot 12.30 uur.\n\nVoor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Amersfoort-Schothorst',
    ADDRESS: 'Paladijnenweg 275',
    LATITUDE: 52.168823,
    LONGITUDE: 5.391019,
    PHONE: '',
    EMAIL: 'repaircafe@koperhorst.nl',
    WEBSITE: 'http://www.koperhorst.nl/start-repair-cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste maandag van de maand, van 14.00 tot 16.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Bommelerwaard (Kerkdriel)',
    ADDRESS: 'Bernhardstraat 2',
    LATITUDE: 51.770454,
    LONGITUDE: 5.338826,
    PHONE: '',
    EMAIL: 'repaircafebommelerwaard@gmail.com',
    WEBSITE: 'http://www.repaircafebommelerwaard.nl/home/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde woensdag van de maand, van 14.00 tot 16.30 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Breukelen',
    ADDRESS: 'Vrijheidslaan 4',
    LATITUDE: 52.180939,
    LONGITUDE: 4.998239,
    PHONE: '',
    EMAIL: '',
    WEBSITE: 'http://over-noord.nl/content/repaircafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde donderdag van de maand, van 9.00 tot 11.00 uur. \n\nVoor exacte data zie de website.</p>',
  },
  {
    NAME: 'Repair Café Utrecht-Leidsche Rijn',
    ADDRESS: 'Eerste Oosterparklaan 76',
    LATITUDE: 52.08849,
    LONGITUDE: 5.052723,
    PHONE: '',
    EMAIL: 'jrc.bosch@gmail.com',
    WEBSITE: 'http://rcu001.repaircafe-utrecht.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste zaterdag van de maand, van 13.00 tot 17.00 uur.\n\nVoor exacte data zie de website, Facebookpagina en Twitter.',
  },
  {
    NAME: 'Repair Café Nijmegen-Willemskwartier',
    ADDRESS: 'Groenestraat 170',
    LATITUDE: 51.830997,
    LONGITUDE: 5.852487,
    PHONE: '',
    EMAIL: 'post@huisvancompassienijmegen.nl',
    WEBSITE: 'http://www.huisvancompassienijmegen.nl/activiteiten/repair-cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde vrijdag van de maand, van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de website hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Doesburg',
    ADDRESS: 'Breedestraat 39',
    LATITUDE: 52.002655,
    LONGITUDE: 6.125853,
    PHONE: '',
    EMAIL: 'g.jordens@caleidoz.nl',
    WEBSITE: 'http://doesburg.caleidoz.nl/home/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste woensdag van de maand, van 14.00 tot 17.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Gilze en Rijen (Rijen)',
    ADDRESS: 'Nassaulaan 62-64',
    LATITUDE: 51.589348,
    LONGITUDE: 4.907316,
    PHONE: '',
    EMAIL: 'management@deboodschap.nl',
    WEBSITE: 'http://www.ccgr.nl/category/repair-cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website of Facebookpagina.',
  },
  {
    NAME: 'Repair Café Gilze en Rijen (Gilze)',
    ADDRESS: 'Kerkstraat 104',
    LATITUDE: 51.540356,
    LONGITUDE: 4.941088,
    PHONE: '',
    EMAIL: 'management@deboodschap.nl',
    WEBSITE: 'http://www.ccgr.nl/ontmoetingsplek-cafe/repair-cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Bommelerwaard (Ammerzoden)',
    ADDRESS: 'Hogesteeg 2',
    LATITUDE: 51.749992,
    LONGITUDE: 5.222617,
    PHONE: '',
    EMAIL: 'repaircafebommelerwaard@gmail.com',
    WEBSITE: 'http://www.repaircafebommelerwaard.nl/home/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede woensdag van de maand, van 14.00 tot 16.30 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Bommelerwaard (Zaltbommel)',
    ADDRESS: 'van Heemstraweg west 9',
    LATITUDE: 51.803272,
    LONGITUDE: 5.254083,
    PHONE: '',
    EMAIL: 'repaircafebommelerwaard@gmail.com',
    WEBSITE: 'http://www.repaircafebommelerwaard.nl/home/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke vierde woensdag van de maand, van 14.00 tot 16.30 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Venlo-Blerick',
    ADDRESS: 'Vossenerlaan 57',
    LATITUDE: 51.366131,
    LONGITUDE: 6.129941,
    PHONE: '',
    EMAIL: 'repaircafe.vossener@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand, van 12.00 tot 16.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Emmen-Zuidermarke',
    ADDRESS: 'Wilhelminastraat 8',
    LATITUDE: 52.77821,
    LONGITUDE: 6.897121,
    PHONE: '',
    EMAIL: 's.schnoing@levestecare.nl',
    WEBSITE: 'https://www.treant.nl/over-treant/nieuws/kapotte-spullen-repareren-in-woonzorgcentrum-zuidermarke',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke donderdag van 13.30 tot 16.30 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Oostzaan',
    ADDRESS: 'Dr. Snijderstraat 2a',
    LATITUDE: 52.443981,
    LONGITUDE: 4.874618,
    PHONE: '',
    EMAIL: 'repaircafe@buurtverenigingdebres.nl',
    WEBSITE: 'http://www.buurtverenigingdebres.nl/?page_id=692',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 9.30 tot 11.30 uur.\n\nVoor exacte data zie de website hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Roden',
    ADDRESS: 'Padkamp 2b (tegenover ALDI)',
    LATITUDE: 53.138874,
    LONGITUDE: 6.428666,
    PHONE: '',
    EMAIL: 'richardton@live.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste woensdag van de maand, van 14.00 tot 16.30 uur, van september tot juni.',
  },
  {
    NAME: 'Repair Café Kampen',
    ADDRESS: 'Trekvaart 33',
    LATITUDE: 52.559635,
    LONGITUDE: 5.92445,
    PHONE: '',
    EMAIL: 'repaircafekampen@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'E&eacute;n keer in de twee maanden, van 10.00 tot 13.00 uur. Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Zoetermeer',
    ADDRESS: 'Edelgasstraat 248',
    LATITUDE: 52.034378,
    LONGITUDE: 4.499392,
    PHONE: '',
    EMAIL: 'info@despringplank.nu',
    WEBSITE: 'http://www.despringplank.nu/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Repair Caf&eacute; Zoetermeer is voorlopig gesloten, helaas zijn er onvoldoende vrijwilligers beschikbaar.',
  },
  {
    NAME: 'Repair Café Beetsterzwaag',
    ADDRESS: 'Vlaslaan 11',
    LATITUDE: 53.062275,
    LONGITUDE: 6.08064,
    PHONE: '',
    EMAIL: 'repaircafe.bzwaag@gmail.com',
    WEBSITE: 'http://www.repaircafebeetsterzwaag.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag van de maand, van 9.30 tot 12.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Rotterdam-Oud Charlois',
    ADDRESS: 'Wolphaertstraat 2',
    LATITUDE: 51.893604,
    LONGITUDE: 4.467522,
    PHONE: '',
    EMAIL: 'swcharlois@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      '<p><strong>Openingstijden</strong><br />\nelke tweede woensdag van de maand, van 15.00 tot 18.00 uur.</p>\n<p><strong>Contactgegevens</strong><br />\nE-mail: swcharlois [at] gmail.com<br />\nTelefoonnummer: +31(0)10 8487 700 (Jeroen van der Starre)</p>',
  },
  {
    NAME: 'Repair Café Diemen',
    ADDRESS: 'Arent Krijtsstraat 48',
    LATITUDE: 52.341541,
    LONGITUDE: 4.95667,
    PHONE: '',
    EMAIL: 'repaircafe@duurzaamdorpdiemen.nl',
    WEBSITE: 'http://www.duurzaamdorpdiemen.nl/p/repair-cafe.html',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, 14.15 tot 16.45 uur (m.u.v. juli en augustus).\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Leende',
    ADDRESS: 'Lindenlaan 57',
    LATITUDE: 51.351482,
    LONGITUDE: 5.557031,
    PHONE: '',
    EMAIL: 'rebeling1946@gmail.com',
    WEBSITE: 'http://www.zcgraaggedaanleende.nl/repaircafe2.html',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand van 09.30 tot 12.30 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Appingedam',
    ADDRESS: 'Burg. Klauckelaan 16',
    LATITUDE: 53.315514,
    LONGITUDE: 6.862008,
    PHONE: '',
    EMAIL: 'njvangelderen@hotmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 13.00 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Obdam',
    ADDRESS: 'Dorpsstraat 155',
    LATITUDE: 52.67654,
    LONGITUDE: 4.909612,
    PHONE: '',
    EMAIL: 'wimbruin49@gmail.com',
    WEBSITE: 'http://www.debrinkobdam.nl/jaarprogramma',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde woensdag van de maand, van 09.30 tot 11.30 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Ermelo',
    ADDRESS: 'Raadhuisplein 8-10',
    LATITUDE: 52.300209,
    LONGITUDE: 5.617444,
    PHONE: '',
    EMAIL: 'wafa.fare@welzijnermelo.nl',
    WEBSITE: 'https://www.welzijnermelo.nl/diensten/repaircafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede en vierde dinsdag in de maand, van 09.30 tot 12.00 uur.\n\nVoor exacte data zie de link hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Amstelveen-Stadsdorp Elsrijk',
    ADDRESS: 'Rembrandtweg 428',
    LATITUDE: 52.311291,
    LONGITUDE: 4.86367,
    PHONE: '',
    EMAIL: 'repaircafe@stadsdorpelsrijk.nl',
    WEBSITE: 'http://www.stadsdorpelsrijk.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag van de maand, van 10.00 tot 13.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Rotterdam-Hoogvliet',
    ADDRESS: 'Mosoelstraat 20',
    LATITUDE: 51.870464,
    LONGITUDE: 4.351794,
    PHONE: '',
    EMAIL: 'repaircafehoogvliet@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand van 9.00 tot 12.00 uur.',
  },
  {
    NAME: 'Repair Café Amsterdam-Slotervaart Noord',
    ADDRESS: 'Piet Mondriaanstraat 140',
    LATITUDE: 52.366055,
    LONGITUDE: 4.838263,
    PHONE: '',
    EMAIL: 'herman.bouma@gmail.com',
    WEBSITE: 'https://repaircafeslotervaart.wordpress.com/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voorlopig gesloten wegens een tekort aan vrijwilligers.',
  },
  {
    NAME: 'Repair Café Groesbeek',
    ADDRESS: 'De Paap 1',
    LATITUDE: 51.777203,
    LONGITUDE: 5.94058,
    PHONE: '',
    EMAIL: 'repaircafegroesbeek@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Vries',
    ADDRESS: 'Brinkstraat 6A',
    LATITUDE: 53.07679,
    LONGITUDE: 6.576207,
    PHONE: '',
    EMAIL: 'nicoheikamp@gmail.com',
    WEBSITE: 'http://www.kringloopwinkelvries.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste vrijdag van de maand, van 13.00 tot 17.00 uur. \n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Dordrecht-Centrum',
    ADDRESS: 'Otto Dickeplein 1',
    LATITUDE: 51.81934,
    LONGITUDE: 4.67433,
    PHONE: '',
    EMAIL: 'repaircafe.dordrecht@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde zaterdag van de maand van 10.00 tot 13.00 uur, behalve in juli en augustus. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Anna Paulowna',
    ADDRESS: 'Molenvaart 93',
    LATITUDE: 52.863388,
    LONGITUDE: 4.829436,
    PHONE: '',
    EMAIL: 'c.meijburg@wbvap.nl',
    WEBSITE: 'https://ctrhollandskroon.nl/lokaal/repair-café-anna-paulowna-511931',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website.',
  },
  {
    NAME: 'Repair Café Zaandijk',
    ADDRESS: 'Willem Dreeslaan 1',
    LATITUDE: 52.475075,
    LONGITUDE: 4.81141,
    PHONE: '',
    EMAIL: 'info@repaircafezaandijk.nl',
    WEBSITE: 'http://www.repaircafezaandijk.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke zaterdag van 10.00 tot 13.00 uur.\n\nVoor exacte data zie de website hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Loon op Zand',
    ADDRESS: 'Schotsestraat 4c',
    LATITUDE: 51.663429,
    LONGITUDE: 5.032698,
    PHONE: '',
    EMAIL: 'h.lonterman@home.nl',
    WEBSITE: 'http://www.kdc-loz.nl/index.php/162/repair-cafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Locatie 1:\n\nElke eerste donderdag van de maand, van 19.00 tot 21.00 uur.\n\nElke derde donderdag van de maand, van 14.00 tot 16.00 uur.\n\nVrijwilligersgebouw\n\nSchotsestraat 4c\n\n5171 DT Kaatsheuvel\n\n\n\nLocatie 2:\n\nElke derde dinsdag van de maand, van 19.00 tot 21.00 uur.\n\nDe Wetering\n\nWeteringplein 1\n\nLoon op Zand\n\n\n\nVoor exacte data zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Utrecht-Lunetten',
    ADDRESS: 'Hondsrug 19',
    LATITUDE: 52.064163,
    LONGITUDE: 5.136203,
    PHONE: '',
    EMAIL: 'repaircafelunetten@gmail.com',
    WEBSITE: 'http://rcu001.repaircafe-utrecht.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 10.00 tot 12.30 uur (in september op de derde zaterdag van de maand). Voor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Wervershoof',
    ADDRESS: 'Kerkelaantje 1',
    LATITUDE: 52.729595,
    LONGITUDE: 5.156807,
    PHONE: '',
    EMAIL: 'info@deschoof.net',
    WEBSITE: 'http://www.deschoof.net/?page_id=82',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste woensdag van de maand, van 09.30 tot 11.30 uur, en\n\nelke laatste zaterdag van de maand, van 10.00 tot 12.00 uur.\n\nVoor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Enschede-Glanerbrug',
    ADDRESS: 'Gronausestraat 846',
    LATITUDE: 52.215786,
    LONGITUDE: 6.954147,
    PHONE: '',
    EMAIL: 'tamcit@hotmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke dinsdag, van 14.00 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Harkstede',
    ADDRESS: 'Appelhof 32',
    LATITUDE: 53.212666,
    LONGITUDE: 6.700505,
    PHONE: '',
    EMAIL: 'avanduuren@home.nl',
    WEBSITE: 'http://www.dorpsbelangenharkstede.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elk kwartaal de derde zaterdag, van 13.00 tot 17.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Barneveld',
    ADDRESS: 'Johan de Wittlaan 12',
    LATITUDE: 52.145916,
    LONGITUDE: 5.581817,
    PHONE: '',
    EMAIL: 'vrijwilligerswerk@neboplus.nl',
    WEBSITE: 'http://www.neboplus.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde maandag van de maand, van 13.30 tot 16.30 uur.\n\nVoor exacte data zie de <strong>website</strong> van dit Repair Café.',
  },
  {
    NAME: 'Repair Café Putten',
    ADDRESS: 'Molenweg 2',
    LATITUDE: 52.258762,
    LONGITUDE: 5.607168,
    PHONE: '',
    EMAIL: 'repaircafeputten@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand, van 10.00 tot 12.00 uur.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Sneek',
    ADDRESS: 'Frederik Hendrikstraat 22',
    LATITUDE: 53.02813,
    LONGITUDE: 5.666781,
    PHONE: '',
    EMAIL: 'repaircafe@outlook.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke woensdag, van 13.00 tot 15.30 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Texel',
    ADDRESS: 'Naalrand 2',
    LATITUDE: 53.023895,
    LONGITUDE: 4.748419,
    PHONE: '',
    EMAIL: 'ester@deverzamelpost.nl',
    WEBSITE: 'http://deverzamelpost.nl/partners/repair-cafe-texel/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Een keer per maand op zaterdag, van 14.00 tot 17.00 uur.\n\nVoor exacte data zie de website en Facebookpagina hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Wapenveld',
    ADDRESS: 'Putterweg 2D',
    LATITUDE: 52.428894,
    LONGITUDE: 6.069312,
    PHONE: '',
    EMAIL: 'tonhulsebos@verian.nl',
    WEBSITE: 'http://www.hofvancramer.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede dinsdag van de maand, van 14.00 tot 16.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Berkelland',
    ADDRESS: 'Pagendijk 61',
    LATITUDE: 52.113712,
    LONGITUDE: 6.521065,
    PHONE: '',
    EMAIL: 'repaircafeberkelland@kpnmail.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Op afspraak (telefonisch via het onderstaande nummer).',
  },
  {
    NAME: 'Repair Café Echt-Susteren (Susteren)',
    ADDRESS: 'Willibrordusstraat 4',
    LATITUDE: 51.063313,
    LONGITUDE: 5.853108,
    PHONE: '',
    EMAIL: 'info@veureederein.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 13.00 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Schiedam',
    ADDRESS: 'Nieuwe Damlaan 818',
    LATITUDE: 51.918354,
    LONGITUDE: 4.389005,
    PHONE: '',
    EMAIL: 'repaircafeschiedam@gmail.com',
    WEBSITE: 'http://www.seniorenwelzijn.nl/diensten/repair-cafa',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag van de maand van 09.30 tot 12.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Amersfoort-Vathorst',
    ADDRESS: 'Wervershoofstraat 157',
    LATITUDE: 52.20216,
    LONGITUDE: 5.422548,
    PHONE: '',
    EMAIL: 'repaircafe@xmsnet.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Het streven is elke eerste zaterdag van de maand, van 10.30 tot 13.30 uur. Voor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Amstelveen-Randwijck',
    ADDRESS: 'Catharina van Clevepark 10',
    LATITUDE: 52.320011,
    LONGITUDE: 4.862438,
    PHONE: '',
    EMAIL: 'repaircafe@villarandwijck.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand, van 14.00 tot 17.00 uur.',
  },
  {
    NAME: 'Repair Café Grou',
    ADDRESS: 'De Muldyk 3',
    LATITUDE: 53.098228,
    LONGITUDE: 5.825975,
    PHONE: '',
    EMAIL: 'renny_ausma@hetnet.nl',
    WEBSITE: 'https://sites.google.com/site/repaircafegrou',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde woensdag van de maand, van 13:30 tot 16:30 uur. \n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Zwolle-Stadshagen',
    ADDRESS: 'Werkerlaan 154',
    LATITUDE: 52.533451,
    LONGITUDE: 6.057693,
    PHONE: '',
    EMAIL: 'repaircafestadshagen@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag van de maand van 10.00 tot 14.00 uur, niet op feestdagen. Voor exacte data, zie de Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Emmen-Rietlanden',
    ADDRESS: 'Boomvalk 80',
    LATITUDE: 52.750626,
    LONGITUDE: 6.890959,
    PHONE: '',
    EMAIL: 'h.wolbers@treant.nl',
    WEBSITE: 'http://www.levestecare.nl/locaties/valkenhof',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke donderdag, van 10.00 tot 12.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Beilen',
    ADDRESS: 'Kampstraat 2',
    LATITUDE: 52.860661,
    LONGITUDE: 6.512411,
    PHONE: '',
    EMAIL: 'repaircafebeilen@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 10.00 tot 13.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Krimpen aan den IJssel - de Vijverhoek',
    ADDRESS: 'Lage Vijver 2',
    LATITUDE: 51.914001,
    LONGITUDE: 4.614808,
    PHONE: '',
    EMAIL: 'ellenvanpelt@contourdetwern.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste woensdag van de maand van 13.00 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Spijkenisse',
    ADDRESS: 'Zwanenhoek 86A',
    LATITUDE: 51.843678,
    LONGITUDE: 4.316321,
    PHONE: '',
    EMAIL: 'a.velden13@chello.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'In even weken op donderdag van 13.30 tot 16.30 uur.',
  },
  {
    NAME: 'Repair Café Borne',
    ADDRESS: 'De Wanne 8',
    LATITUDE: 52.30743,
    LONGITUDE: 6.756428,
    PHONE: '',
    EMAIL: 'repaircafeborne@gmail.com',
    WEBSITE: 'http://www.stroomesch.nl/activiteiten/repair-caf/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag van de maand, van 10.00 tot 12.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Groenlo',
    ADDRESS: 'Mattelierstraat 19',
    LATITUDE: 52.041531,
    LONGITUDE: 6.61851,
    PHONE: '',
    EMAIL: 'info@debrongroenlo.nl',
    WEBSITE: 'http://bs22.nl/projecten/repair-cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke zaterdag in de EVEN WEKEN.\n\nVoor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Albrandswaard',
    ADDRESS: 'Dorpsstraat 34',
    LATITUDE: 51.856728,
    LONGITUDE: 4.388448,
    PHONE: '',
    EMAIL: 'harmien@dirk-harmien.nl',
    WEBSITE: 'http://www.albrandswaardinactie.nl/ontmoeten/repair-cafe-albrandswaard-start-september/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand van 10.00 tot 13.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Amersfoort-Soesterkwartier',
    ADDRESS: 'Noordewierweg 131',
    LATITUDE: 52.160355,
    LONGITUDE: 5.365374,
    PHONE: '',
    EMAIL: 'repaircafe.soesterkwartier@gmail.com',
    WEBSITE: 'http://soesterkwartier.nl/klussenvrijwilligers/repaircafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste en derde donderdag van de maand, van 13.30 tot 15.30 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Ouder-Amstel (Ouderkerk aan de Amstel)',
    ADDRESS: 'Julianalaan 16',
    LATITUDE: 52.295303,
    LONGITUDE: 4.90804,
    PHONE: '',
    EMAIL: 'havenot@planet.nl',
    WEBSITE: 'http://www.coherente.nl/dienstverlening/repair-café',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor data en openingstijden zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Ouder-Amstel (Duivendrecht)',
    ADDRESS: 'Schoolpad 3',
    LATITUDE: 52.330753,
    LONGITUDE: 4.941283,
    PHONE: '',
    EMAIL: 'havenot@planet.nl',
    WEBSITE: 'http://www.coherente.nl/dienstverlening/repair-café',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor data en openingstijden zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Utrecht-Overvecht',
    ADDRESS: "Jeanne d'Arcdreef 1",
    LATITUDE: 52.113377,
    LONGITUDE: 5.126725,
    PHONE: '',
    EMAIL: 'ronaldteune@gmail.com',
    WEBSITE: 'http://rcu001.repaircafe-utrecht.nl/repair-cafe-utrecht-overvecht/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag van de maand, van 14.00 tot 17.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Eindhoven-Blixembosch',
    ADDRESS: 'Ouverture 2',
    LATITUDE: 51.488625,
    LONGITUDE: 5.47371,
    PHONE: '',
    EMAIL: 'frans@repaircafe-blixembosch.nl',
    WEBSITE: 'http://www.repaircafe-blixembosch.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website.',
  },
  {
    NAME: 'Repair Café Alkmaar-De Mare',
    ADDRESS: 'Beneluxplein 1 (winkelcentrum De Mare)',
    LATITUDE: 52.655354,
    LONGITUDE: 4.755877,
    PHONE: '',
    EMAIL: 'repaircafedemare@gmail.com',
    WEBSITE: 'http://HetVlijthof.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde zaterdag van de maand, van 13.00 tot 16.00 uur.\n\nIn de maanden juni, juli en augustus gesloten.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Weert',
    ADDRESS: 'Dr. Kuyperstraat 221',
    LATITUDE: 51.248558,
    LONGITUDE: 5.718725,
    PHONE: '',
    EMAIL: 'dayuniz@hotmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      '<p><strong>Openingstijden</strong><br />\nElke tweede zaterdag van de maand, van 13.00 tot 16.00 uur.<br />\nMet uitzondering van juli en augustus.</p>\n<p><strong>Facebook</strong><br />\n<a href="https://www.facebook.com/repaircafeweert">Repair Caf&eacute; Weert</a></p>\n<p><strong>Contactgegevens</strong><br />\nE-mail: dayuniz [at] hotmail.com (Younes Ezzohari)</p>',
  },
  {
    NAME: 'Repair Café Overveen',
    ADDRESS: 'Vrijburglaan 17',
    LATITUDE: 52.393288,
    LONGITUDE: 4.617164,
    PHONE: '',
    EMAIL: 'tomdegraaff@quicknet.nl',
    WEBSITE: 'http://www.duurzaamoverveen.nl/content/repair-caf%C3%A9-overveen',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Tussen de acht en tien keer per jaar op zaterdag, van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de website hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Spanbroek',
    ADDRESS: 'Pastoor Meriusstraat 4',
    LATITUDE: 52.70335,
    LONGITUDE: 4.948748,
    PHONE: '',
    EMAIL: 'wijksteunpuntdeschakel@omring.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste maandag van de maand, van 09.30 tot 12.00 uur.',
  },
  {
    NAME: 'Repair Café Winterswijk',
    ADDRESS: 'Handelscentrum 6',
    LATITUDE: 51.970482,
    LONGITUDE: 6.710517,
    PHONE: '',
    EMAIL: 'g.berndsen@aktief-groep.nl',
    WEBSITE: 'http://www.aktiefonline.nl/nl/over-aktief/repair-cafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste woensdag van de maand, van 13.30 tot 17.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Pijnacker-Noord',
    ADDRESS: 'Groen van Prinstererlaan 1',
    LATITUDE: 52.024578,
    LONGITUDE: 4.429481,
    PHONE: '',
    EMAIL: 'bartennema@gmail.com',
    WEBSITE: 'http://www.pijnacker-noord.nl/kalender/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand van 09.30 tot 12.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Utrecht-Votulast',
    ADDRESS: 'Samuel v Houtenstraat 1',
    LATITUDE: 52.104511,
    LONGITUDE: 5.116455,
    PHONE: '',
    EMAIL: 'w.sanders38@casema.nl',
    WEBSITE: 'http://rcu001.repaircafe-utrecht.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 13.00 tot 15.00 uur inloop, repareren tot 16.00 uur. \n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Didam',
    ADDRESS: 'Raadhuisstraat 3',
    LATITUDE: 51.93845,
    LONGITUDE: 6.127679,
    PHONE: '',
    EMAIL: 'repaircafedidam@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      '<p><strong>Openingstijden</strong><br />\nElke tweede zaterdag van de maand, van 13.00 tot 16.00 uur.<br />\nNiet in juli en augustus. Voor exacte data zie de Facebookpagina hieronder.</p>\n<p><strong>Facebook</strong><br />\n<a href="https://www.facebook.com/pages/Repair-Caf&eacute;-Didam/392556324243719">Repair Caf&eacute; Didam</a></p>\n<p><strong>Contactgegevens</strong><br />\nE-mail: repaircafedidam [at] gmail.com<br />\nTelefoonnummer: +31 (0)316 223427</p>',
  },
  {
    NAME: 'Repair Café Haaksbergen',
    ADDRESS: 'Industriestraat 51',
    LATITUDE: 52.151497,
    LONGITUDE: 6.717927,
    PHONE: '',
    EMAIL: 'l.veldhuis@kpnmail.nl',
    WEBSITE: 'http://www.gildehaaksbergen.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste woensdag van de maand, van 09.00 tot 12.00 uur.',
  },
  {
    NAME: 'Repair Café Ede',
    ADDRESS: 'Notaris Fischerstraat 10',
    LATITUDE: 52.047516,
    LONGITUDE: 5.66986,
    PHONE: '',
    EMAIL: 'info@repaircafe-ede.nl',
    WEBSITE: 'http://www.repaircafe-ede.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde zaterdag van de maand, van 10.00 tot 13.00 uur.\n\nNiet in juli en augustus. Voor exacte data zie de website en Facebookpagina hieronder.\n\n\n\n&nbsp;\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Kapelle',
    ADDRESS: 'Cederlaan 9',
    LATITUDE: 51.481277,
    LONGITUDE: 3.962309,
    PHONE: '',
    EMAIL: 'info@cederhof.eu',
    WEBSITE: 'http://www.cederhof.eu/welzijn/activiteiten',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Dieren',
    ADDRESS: 'Ericaplein 1',
    LATITUDE: 52.051537,
    LONGITUDE: 6.101634,
    PHONE: '',
    EMAIL: 'repaircafedieren@outlook.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 14.00 tot 16.00 uur, en elke vierde donderdag van de maand, van 19.00 tot 21.00 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Wassenaar',
    ADDRESS: 'Parklaan 26',
    LATITUDE: 52.146034,
    LONGITUDE: 4.410382,
    PHONE: '',
    EMAIL: 'repaircafewassenaar@gmail.com',
    WEBSITE: 'https://www.vrijwilligerscentralewassenaar.nl/paginas/6/78/Repaircafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede woensdag van de maand van 14.00 tot 16.00 uur.\n\nVoor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Delfzijl-Noord',
    ADDRESS: 'Ede Staalstraat 1',
    LATITUDE: 53.33559,
    LONGITUDE: 6.910023,
    PHONE: '',
    EMAIL: 's.udema@bewonersbedrijfdelfzijlnoord.nl',
    WEBSITE: 'http://www.eemsbode.nl/nieuws/26743/repair-cafe-nu-appingedam-en-delfzijl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag van de maand, van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Amsterdam-Centrum West',
    ADDRESS: 'Tussen de Bogen 16',
    LATITUDE: 52.383366,
    LONGITUDE: 4.888557,
    PHONE: '',
    EMAIL: 'onno@jordaangoudenreael.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke dinsdag, van 13.00 tot 15.00 uur.',
  },
  {
    NAME: 'Repair Café Onderbanken',
    ADDRESS: 'A ge Water 20A',
    LATITUDE: 50.968075,
    LONGITUDE: 5.980666,
    PHONE: '',
    EMAIL: 'cjsteinen@live.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Let op! Repair Café Onderbanken wordt op verschillende adressen gehouden. Op welk adres wanneer wordt gerepareerd, is op te vragen bij de contactpersoon van dit Repair Café.\n\n\n\nElke tweede zaterdag van de maand, van 10.00 tot 14.00 uur. Afwisselend op de volgende locaties:\n\n\n\n<em>Kantoren CMWW Schinveld\n\n</em>A ge Water 20A, 6451 CA Schinveld-Onderbanken\n\n\n\n<em>Gemeenschapshuis de Henkhof\n\n</em>Clemensweg 1, 6447 AZ Merkelbeek-Onderbanken\n\n\n\n<em>Ontmoetingscentrum</em>\n\nDorpstraat 145, 6454 AN Bingelrade-Onderbanken\n\n\n\n<em>Ontmoetingscentrum</em>\n\nGaatstraat 1, 6456 AC Jabeek-Onderbanken',
  },
  {
    NAME: 'Repair Café Bladel',
    ADDRESS: 'Torendreef 20',
    LATITUDE: 51.370342,
    LONGITUDE: 5.224526,
    PHONE: '',
    EMAIL: 'info@kompasbladel.nl',
    WEBSITE: 'http://www.kompasbladel.nl/site/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde woensdag van de maand, van 09.00 tot 11.30 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Utrecht-University (De Uithof)',
    ADDRESS: 'Heidelberglaan 2',
    LATITUDE: 52.089653,
    LONGITUDE: 5.182856,
    PHONE: '',
    EMAIL: 'a.rademakers@uu.nl',
    WEBSITE: 'http://www.uu.nl/en/organisation/green-office-utrecht/project-support/student-projects/repair-cafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Dit Repair Café is tijdelijk gesloten.\n\nVoor meer info zie de website.',
  },
  {
    NAME: 'Repair Café Dordrecht-Wielwijk',
    ADDRESS: 'Admiraalsplein 168',
    LATITUDE: 51.790936,
    LONGITUDE: 4.65358,
    PHONE: '',
    EMAIL: 'repaircafe.wielwijk@gmail.com',
    WEBSITE:
      'http://www.wijkendordrecht.nl/wielwijk/nieuws-en-informatie/het-laatste-nieuws/archief/february-2015/feestelijke-opening-repair-cafe-wielwijk?swords=repair+cafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand van 09.00 tot 12.00 uur, behalve in augustus.',
  },
  {
    NAME: 'Repair Café Schagen en Middenmeer',
    ADDRESS: 'Acaciaplein 400',
    LATITUDE: 52.796623,
    LONGITUDE: 4.801897,
    PHONE: '',
    EMAIL: '',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Neem contact op met de organisatoren.\n\n\n\n<em>Er is ook een Repair Café-locatie in Middenmeer:\n\n</em>Posthuis Middenmeer\n\nPoststraat 16, Middenmeer',
  },
  {
    NAME: 'Repair Café Dordrecht-Sterrenburg',
    ADDRESS: 'Noorderkroonstraat 160',
    LATITUDE: 51.787231,
    LONGITUDE: 4.6842,
    PHONE: '',
    EMAIL: 'repaircafe.sterrenburg@gmail.com',
    WEBSITE: 'http://repaircafesterrenburg.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand van 10.00 tot 13.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Driebergen',
    ADDRESS: 'Oranjelaan 73',
    LATITUDE: 52.053837,
    LONGITUDE: 5.288807,
    PHONE: '',
    EMAIL: 'deinloopdriebergen@gmail.com',
    WEBSITE: 'http://driebergen.deinloop.net/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste vrijdag van de maand, 13.00 tot 15.30 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Nuth',
    ADDRESS: 'Markt 31',
    LATITUDE: 50.91729,
    LONGITUDE: 5.883815,
    PHONE: '',
    EMAIL: 'ljm.houben@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand, van 10.00 tot 13.00 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Deurne',
    ADDRESS: 'Rembrandt van Rijnstraat 5',
    LATITUDE: 51.452477,
    LONGITUDE: 5.787438,
    PHONE: '',
    EMAIL: 'j.berkers16@chello.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 09.30 tot 12.30 uur, en\n\nelke laatste dinsdag van de maand, van 13.30 tot 16.00 uur.\n\n\n\nZie ook lokale dag- en weekbladen voor afwijkende data\n\ni.v.m. feestdagen.',
  },
  {
    NAME: 'Repair Café Beemster-Middenbeemster',
    ADDRESS: 'Prinses Beatrixpark 54',
    LATITUDE: 52.546188,
    LONGITUDE: 4.914134,
    PHONE: '',
    EMAIL: 'm.spliet@rswp.eu',
    WEBSITE: 'http://www.buurthuiszuidoostbeemster.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede donderdag in de even maanden, van 09.30 tot 11.30 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Amersfoort-Zuid',
    ADDRESS: 'Woestijgerweg 3',
    LATITUDE: 52.147507,
    LONGITUDE: 5.384062,
    PHONE: '',
    EMAIL: 'a.akachar@kwintes.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste dinsdag van de maand, van 14.00 tot 16.00 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Hoorn',
    ADDRESS: 'Sint Eloystraat 106',
    LATITUDE: 52.649937,
    LONGITUDE: 5.06647,
    PHONE: '',
    EMAIL: 'j.fuchs@netwerkhoorn.nl',
    WEBSITE: 'https://www.netwerkhoorn.nl/activiteiten-cursussen/activiteiten-cursussen/educatie/repair-cafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke woensdag van de maand, van 09.30 tot 11.30 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Kamerik',
    ADDRESS: 'Nijverheidsweg 8',
    LATITUDE: 52.108276,
    LONGITUDE: 4.891683,
    PHONE: '',
    EMAIL: 'evelien51@hotmail.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste dinsdag van de maand, van 14.00 tot 17.00 uur.',
  },
  {
    NAME: 'Repair Café Ooltgensplaat',
    ADDRESS: 'Weesmolenstraat 12',
    LATITUDE: 51.685497,
    LONGITUDE: 4.344802,
    PHONE: '',
    EMAIL: 'repaircafeooltgensplaat@gmail.com',
    WEBSITE: 'http://www.repaircafeooltgensplaat.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde zaterdag van de maand van 10.00 tot 13.30 uur. Voor exacte data, zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Noordwijk-De Klei',
    ADDRESS: 'Rechthoeklaan 10',
    LATITUDE: 52.220886,
    LONGITUDE: 4.432117,
    PHONE: '',
    EMAIL: '',
    WEBSITE: 'http://www.sheerenloo.nl/-/9-mei-repair-cafe-willem-van-den-bergh',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand van 10.30 tot 12.30 uur. Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Kerkrade',
    ADDRESS: 'Burgemeester Savelberglaan 89',
    LATITUDE: 50.85994,
    LONGITUDE: 6.062209,
    PHONE: '',
    EMAIL: 'riaottenhoff4@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand, van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Borger-Odoorn',
    ADDRESS: 'Noorderdiep 137',
    LATITUDE: 52.96767,
    LONGITUDE: 6.954863,
    PHONE: '',
    EMAIL: 'marinushollander63@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede en vierde zaterdag van de maand, van 10.00 tot 12.00 uur.\n\nVoor exacte data zie de facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Almkerk',
    ADDRESS: 'Kerkstraat 1',
    LATITUDE: 51.771721,
    LONGITUDE: 4.96481,
    PHONE: '',
    EMAIL: 'info@kringloopcentrumaltena.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede dinsdag van de maand, van 09.30 tot 11.30 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Bovensmilde',
    ADDRESS: 'De Walstraat 16',
    LATITUDE: 52.978409,
    LONGITUDE: 6.479379,
    PHONE: '',
    EMAIL: 'duurzaambovensmilde@gmail.com',
    WEBSITE: 'http://www.duurzaambovensmilde.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag van de maand, van 13.00 tot 17.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Vinkeveen',
    ADDRESS: 'Herenweg 63C',
    LATITUDE: 52.214558,
    LONGITUDE: 4.934688,
    PHONE: '',
    EMAIL: 'a.keja@stdb.nl',
    WEBSITE: 'http://www.tympaan-debaat.nl/repair-café',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde maandag van de maand, van 09.30 tot 12.30 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Utrecht (Speciaal voor fietsen)',
    ADDRESS: 'Europalaan 2',
    LATITUDE: 52.074989,
    LONGITUDE: 5.105615,
    PHONE: '',
    EMAIL: 'dennis@utrechtfietst.nl',
    WEBSITE: 'http://www.hartenvoorsport.nl/bikerepaircafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Voor openingstijden zie de website.\n\n\n\nNeem je fiets mee en ga gezellig samen aan de slag bij het Bike Repair Café! Er is altijd een ervaren Bike-Fixer aanwezig om je te helpen. Gereedschap is aanwezig in de FixFiets: een mobiele werkplaats in de vorm van een bakfiets. Ook de kleine sleutelaars zijn welkom, zij kunnen hun bandenplakcertificaat halen.',
  },
  {
    NAME: 'Repair Café Diessen',
    ADDRESS: 'Rijtseweg 1',
    LATITUDE: 51.475986,
    LONGITUDE: 5.178156,
    PHONE: '',
    EMAIL: 'jaclinnemans@live.nl',
    WEBSITE: 'http://wij-wel.nl/repair-cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website.',
  },
  {
    NAME: 'Repair Café Aalten',
    ADDRESS: 'Polstraat 7',
    LATITUDE: 51.926689,
    LONGITUDE: 6.587122,
    PHONE: '',
    EMAIL: 'gerritrutgers@hotmail.com',
    WEBSITE: 'https://repaircafeaalten.wordpress.com/over-repair-cafe-aalten/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke donderdag van 14.00 tot 16.30 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: "Repair Café 's-Heer Arendskerke",
    ADDRESS: 'Torenring 46',
    LATITUDE: 51.492477,
    LONGITUDE: 3.823835,
    PHONE: '',
    EMAIL: 'shak-repaircafe@xs4all.nl',
    WEBSITE: 'http://www.heerarendhuis.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Biddinghuizen',
    ADDRESS: 'Baan 33',
    LATITUDE: 52.456573,
    LONGITUDE: 5.693407,
    PHONE: '',
    EMAIL: 'dejong.lj@solcon.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand (behalve juli en augustus) van 10.00 tot 12.00 uur.',
  },
  {
    NAME: 'Repair Café Doorn',
    ADDRESS: 'Kerkplein 1',
    LATITUDE: 52.032925,
    LONGITUDE: 5.344822,
    PHONE: '',
    EMAIL: 'henktebrake@hotmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde vrijdag van de maand, van 13:30 – 16:00 uur. In juli en augustus is het Repair Café gesloten.',
  },
  {
    NAME: 'Repair Café Papendrecht',
    ADDRESS: 'P.S. Gerbrandystraat 31',
    LATITUDE: 51.828705,
    LONGITUDE: 4.700012,
    PHONE: '',
    EMAIL: 'repaircafe.papendrecht@gmail.com',
    WEBSITE: 'http://www.repaircafepapendrecht.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand van 10.00 tot 13.00 uur, behalve in juli en augustus.',
  },
  {
    NAME: 'Repair Café Stadskanaal',
    ADDRESS: 'Navolaan 30',
    LATITUDE: 52.983719,
    LONGITUDE: 6.960062,
    PHONE: '',
    EMAIL: 'info@repaircafe-stadskanaal.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke donderdag, van 13.00 tot 15.30 uur.\n\nVoor meer informatie zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Bredevoort',
    ADDRESS: 'Kruittorenstraat 1',
    LATITUDE: 51.945873,
    LONGITUDE: 6.619811,
    PHONE: '',
    EMAIL: 'arjantwigt18@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste en derde vrijdag van de maand, van 14.00 tot 16.30 uur.',
  },
  {
    NAME: 'Repair Café Leusden',
    ADDRESS: 'Bavoortseweg 25',
    LATITUDE: 52.131718,
    LONGITUDE: 5.414894,
    PHONE: '',
    EMAIL: 'carola67@hotmail.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede vrijdag van de maand, van 13.30 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Echt',
    ADDRESS: 'Diepstraat 3C',
    LATITUDE: 51.10738,
    LONGITUDE: 5.865177,
    PHONE: '',
    EMAIL: 'castor0502@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand, van 10.00 tot 13.00 uur.',
  },
  {
    NAME: 'Repair Café Noordwijk',
    ADDRESS: 'Johanna van Hoornstraat 8',
    LATITUDE: 52.241402,
    LONGITUDE: 4.448882,
    PHONE: '',
    EMAIL: 'dennis@repaircafenorthgo.nl',
    WEBSITE: 'http://www.repaircafenorthgo.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag van de maand van 14.00 tot 17.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Venlo-Zuid',
    ADDRESS: 'Reigerstraat 2',
    LATITUDE: 51.359653,
    LONGITUDE: 6.164752,
    PHONE: '',
    EMAIL: 'repaircafevenlozuid@gmail.com',
    WEBSITE: 'https://www.burenhulpvenlozuid.nl/repair_cafe.html',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke vierde zaterdag van de maand, van 12.00 tot 16.00 uur.\n\nVoor exacte data zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Amsterdam-Kantoor',
    ADDRESS: 'Molukkenstraat 200E',
    LATITUDE: 52.357414,
    LONGITUDE: 4.940274,
    PHONE: '',
    EMAIL: 'martine@repaircafe.nl',
    WEBSITE: 'https://repaircafe.org',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Geen Repair Café-locatie',
  },
  {
    NAME: 'Repair Café Tilburg-La Poubelle',
    ADDRESS: 'Hoevenseweg 3',
    LATITUDE: 51.553501,
    LONGITUDE: 5.104005,
    PHONE: '',
    EMAIL: 'info@repaircafe-lapoubelle.nl',
    WEBSITE: 'http://www.repaircafe-lapoubelle.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste donderdag van de maand, van 19.30 tot 21.30 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Groningen-Paddepoel',
    ADDRESS: 'Antaresstraat 45',
    LATITUDE: 53.231171,
    LONGITUDE: 6.540432,
    PHONE: '',
    EMAIL: 'e.hoeksema@lentis.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Om de maand op zaterdag, van 11.00 tot 14.00 uur. Niet in juli en augustus. Voor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Apeldoorn-Noord',
    ADDRESS: 'Vlijtseweg 136',
    LATITUDE: 52.225922,
    LONGITUDE: 5.972431,
    PHONE: '',
    EMAIL: 'kgrimmelikhuisen@foenix.nl',
    WEBSITE: 'http://www.foenix.nl/#!informatie/repair-cafe-/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Maandag tot en met vrijdag, van 9.00 tot 16.30 uur.\n\n\n\n&nbsp;\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Abcoude',
    ADDRESS: 'Dorpszicht 22',
    LATITUDE: 52.274151,
    LONGITUDE: 4.97443,
    PHONE: '',
    EMAIL: 'a.keja@stdb.nl',
    WEBSITE: 'http://www.tympaan-debaat.nl/repair-café-0',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste vrijdag van de maand van 10.00 tot 12.30 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Uithuizen',
    ADDRESS: 'Schoolstraat 22',
    LATITUDE: 53.408482,
    LONGITUDE: 6.67375,
    PHONE: '',
    EMAIL: '',
    WEBSITE: 'https://repaircafeuithuizen.wordpress.com/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste vrijdag van de maand, van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Peel en Maas - Maasbree',
    ADDRESS: 'Kennedyplein 2',
    LATITUDE: 51.356853,
    LONGITUDE: 6.047834,
    PHONE: '',
    EMAIL: 'rc.peelenmaas@gmail.com',
    WEBSITE: 'https://repaircafe.org/locations/repair-cafe-peel-en-maas-helden/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Let op! Repair Café Peel en Maas wordt behalve in Maasbree ook gehouden in <a href="https://repaircafe.org/locations/repair-cafe-peel-en-maas-helden/">Helden</a>.\n\n\n\nElke derde zaterdag van de maand, van 10.30 tot 13.00 uur. \n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Oosterwolde',
    ADDRESS: 'Venekoterweg 21',
    LATITUDE: 52.981682,
    LONGITUDE: 6.295416,
    PHONE: '',
    EMAIL: 'boaboa98@hotmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Op de zaterdagen van de even kalenderweken, van 10.00 tot 13.00 uur. \n\nVoor exacte data zie de Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Meijel',
    ADDRESS: 'Raadhuisplein 3',
    LATITUDE: 51.344528,
    LONGITUDE: 5.885223,
    PHONE: '',
    EMAIL: 'janoomen52@gmail.com',
    WEBSITE: 'http://www.oppekoffie.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Alle zaterdagen in de oneven weken, van 09.30 tot 11.30 uur.\n\nVoor exacte data zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Amsterdam-West Westerpark',
    ADDRESS: 'Van Limburg Stirumstraat 119',
    LATITUDE: 52.383549,
    LONGITUDE: 4.876791,
    PHONE: '',
    EMAIL: 'l.vankampen@abc-west.nl',
    WEBSITE: 'http://www.abc-west.nl/repair-cafe-3/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke woensdag, van 10.00 tot 12.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Eindhoven-Zuid',
    ADDRESS: 'Seringenstraat 9',
    LATITUDE: 51.42131,
    LONGITUDE: 5.489861,
    PHONE: '',
    EMAIL: 'repaircafeeindhovenzuid@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 14.00 tot 17.00 uur.',
  },
  {
    NAME: 'Repair Café Doetinchem',
    ADDRESS: 'Dr. Huber Noodtstraat 6',
    LATITUDE: 51.967075,
    LONGITUDE: 6.290825,
    PHONE: '',
    EMAIL: 'repaircafe@stadskamer.com',
    WEBSITE: 'https://www.stadskamer.com/dtc/repair-cafe-doetinchem/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke donderdagmiddag van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Leersum',
    ADDRESS: 'Hoflaan 29',
    LATITUDE: 52.010677,
    LONGITUDE: 5.424932,
    PHONE: '',
    EMAIL: 'th_smit@hotmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vierde zaterdag van de maand. \n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Wijchen',
    ADDRESS: 'Urnenveldweg 10',
    LATITUDE: 51.806767,
    LONGITUDE: 5.740199,
    PHONE: '',
    EMAIL: 'info@repaircafewijchen.nl',
    WEBSITE: 'http://www.repaircafewijchen.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste zaterdag van de maand, van 10.00 tot 13.00 uur (aanmelden tot 12.30 uur).\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Bergen NH',
    ADDRESS: 'Kogendijk 42a',
    LATITUDE: 52.663776,
    LONGITUDE: 4.717323,
    PHONE: '',
    EMAIL: 'j.ter.koele@quicknet.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste en derde vrijdag van de maand, van 09.30 tot 12.00 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Warnsveld',
    ADDRESS: 'Dreiumme 43',
    LATITUDE: 52.134666,
    LONGITUDE: 6.230111,
    PHONE: '',
    EMAIL: 'marionvanarragon@gmail.com',
    WEBSITE: 'http://www.warnshuus.nl/2130/agenda/repair-cafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede dinsdag van de maand, van 19.00 tot 21.00 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Den Haag-Laakkwartier',
    ADDRESS: 'Jonckbloetplein 24',
    LATITUDE: 52.058918,
    LONGITUDE: 4.321543,
    PHONE: '',
    EMAIL: 'b.sahin@xtra.nlbilalsahin',
    WEBSITE: 'http://www.haagsevaders.nl/site/index.php/cursussen/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde donderdag van de maand van 14.00 tot 17.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Ten Boer',
    ADDRESS: 'Sportlaan 2',
    LATITUDE: 53.277176,
    LONGITUDE: 6.700123,
    PHONE: '',
    EMAIL: 'repaircafetenboer@gmail.com',
    WEBSITE: 'https://www.dorpsbelangentenboer.nl/activiteiten/repaircafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: '4 zaterdagen in het jaar, van 10.00 tot 14.00 uur. Voor exacte data neem contact op met de organisator.',
  },
  {
    NAME: 'Repair Café Naarden',
    ADDRESS: 'Kolonel Verveerstraat 74 /',
    LATITUDE: 52.298763,
    LONGITUDE: 5.147648,
    PHONE: '',
    EMAIL: 'info@wijkpostdeschakel.nl',
    WEBSITE: 'http://www.repaircafenaarden.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Zwolle-Assendorp',
    ADDRESS: 'Enkstraat 67',
    LATITUDE: 52.506798,
    LONGITUDE: 6.09904,
    PHONE: '',
    EMAIL: 'repaircafeenk67@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde zaterdag van de maand, van 14.00 tot 17.00 uur. Voor exacte data, zie de Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Eindhoven-Gestel',
    ADDRESS: 'Kastelenplein 167',
    LATITUDE: 51.418842,
    LONGITUDE: 5.441821,
    PHONE: '',
    EMAIL: 'p.loog@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde zaterdag van de maand, van 13.00 tot 16.00 uur.\n\nVoor exacte data neem contact op met de organisator.',
  },
  {
    NAME: 'Repair Café Bodegraven',
    ADDRESS: 'Prins Hendrikstraat 31',
    LATITUDE: 52.083111,
    LONGITUDE: 4.746153,
    PHONE: '',
    EMAIL: 'repaircafebodegraven@kpnmail.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vrijdag van 13.00 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Ridderkerk',
    ADDRESS: 'Boksdoornstraat 80',
    LATITUDE: 51.871853,
    LONGITUDE: 4.585477,
    PHONE: '',
    EMAIL: 'info@repaircaferidderkerk.nl',
    WEBSITE: 'http://www.repaircaferidderkerk.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand van 10.00 tot 12.00 uur.',
  },
  {
    NAME: 'Repair Café Assen',
    ADDRESS: 'Vesteplein 5',
    LATITUDE: 52.99287,
    LONGITUDE: 6.564206,
    PHONE: '',
    EMAIL: 'repaircafe.assen@gmail.com',
    WEBSITE: 'http://www.repaircafe-assen.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 11.00 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Wierden',
    ADDRESS: 'Marktstraat 26',
    LATITUDE: 52.357716,
    LONGITUDE: 6.590084,
    PHONE: '',
    EMAIL: 'repaircafewierden@gmail.com',
    WEBSITE: 'http://www.repaircafewierden.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede en vierde donderdag van de maand, van 14.00 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Enkhuizen',
    ADDRESS: 'Paktuinen 1',
    LATITUDE: 52.700798,
    LONGITUDE: 5.292954,
    PHONE: '',
    EMAIL: 'info@drom.nl',
    WEBSITE: 'http://www.drom.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde dinsdag, van 14.00 tot 16.00 uur. Voor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Hoogezand-Sappemeer',
    ADDRESS: 'Pleiaden 21',
    LATITUDE: 53.14959,
    LONGITUDE: 6.742698,
    PHONE: '',
    EMAIL: 'toine@savitar.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zondag van de maand, van 14.00 tot 17.00 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Rotterdam-De Esch',
    ADDRESS: 'Rijnwaterstraat 23b',
    LATITUDE: 51.913261,
    LONGITUDE: 4.520722,
    PHONE: '',
    EMAIL: 'j.vanveen14@chello.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Dinsdag: 10.00 &#8211; 12.00 uur\n\nWoensdag: 13.00 &#8211; 16.00 uur\n\nDonderdag: 13.00 &#8211; 16.00 uur',
  },
  {
    NAME: 'Repair Café Loosdrecht',
    ADDRESS: 'Tjalk 41',
    LATITUDE: 52.197388,
    LONGITUDE: 5.13775,
    PHONE: '',
    EMAIL: 'fjansen@bibliotheekgooienmeer.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste zaterdag van de maand, van 10.00 tot 13.30 uur (in juli en augustus niet in verband met vakantie).',
  },
  {
    NAME: 'Repair Café Enschede-Noord',
    ADDRESS: 'Abraham Strickstraat 23',
    LATITUDE: 52.226982,
    LONGITUDE: 6.864206,
    PHONE: '',
    EMAIL: 'repaircafe053@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke maandag, van 14.00 tot 16.00 uur.\n\nVoor exacte data, zie de Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Boxtel',
    ADDRESS: 'Industrieweg 6',
    LATITUDE: 51.579533,
    LONGITUDE: 5.313667,
    PHONE: '',
    EMAIL: 'ttboxtel@gmail.com',
    WEBSITE: 'http://boxtel.transitiontowns.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website.',
  },
  {
    NAME: 'Repair Café Silvolde',
    ADDRESS: 'Laan van Schuylenburch 10',
    LATITUDE: 51.91259,
    LONGITUDE: 6.375061,
    PHONE: '',
    EMAIL: 'm.kraaijenbrink@azora.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand, van 10.00 tot 13.00 uur.',
  },
  {
    NAME: 'Repair Café Nederweert',
    ADDRESS: 'Kapelaniestraat 4',
    LATITUDE: 51.286449,
    LONGITUDE: 5.749588,
    PHONE: '',
    EMAIL: 'repaircafenederweert@telfortglasvezel.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 10.00 tot 13.00 uur.',
  },
  {
    NAME: 'Repair Café Scharnegoutum',
    ADDRESS: 'Legedyk 43',
    LATITUDE: 53.059612,
    LONGITUDE: 5.679289,
    PHONE: '',
    EMAIL: 'repaircafe@scharnegoutum.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 10.00 tot 12.00 uur, behalve in de maanden juli en augustus.',
  },
  {
    NAME: 'Repair Café Ulrum',
    ADDRESS: 'Borgstee 3',
    LATITUDE: 53.358292,
    LONGITUDE: 6.334158,
    PHONE: '',
    EMAIL: 'dinymei@hotmail.com',
    WEBSITE: 'http://www.dorpszorgulrum.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede woensdag van de maand, van 14.00 tot 16.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Amsterdam-Oost Transvaalbuurt',
    ADDRESS: 'Hofmeyrstraat 67',
    LATITUDE: 52.350742,
    LONGITUDE: 4.917422,
    PHONE: '',
    EMAIL: 'bikovoordebuurt@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede en vierde zaterdag van de maand, van 13:00 tot 16:00 uur.\n\nVoor exacte data neem contact op met de organisator.',
  },
  {
    NAME: 'Repair Café Velp',
    ADDRESS: 'Heeckerensstraat 201',
    LATITUDE: 51.988457,
    LONGITUDE: 5.965852,
    PHONE: '',
    EMAIL: 'repaircafevelp@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke vierde zaterdag van de maand, van 14.00 tot 16.00 uur.\n\nVoor exacte data zie de Facebookpagina hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Bathmen',
    ADDRESS: 'Schoolstraat 6a',
    LATITUDE: 52.249771,
    LONGITUDE: 6.286676,
    PHONE: '',
    EMAIL: 'infopunt@bathmen.nl',
    WEBSITE: 'https://www.bathmen.nl/praktisch/repair-café/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Zie de website.',
  },
  {
    NAME: 'Repair Café Beverwijk',
    ADDRESS: 'Westerhoutplein 3',
    LATITUDE: 52.48415,
    LONGITUDE: 4.642876,
    PHONE: '',
    EMAIL: 'c.olijnsma@live.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag van de maand, van 12.30 tot 15.30 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Westerbork',
    ADDRESS: 'Beukenlaan 30',
    LATITUDE: 52.854313,
    LONGITUDE: 6.604849,
    PHONE: '',
    EMAIL: '',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag van de maand, van 10.00 tot 13.00 uur.\n\nNiet in juli en augustus.',
  },
  {
    NAME: 'Repair Café Elst (Utr.)',
    ADDRESS: 'Oranjestraat 17',
    LATITUDE: 51.984928,
    LONGITUDE: 5.499744,
    PHONE: '',
    EMAIL: 'Els@clerx.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste dinsdag van de maand, van 13.30 tot 16.00 uur.',
  },
  {
    NAME: 'Testlocatie NL',
    ADDRESS: 'Gruttersdijk',
    LATITUDE: 52.098743,
    LONGITUDE: 5.115721,
    PHONE: '',
    EMAIL: '',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: '<p>Test</p>\n\n<p>info [at] repaircafe.org</p>\n\n',
  },
  {
    NAME: 'Repair Café IJlst',
    ADDRESS: 'Ylostinslaan 1-r 8',
    LATITUDE: 53.013344,
    LONGITUDE: 5.616895,
    PHONE: '',
    EMAIL: 'info@perron2.nl',
    WEBSITE: 'http://perron2.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde zaterdag van de maand, van 11.00 tot 15.00 uur. \n\nVoor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Utrecht-Zuilen',
    ADDRESS: 'st. Ludgerusstraat 251',
    LATITUDE: 52.110027,
    LONGITUDE: 5.0869,
    PHONE: '',
    EMAIL: 'info@repaircafezuilen.nl',
    WEBSITE: 'http://repaircafezuilen.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 10.00 tot 13.00 uur.\n\nVoor exacte data zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Egmond aan Zee',
    ADDRESS: 'Voorstraat 41',
    LATITUDE: 52.617867,
    LONGITUDE: 4.631466,
    PHONE: '',
    EMAIL: 'm.pema@zorgcirkel.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste donderdag van de maand, van 13.00 tot 15.00 uur.',
  },
  {
    NAME: 'Repair Café Delden',
    ADDRESS: 'Langestraat 79',
    LATITUDE: 52.261829,
    LONGITUDE: 6.70759,
    PHONE: '',
    EMAIL: 'bschurink@sbrdelden.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand, van 10.00 tot 13.00 uur.',
  },
  {
    NAME: 'Repair Café Almelo I',
    ADDRESS: 'Twentelaan 2',
    LATITUDE: 52.331074,
    LONGITUDE: 6.649523,
    PHONE: '',
    EMAIL: 'info@tvaservice.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste vrijdag van de maand, van 13.00 tot 17.00 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Amstelveen-Bankras Kostverloren',
    ADDRESS: 'Den Bloeyenden Wijngaerdt 1',
    LATITUDE: 52.307419,
    LONGITUDE: 4.878081,
    PHONE: '',
    EMAIL: 'b.voorend@aanz-amstelveen.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand, van 10.00 tot 14.00 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Eelde-Paterswolde',
    ADDRESS: 'Bähler Boermaweg 4',
    LATITUDE: 53.146877,
    LONGITUDE: 6.563571,
    PHONE: '',
    EMAIL: 'g.r.wagenaar@kpnmail.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede vrijdag van de maand, van 13.00 tot 16.30 uur.',
  },
  {
    NAME: 'Repair Café Amstelveen-Uilenstede',
    ADDRESS: 'Uilenstede 348',
    LATITUDE: 52.320896,
    LONGITUDE: 4.875555,
    PHONE: '',
    EMAIL: 'info@generationzen.nl',
    WEBSITE: 'http://www.generationzen.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Almelo II',
    ADDRESS: 'Kruisweg 2',
    LATITUDE: 52.348164,
    LONGITUDE: 6.670775,
    PHONE: '',
    EMAIL: 'info@repaircafealmelo.nl',
    WEBSITE: 'http://www.repaircafealmelo.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede vrijdag van de maand, van 12.30 tot 15.30 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Reuver',
    ADDRESS: 'Pastoor Vranckenlaan 18',
    LATITUDE: 51.284328,
    LONGITUDE: 6.077176,
    PHONE: '',
    EMAIL: 'wielweijers@home.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand, van 10.00 tot 13.00 uur.',
  },
  {
    NAME: "Repair Café 's Gravenmoer",
    ADDRESS: 'Hoofdstraat 4',
    LATITUDE: 51.657394,
    LONGITUDE: 4.94034,
    PHONE: '',
    EMAIL: 'willem@deboek.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag van de maand van 10.00 tot 12.00 uur.\n\nVoor exacte data neem contact op met de organisator.',
  },
  {
    NAME: 'Repair Café Eemnes',
    ADDRESS: 'Wakkerendijk 66',
    LATITUDE: 52.247917,
    LONGITUDE: 5.264905,
    PHONE: '',
    EMAIL: 'repaircafe.eemnes@gmail.com',
    WEBSITE: 'http://repaircafeeemnes.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde zaterdag van de maand, van 10.00 tot 13.00 uur (na 12.30 geen inname kapotte spullen meer). Voor exacte data zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Renkum',
    ADDRESS: 'Europalaan 11',
    LATITUDE: 51.973396,
    LONGITUDE: 5.729814,
    PHONE: '',
    EMAIL: 'jansplinterrenkum@live.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke vierde zaterdag van de maand, van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de Facebookpagina hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Gouda-Het Bruisnest',
    ADDRESS: 'Blekerssingel 78',
    LATITUDE: 52.013378,
    LONGITUDE: 4.714276,
    PHONE: '',
    EMAIL: 'info@hetbruisnest.nl',
    WEBSITE: 'https://www.hetbruisnest.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde woensdag van de maand van 10.00 tot 14.00 uur. Voor exacte data, zie de Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Nijmegen-Noord',
    ADDRESS: 'Queenstraat 37b',
    LATITUDE: 51.870865,
    LONGITUDE: 5.86836,
    PHONE: '',
    EMAIL: 'groenevingers2010@live.nl',
    WEBSITE: 'http://repaircafenijmegen.nl/noord',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke vierde zaterdag van de maand, van 14.00 tot 16.30 uur uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Aa en Hunze',
    ADDRESS: 'Semsstraat 95',
    LATITUDE: 53.055965,
    LONGITUDE: 6.836603,
    PHONE: '',
    EMAIL: 'info@impulsaaenhunze.nl',
    WEBSITE: 'http://www.impulsaaenhunze.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vierde donderdag van de maand, van 10.00 tot 12.00 uur.',
  },
  {
    NAME: 'Repair Café Heiligerlee',
    ADDRESS: 'Provincialeweg 19',
    LATITUDE: 53.156105,
    LONGITUDE: 7.00473,
    PHONE: '',
    EMAIL: 'jannieorsel1@hotmail.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Maandag tot en met zaterdag, van 10.00 tot 17.00 uur.',
  },
  {
    NAME: 'Repair Café Den Haag-Rustenburg-Oostbroek',
    ADDRESS: 'Escamplaan 61G',
    LATITUDE: 52.06477,
    LONGITUDE: 4.276678,
    PHONE: '',
    EMAIL: 'm.yakoubi@middin.nl',
    WEBSITE: 'http://www.repaircafedenhaag.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Egmond-Binnen',
    ADDRESS: 'Visweg 45',
    LATITUDE: 52.599487,
    LONGITUDE: 4.653209,
    PHONE: '',
    EMAIL: 'beheerder@deschulp.nl',
    WEBSITE: 'http://www.deschulp.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke vierde vrijdag van de maand, van 09.30 tot 12.00 uur (niet in augustus)\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Brielle',
    ADDRESS: 'Reede 2A',
    LATITUDE: 51.895565,
    LONGITUDE: 4.15943,
    PHONE: '',
    EMAIL: 'ineke.van.der.jagt.biezepol@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede woensdag in de maand van 14.00 tot 16.00, uitgezonderd schoolvakanties. Voor exacte data, zie de Facebookpagina van dit Repair Café.',
  },
  {
    NAME: 'Repair Café Harmelen',
    ADDRESS: 'De Joncheerelaan 7',
    LATITUDE: 52.092518,
    LONGITUDE: 4.962396,
    PHONE: '',
    EMAIL: 'repaircafeharmelen@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag van de maand, van 10.00 tot 12.00 uur.\n\n(niet in juli en augustus)',
  },
  {
    NAME: 'Repair Café Amsterdam-IJburg',
    ADDRESS: 'Erich Salomonstraat 131-151',
    LATITUDE: 52.359386,
    LONGITUDE: 4.992787,
    PHONE: '',
    EMAIL: 'jannikeoltmans@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke woensdag, van 17.00 tot 19.00 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Heino',
    ADDRESS: 'Marktstraat 7',
    LATITUDE: 52.435963,
    LONGITUDE: 6.234827,
    PHONE: '',
    EMAIL: 'huizelankhorst@online.nl',
    WEBSITE: 'http://www.dorpshuusheino.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand, van 09.30 tot 12.00 uur.',
  },
  {
    NAME: 'Repair Café Ruurlo',
    ADDRESS: 'Dorpsstraat 74',
    LATITUDE: 52.087482,
    LONGITUDE: 6.448798,
    PHONE: '',
    EMAIL: 'repaircaferuurlo@outlook.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste donderdag van de maand van 13.30 tot 16.30 uur. Voor exacte data zie de Facebookpagina hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Cuijk',
    ADDRESS: 'Patrijzenveld 117',
    LATITUDE: 51.718517,
    LONGITUDE: 5.883205,
    PHONE: '',
    EMAIL: 'repaircafe.cuijk@outlook.com',
    WEBSITE: 'http://repaircafecuijk.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag in de maand, van 09.00 tot 12.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Meppel',
    ADDRESS: 'Groenmarktstraat 6',
    LATITUDE: 52.696545,
    LONGITUDE: 6.191431,
    PHONE: '',
    EMAIL: 'willemijn@debasismeppel.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand, van 09.30 tot 13.00 uur.',
  },
  {
    NAME: 'Repair Cafe Haastrecht',
    ADDRESS: 'Concordiaplein 1',
    LATITUDE: 52.000034,
    LONGITUDE: 4.77641,
    PHONE: '',
    EMAIL: 'info@theaterconcordia.nl',
    WEBSITE: 'http://www.theaterconcordia.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Vanaf december elke eerste donderdag van de maand van 13.30 tot 16.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Best',
    ADDRESS: 'De Leemkuilen 101',
    LATITUDE: 51.505466,
    LONGITUDE: 5.397514,
    PHONE: '',
    EMAIL: 'repaircafebest@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vrijdag, van 13.00 tot 15.00 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Rheden',
    ADDRESS: 'Meester B. van Leeuwenplein 3',
    LATITUDE: 52.005196,
    LONGITUDE: 6.033049,
    PHONE: '',
    EMAIL: 'ansinekevanoosterom@gmail.com',
    WEBSITE: 'http://www.repaircafe-rheden.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste zaterdag van de maand, van 09.30 tot 12.00 uur.\n\nVoor exacte data zie de website hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Helmond-Oost',
    ADDRESS: 'Evertsenstraat 19',
    LATITUDE: 51.474869,
    LONGITUDE: 5.68276,
    PHONE: '',
    EMAIL: 'repaircafe@swrho.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'In de even weken op zaterdag van 10.00 tot 13.00 uur.\n\nIn de oneven weken op woensdag van 14.00 tot 17.00 uur.\n\nHet Repair Café is in de kerst- en zomervakantie gesloten.\n\nVoor exacte data neem contact op met de organisator.',
  },
  {
    NAME: 'Repair Café Schoorl',
    ADDRESS: 'De Sanderij 7',
    LATITUDE: 52.705776,
    LONGITUDE: 4.692669,
    PHONE: '',
    EMAIL: 'm.otto@welzijnbergen.nl',
    WEBSITE: 'https://repaircafegemeentebergen.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede en vierde woensdag van de maand, van 13.30 tot 16.00 uur. Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Westland-Monster',
    ADDRESS: 'Havenstraat 16',
    LATITUDE: 52.021137,
    LONGITUDE: 4.173681,
    PHONE: '',
    EMAIL: 'rcwestland@outlook.com',
    WEBSITE:
      'http://www.vitiswelzijn.nl/site/nl/wijkcentra-en-activiteiten/activiteiten/overzicht-activiteiten/repair-cafe/nl-57-2500',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste donderdag van de maand van 13.30 tot 16.00 uur. Voor exacte data, zie de website hieronder.\n\n\n\nRepair Café Westland heeft nog twee andere locaties:\n\n<a href="https://repaircafe.org/locatie/repair-cafe-westland-hoek-van-holland/">Repair Café Westland-Hoek van Holland</a>\n\n<a href="https://repaircafe.org/locatie/repair-cafe-westland-poeldijk/">Repair Café Westland-Poeldijk</a>',
  },
  {
    NAME: 'Repair Cafe Pernis',
    ADDRESS: 'Ring 15',
    LATITUDE: 51.88755,
    LONGITUDE: 4.392631,
    PHONE: '',
    EMAIL: 'wilcodiejen@live.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand van 09.30 tot 12.30 uur. Voor exacte data, zie de Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Westervoort-Duiven',
    ADDRESS: 'Noordelijke Parallelweg 1',
    LATITUDE: 51.962997,
    LONGITUDE: 5.970152,
    PHONE: '',
    EMAIL: 'repaircafewestervoortduiven@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke woensdagmiddag, van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Oudega (Smallingerland)',
    ADDRESS: 'Buorren 34c',
    LATITUDE: 53.125324,
    LONGITUDE: 5.995923,
    PHONE: '',
    EMAIL: 'j.huizenga@upcmail.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: '',
  },
  {
    NAME: 'Repair Café Voorschoten',
    ADDRESS: 'Schoolstraat 100 en 108',
    LATITUDE: 52.12558,
    LONGITUDE: 4.44594,
    PHONE: '',
    EMAIL: 'repaircafevoorschoten@gmail.com',
    WEBSITE: 'http://www.repaircafevoorschoten.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand van 10.00 tot 13.00 uur\n\n(niet in de vakantiemaanden).',
  },
  {
    NAME: 'Repair Café Woudenberg',
    ADDRESS: 'Dorpsstraat 40',
    LATITUDE: 52.081757,
    LONGITUDE: 5.417895,
    PHONE: '',
    EMAIL: 'fjschot1940@kpnmail.nl',
    WEBSITE: 'http://www.duurzaamwoudenberg.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde vrijdag van de maand, van 14.00 tot 16.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Meers',
    ADDRESS: 'Kloosterstraat 19A',
    LATITUDE: 50.962177,
    LONGITUDE: 5.741817,
    PHONE: '',
    EMAIL: 'info@dorpsdagvoorzieningmeers.nl',
    WEBSITE: 'http://www.dorpsdagvoorzieningmeers.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand, van 10.00 tot 14.00 uur. Voor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Eindhoven Gestel Hagenkamp',
    ADDRESS: 'Maria van Bourgondiëlaan 8',
    LATITUDE: 51.431423,
    LONGITUDE: 5.458799,
    PHONE: '',
    EMAIL: 'm.schoenmakers@vitalisgroep.nl',
    WEBSITE: 'https://vitalis.samenuitagenda.nl/evenement/1441-repair-cafe-parc-',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke woensdag, van 10.00 tot 12.00 uur. Voor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Zierikzee-Poortambacht',
    ADDRESS: 'Scheldestraat 3',
    LATITUDE: 51.649342,
    LONGITUDE: 3.908316,
    PHONE: '',
    EMAIL: 'repaircafe@hartvanpoortambacht.nl',
    WEBSITE: 'http://repaircafe.hartvanpoortambacht.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand van 10.00 tot 14.00 uur. \n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Bussum',
    ADDRESS: 'Nieuwe Englaan 6',
    LATITUDE: 52.273857,
    LONGITUDE: 5.175225,
    PHONE: '',
    EMAIL: 'repaircafebussum@gmail.com',
    WEBSITE: 'http://repaircafebussum.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 10.00 tot 13.00 uur.\n\nKijk voor exacte data en adressen altijd op de <strong>website</strong> of <strong>Facebookpagina </strong>(het Repair Café wordt op verschillende locaties gehouden!)',
  },
  {
    NAME: 'Repair Café Beek',
    ADDRESS: 'Callistusplein 9',
    LATITUDE: 50.951988,
    LONGITUDE: 5.814543,
    PHONE: '',
    EMAIL: 'repaircafebeek@gmail.com',
    WEBSITE: 'http://www.repaircafebeek.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 10.30 tot 14.30 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Weesp-Muiden',
    ADDRESS: 'De Wintertuin 24',
    LATITUDE: 52.305176,
    LONGITUDE: 5.043455,
    PHONE: '',
    EMAIL: 'bhees@versawelzijn.nl',
    WEBSITE: 'http://www.repaircafeweespmuiden.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Spijkenisse Bewonersgroep Waterland',
    ADDRESS: 'Karperveen 155',
    LATITUDE: 51.832542,
    LONGITUDE: 4.345676,
    PHONE: '',
    EMAIL: 'bewonersgroepwaterland@gmail.com',
    WEBSITE: 'http://www.bewonersgroepwaterland.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke maandag van de oneven weken van 13.00 tot 15.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Neder-Betuwe',
    ADDRESS: 'Swaenenstate 40',
    LATITUDE: 51.934578,
    LONGITUDE: 5.627753,
    PHONE: '',
    EMAIL: 'warends1959@kpnmail.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      '<span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;eerste woensdag van de maand in Opheusden van 14:00-16:00 , de derde woensdag van de maand in Ochten van 14:00-16:00&quot;}" data-sheets-userformat="{&quot;2&quot;:512,&quot;12&quot;:0}">Elke eerste woensdag van de maand in Opheusden, van 14.00-16.00 uur. Elke derde woensdag van de maand in Ochten (Dokter M. van Drielplein, 4051 AX Ochten), van 14.00-16.00 uur. Voor exacte data zie de Facebookpagina hieronder.</span>\n\n\n\n&nbsp;\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Amsterdam-Centrum De Boomsspijker',
    ADDRESS: 'Recht Boomssloot 52',
    LATITUDE: 52.371975,
    LONGITUDE: 4.903493,
    PHONE: '',
    EMAIL: 'vesser@dock.nl',
    WEBSITE: 'http://dock.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste maandag van de maand, van 13.00 tot 15.00 uur. Voor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Amsterdam-Kolenkitbuurt',
    ADDRESS: 'Piet Paaltjenspad 9',
    LATITUDE: 52.37986,
    LONGITUDE: 4.842196,
    PHONE: '',
    EMAIL: 'info@cascoland.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vrijdag, van 14.00 tot 17.00 uur.',
  },
  {
    NAME: 'Repair Café Brummen',
    ADDRESS: 'Meidoornlaan 18B',
    LATITUDE: 52.092392,
    LONGITUDE: 6.154551,
    PHONE: '',
    EMAIL: 'st.onzedroom@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vierde donderdag van de maand, van 10.00 tot 12.00 uur. Voor exacte data zie de link hieronder.',
  },
  {
    NAME: 'Repair Café Maarn Maarsbergen',
    ADDRESS: 'Kapelweg 45',
    LATITUDE: 52.06377,
    LONGITUDE: 5.367166,
    PHONE: '',
    EMAIL: 'info@repaircafemaarnmaarsbergen.nl',
    WEBSITE: 'http://www.repaircafemaarnmaarsbergen.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde zaterdag van de maand, van 9.30 tot 12.00 uur. \n\nVoor exacte data zie de website.\n\n\n\nE-mailadres voor aanmelden reparatie: reparatie [at] repaircafemaarnmaarsbergen.nl',
  },
  {
    NAME: 'Repair Café Tegelen',
    ADDRESS: 'Haandertstraat 6-8',
    LATITUDE: 51.339165,
    LONGITUDE: 6.145426,
    PHONE: '',
    EMAIL: 'repaircafetegelen@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag van de maand (niet op feestdagen), van 13.00 tot 16.00 uur. Voor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Delft-Delfshove',
    ADDRESS: 'Vorrinkplein 99',
    LATITUDE: 52.000961,
    LONGITUDE: 4.353023,
    PHONE: '',
    EMAIL: 'info@repaircafedelft.nl',
    WEBSITE: 'http://repaircafedelft.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste zaterdag van de maand van 11.00 tot circa 14.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Olst',
    ADDRESS: 'Hendrik Droststraat 47',
    LATITUDE: 52.338585,
    LONGITUDE: 6.109194,
    PHONE: '',
    EMAIL: 'rcolst@xs4all.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste vrijdag van de maand, van 13.00 tot 15.30 uur.',
  },
  {
    NAME: 'Repair Café Haarlemmermeer-Lisserbroek',
    ADDRESS: 'Krabbescheerstraat 1',
    LATITUDE: 52.259373,
    LONGITUDE: 4.572347,
    PHONE: '',
    EMAIL: 'repaircafehmr@gmail.com',
    WEBSITE: 'http://www.repaircafehaarlemmermeer.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste zaterdag van de maand, van 13.30 tot 16.00 uur. \n\nVoor exacte data zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Haarlemmermeer-Zwanenburg/Halfweg',
    ADDRESS: 'Olmenlaan 141',
    LATITUDE: 52.376781,
    LONGITUDE: 4.748527,
    PHONE: '',
    EMAIL: 'repaircafehmr@gmail.com',
    WEBSITE: 'http://www.repaircafehaarlemmermeer.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede dinsdag van de maand, van 13.30 tot 16.00 uur. Voor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Haarlemmermeer-Badhoevedorp',
    ADDRESS: 'Christian Huygensstraat 21',
    LATITUDE: 52.342003,
    LONGITUDE: 4.781957,
    PHONE: '',
    EMAIL: 'repaircafehmr@gmail.com',
    WEBSITE: 'http://www.repaircafehaarlemmermeer.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke vierde woensdag van de maand, van 9.30 tot 12.00 uur. \n\nVoor exacte data zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Haarlemmermeer - Nieuw-Vennep',
    ADDRESS: 'Oostmoor 52',
    LATITUDE: 52.263718,
    LONGITUDE: 4.618265,
    PHONE: '',
    EMAIL: 'repaircafehmr@gmail.com',
    WEBSITE: 'http://www.repaircafehaarlemmermeer.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste vrijdag van de maand, van 13.30 tot 16.00 uur. \n\nVoor exacte data zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Reeuwijk Brug',
    ADDRESS: 'Van Staverenstraat 39',
    LATITUDE: 52.046604,
    LONGITUDE: 4.719857,
    PHONE: '',
    EMAIL: 'info@duurzaambodegravenreeuwijk.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede en laatste zaterdag van de maand van 10.00 tot 12.00 uur.',
  },
  {
    NAME: 'Repair Café Waarder',
    ADDRESS: 'Prinses Máximalaan 2',
    LATITUDE: 52.061054,
    LONGITUDE: 4.822379,
    PHONE: '',
    EMAIL: 'martin.vanvliet@tele2.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste vrijdag van de maand van 14.00 tot 16.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Zwolle-Aa-landen',
    ADDRESS: 'Dobbe 29',
    LATITUDE: 52.529987,
    LONGITUDE: 6.103098,
    PHONE: '',
    EMAIL: 'repaircafe@wijkgemeenschapaalanden.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde vrijdag van de maand van 12.00 tot 16.00 uur, niet in schoolvakanties. Voor exacte data, zie de Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Postert',
    ADDRESS: 'Nieuw Holsterweg 25',
    LATITUDE: 51.123978,
    LONGITUDE: 6.03418,
    PHONE: '',
    EMAIL: 'joep.thebrath@canaldigitaal.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste zaterdag van de maand, van 14.00 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Zoetermeer-Ambachtenwerkplaats',
    ADDRESS: 'Teldersrode 25',
    LATITUDE: 52.066933,
    LONGITUDE: 4.477846,
    PHONE: '',
    EMAIL: 'femke@stichtingpiezo.nl',
    WEBSITE: 'http://www.sticthtingpiezo.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke dinsdag en woensdag van 09.00 tot 14.00. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Den Haag',
    ADDRESS: 'Scheveningseweg 76',
    LATITUDE: 52.098461,
    LONGITUDE: 4.282565,
    PHONE: '',
    EMAIL: 'repaircafe.denhaag@gmail.com',
    WEBSITE: 'http://www.repaircafedenhaag.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Bunschoten',
    ADDRESS: 'Lorentzweg 10',
    LATITUDE: 52.232365,
    LONGITUDE: 5.361661,
    PHONE: '',
    EMAIL: 'aahuygen@gmail.com',
    WEBSITE: 'http://www.deboeibunschoten.nl/repaircafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste donderdag van de maand, van 13.00 tot 16.00 uur. \n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Diepenveen',
    ADDRESS: 'Randerstraat 31a',
    LATITUDE: 52.302723,
    LONGITUDE: 6.154707,
    PHONE: '',
    EMAIL: 'werkplaats31@hotmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vierde zaterdag van de maand, van 10.00 tot 14.00 uur. \n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Amersfoort-Nieuwland',
    ADDRESS: 'Zeldertse dreef 2',
    LATITUDE: 52.200424,
    LONGITUDE: 5.376897,
    PHONE: '',
    EMAIL: 'janhuurdeman1@gmail.com',
    WEBSITE: 'http://www.wijzijnnieuwland.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vierde zaterdag van de maand, van 10.00 tot 13.00 uur. \n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Uithoorn-Oude Raadhuis',
    ADDRESS: 'Marktplein 2A',
    LATITUDE: 52.232803,
    LONGITUDE: 4.832239,
    PHONE: '',
    EMAIL: 'repaircafeuithoorn@gmail.com',
    WEBSITE: 'https://sites.google.com/site/repairuithoorn/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede woensdag van de maand, van 13.00 tot 16.00 uur. Voor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Luyksgestel',
    ADDRESS: 'Kappellerweg 3',
    LATITUDE: 51.288967,
    LONGITUDE: 5.319606,
    PHONE: '',
    EMAIL: 'repaircafeluyksgestel@gmail.com',
    WEBSITE: 'http://www.deneijkholt.nl/activiteitenprogramma/repair-cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste en derde donderdag van de maand, van 10.00 tot 12.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Woensdrecht',
    ADDRESS: 'Dorpsstraat 41-49',
    LATITUDE: 51.428825,
    LONGITUDE: 4.304611,
    PHONE: '',
    EMAIL: 'ammvz@home.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste en derde vrijdag van de maand, van 14.00 tot 17.00 uur. \n\nVoor exacte data neem contact op met de organisatoren.',
  },
  {
    NAME: 'Repair Café Almere-Waterwijk & Verzetswijk',
    ADDRESS: 'Lekstraat 2',
    LATITUDE: 52.384056,
    LONGITUDE: 5.226265,
    PHONE: '',
    EMAIL: 'a.dboer77@gmail.com',
    WEBSITE: 'http://www.repaircafe-almere.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste dinsdag van de maand, van 9.00 tot 11.00 uur.\n\nVoor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Almere-Kruidenwijk (Kringloper)',
    ADDRESS: 'Zwenkgrasstraat 4',
    LATITUDE: 52.386734,
    LONGITUDE: 5.204366,
    PHONE: '',
    EMAIL: '',
    WEBSITE: 'http://www.repaircafe-almere.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste zaterdag van de maand, van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Almere-EveNaarHuis',
    ADDRESS: 'Sumatraweg 352',
    LATITUDE: 52.401882,
    LONGITUDE: 5.298352,
    PHONE: '',
    EMAIL: 'joycestoit@hotmail.com',
    WEBSITE: 'http://www.repaircafe-almere.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede dinsdag van de maand, van 19.00 tot 21.30 uur. Voor exacte data zie de website hieronder.\n\n\n\n&nbsp;\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Almere-Regenboogbuurt',
    ADDRESS: 'Regenboogweg 45',
    LATITUDE: 52.394909,
    LONGITUDE: 5.294045,
    PHONE: '',
    EMAIL: 'repaircafe@regenboogbuurt.nl',
    WEBSITE: 'http://www.repaircafe-almere.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde dinsdag van de maand, van 19.00 tot 21.30 uur.\n\nVoor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Bergeijk',
    ADDRESS: 'Dr. Rauppstraat 52',
    LATITUDE: 51.317062,
    LONGITUDE: 5.361302,
    PHONE: '',
    EMAIL: 'j.c.janssen@hetnet.nl',
    WEBSITE: 'http://www.aquinohuis.nl/je-van-dienst/repair-cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vrijdag, van 9.00 tot 12.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Almere-Tussen de Vaarten',
    ADDRESS: 'Hildo Kropstraat 8',
    LATITUDE: 52.374882,
    LONGITUDE: 5.258227,
    PHONE: '',
    EMAIL: 'guido-ine@live.nl',
    WEBSITE: 'http://www.repaircafe-almere.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde woensdag van de maand, van 19.30 tot 21.30 uur.\n\nVoor exacte data zie de website en Facebookpagina hieronder.\n\n\n\nTweede e-mailadres: hans@ehd.nu (Hans Wessels).',
  },
  {
    NAME: 'Repair Café Almere-Stedenwijk',
    ADDRESS: "\\'s-Hertogenboschplein 8",
    LATITUDE: 52.359524,
    LONGITUDE: 5.204877,
    PHONE: '',
    EMAIL: 'jhomans@deschoor.nl',
    WEBSITE: 'http://www.repaircafe-almere.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      '<strong>Openingstijden\n\n</strong>Elke derde zaterdag van de maand, van 14.00 tot 17.00 uur.\n\nVoor exacte data zie de website en Facebookpagina hieronder.\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Almere-Filmwijk',
    ADDRESS: 'Walt Disneyplantsoen 76-78',
    LATITUDE: 52.359493,
    LONGITUDE: 5.24229,
    PHONE: '',
    EMAIL: '',
    WEBSITE: 'http://www.repaircafe-almere.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste donderdag van de maand, van 9.00 tot 11.30 uur.\n\nVoor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Almere-Haven',
    ADDRESS: 'Kerkgracht 41',
    LATITUDE: 52.336094,
    LONGITUDE: 5.219825,
    PHONE: '',
    EMAIL: 'lenywesthoven@gmail.com',
    WEBSITE: 'http://www.repaircafe-almere.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste vrijdag van de maand, van 9.30 tot 12.00 uur. Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Harlingen',
    ADDRESS: 'Prinses Irenestraat 2',
    LATITUDE: 53.168816,
    LONGITUDE: 5.425524,
    PHONE: '',
    EMAIL: 'p.veldhuizen@deskule.nl',
    WEBSITE: 'https://nieuwzuid.frl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke donderdag, van 10.00 tot 11.45 uur. Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Rosmalen-HEVO',
    ADDRESS: 'Mimosastraat 1',
    LATITUDE: 51.71801,
    LONGITUDE: 5.353882,
    PHONE: '',
    EMAIL: 'hevorepaircafe@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste en derde woensdag van de maand, van 9.30 tot 12.30 uur. \n\nVoor exacte data neem contact op met de organisator.',
  },
  {
    NAME: 'Repair Café Roermond',
    ADDRESS: 'Dr. Philipslaan 49b',
    LATITUDE: 51.199802,
    LONGITUDE: 6.001275,
    PHONE: '',
    EMAIL: 'roermond@hetgoed.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Dit Repair Café is tijdelijk gesloten. ',
  },
  {
    NAME: 'Repair Café Musselkanaal',
    ADDRESS: 'W. Diemerkanaal 7',
    LATITUDE: 52.926872,
    LONGITUDE: 7.018,
    PHONE: '',
    EMAIL: 'c.bal@welstad.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag van de maand, van 13.00 tot 16.00 uur. Voor exacte data neem contact op met de organisator.\n\n\n\n&nbsp;\n\n\n\n&nbsp;',
  },
  {
    NAME: 'Repair Café Zelhem',
    ADDRESS: 'Meeneweg 4',
    LATITUDE: 52.010406,
    LONGITUDE: 6.362097,
    PHONE: '',
    EMAIL: 'deboerderijzelhem@gmail.com',
    WEBSITE: 'http://rc.deboerderijzelhem.nl/index.php',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste donderdag van de maand, van 14.00 tot 17.00 uur. Voor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Peel en Maas - Helden',
    ADDRESS: 'Aan de Koeberg 3',
    LATITUDE: 51.319996,
    LONGITUDE: 6.003383,
    PHONE: '',
    EMAIL: 'rc.peelenmaas@gmail.com',
    WEBSITE: 'https://repaircafe.org/locations/repair-cafe-peel-en-maas/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Let op! Repair Café Peel en Maas wordt behalve in Helden ook gehouden in <a href="https://repaircafe.org/locations/repair-cafe-peel-en-maas/">Maasbree</a>.\n\n\n\nElke eerste zaterdag van de maand, van 10.00 tot 12.30 uur. \n\nVoor exacte data zie Facebookpagina.',
  },
  {
    NAME: 'Repair Café Wijhe',
    ADDRESS: 'Langstraat 1',
    LATITUDE: 52.389435,
    LONGITUDE: 6.135495,
    PHONE: '',
    EMAIL: 'gamnijboer@hetnet.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke woensdag van 10.00 tot 12.00 uur. Voor exacte data, zie de Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Hoogland',
    ADDRESS: 'Engweg 7',
    LATITUDE: 52.188114,
    LONGITUDE: 5.383196,
    PHONE: '',
    EMAIL: 'wouter.tamboer@dorpshuisdeneng.nl',
    WEBSITE: 'https://www.dorpshuisdeneng.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand, van 10.00 tot 13.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Hoogezand-Sappemeer-Oost',
    ADDRESS: 'Rembrandtlaan 72a',
    LATITUDE: 53.165863,
    LONGITUDE: 6.768819,
    PHONE: '',
    EMAIL: 'eastreuper@outlook.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke vierde woensdag van de maand, van 9.30 tot 12.00 uur, behalve in de vakanties. Neem contact op met de organisator voor meer informatie.',
  },
  {
    NAME: 'Repair Café Bergschenhoek',
    ADDRESS: 'Boterdorpseweg',
    LATITUDE: 51.985916,
    LONGITUDE: 4.486626,
    PHONE: '',
    EMAIL: '',
    WEBSITE: 'http://www.pand58.life',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Nog niet bekend. Volg de informatie op de website hieronder, of neem contact op met de organisatoren.\n\n',
  },
  {
    NAME: 'Repair Café Sint Annaparochie',
    ADDRESS: 'Middelweg west 176',
    LATITUDE: 53.275398,
    LONGITUDE: 5.640657,
    PHONE: '',
    EMAIL: 'mirjamalosery@coa.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede vrijdag van de maand, van 10.00 tot 12.00 uur. Neem eventueel contact op met de organisatoren voor exacte data.',
  },
  {
    NAME: 'Repair Café Varik-Heesselt',
    ADDRESS: 'Walgtsestraat 5',
    LATITUDE: 51.822529,
    LONGITUDE: 5.368027,
    PHONE: '',
    EMAIL: 'repaircafe.vh@gmail.com',
    WEBSITE: 'https://www.toevershof.nl/lid/dorpstafel/repair-cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede maandag van de maand, van 14.00 tot 16.00 uur.\n\nVoor exacte data zie de website en Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Nieuwkoop',
    ADDRESS: 'De Verbinding 8',
    LATITUDE: 52.150719,
    LONGITUDE: 4.775401,
    PHONE: '',
    EMAIL: 'corrie.korbee@gildenieuwkoop.nl',
    WEBSITE: 'https://www.gildenieuwkoop.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde maandag van de maand van 14.00 tot 16.00 uur. Voor exacte data, zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Etten-Leur',
    ADDRESS: 'Trompetlaan 42',
    LATITUDE: 51.562237,
    LONGITUDE: 4.633373,
    PHONE: '',
    EMAIL: 'gdijkstra60@planet.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste zaterdag van de maand.\n\nVoor exacte data zie de (besloten) Facebookgroep, of neem contact op met de organisator.',
  },
  {
    NAME: 'Repair Café Krimpen aan den IJssel - De Kajuit',
    ADDRESS: 'Binnenvaart 3',
    LATITUDE: 51.914352,
    LONGITUDE: 4.596233,
    PHONE: '',
    EMAIL: '',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde donderdag van de maand van 13.00 tot 16.00 uur. Voor exacte data, zie de Facebook-pagina hieronder.',
  },
  {
    NAME: 'Repair Café Peel en Maas - Baarlo',
    ADDRESS: 'Tiendvrij 18',
    LATITUDE: 51.33242,
    LONGITUDE: 6.098818,
    PHONE: '',
    EMAIL: 'm.ribbens@debibliotheekmaasenpeel.nl',
    WEBSITE: 'https://www.debibliotheekmaasenpeel.nl/actueel/agenda/repair-cafe-baarlo.html',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke vierde zaterdag van de maand, van 10.00 tot 13.00 uur.\n\nVoor exacte data zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Stein-Nieuwdorp',
    ADDRESS: 'Bergènkenstraat 1',
    LATITUDE: 50.962635,
    LONGITUDE: 5.77278,
    PHONE: '',
    EMAIL: 'stichtingbuurtcentrumnieuwdorp@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 10.00 tot 12.00 uur.',
  },
  {
    NAME: 'Repair Café Langedijk - Winkelcentrum Broekerveiling',
    ADDRESS: 'Marktplein 35',
    LATITUDE: 52.679256,
    LONGITUDE: 4.803487,
    PHONE: '',
    EMAIL: '',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      '<em>Elke tweede woensdag van de maand, van 14.00 tot 16.00 uur.</em>\n\nBehalve in juni, juli en augustus en tussen kerst en oud en nieuw.',
  },
  {
    NAME: 'Repair Café Ruinen',
    ADDRESS: 'Jan Wierengaweg 109',
    LATITUDE: 52.761387,
    LONGITUDE: 6.361902,
    PHONE: '',
    EMAIL: 'info@dorpshuisruinen.nl',
    WEBSITE: 'http://dorpshuisruinen.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste maandag van de maand, van 13.00 tot 16.00 uur. Voor exacte data zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Den Bosch Zuid',
    ADDRESS: 'Zuiderparkweg 496',
    LATITUDE: 51.683334,
    LONGITUDE: 5.324316,
    PHONE: '',
    EMAIL: 'repaircafezuid@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste zaterdag van de maand van 10.00 tot 14.00 uur een volledig Repair Café, behalve in de maand augustus. \n\nIedere vrijdag van 10.00 tot 13.00 Repair Café extra, speciaal voor fietsreparaties.\n\nVoor exacte data neem contact op met de organisator.',
  },
  {
    NAME: 'Repair Cafe Den Bosch Noorderpoort - Samen doen',
    ADDRESS: 'Het Wielsem 21',
    LATITUDE: 51.7159,
    LONGITUDE: 5.301964,
    PHONE: '',
    EMAIL: 'info@noorderpoort-samendoen.nl',
    WEBSITE: 'https://www.noorderpoort-samendoen.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag van de maand van 13.00 tot 18.00 uur. \n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Amsterdam-Oud West (Jeltje)',
    ADDRESS: 'Eerste Helmersstraat 106N',
    LATITUDE: 52.362141,
    LONGITUDE: 4.868578,
    PHONE: '',
    EMAIL: 'johan@repaircafejeltje.nl',
    WEBSITE: 'https://repaircafejeltje.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste vrijdagavond van de maand, tussen 19.00 en 22.00 uur',
  },
  {
    NAME: 'Repair Café Velserbroek',
    ADDRESS: 'Aletta Jacobsstraat 227',
    LATITUDE: 52.433872,
    LONGITUDE: 4.662102,
    PHONE: '',
    EMAIL: 'm.flieringa@zorgbalans.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste vrijdag van de maand, van 14.30 tot 16.30 uur.',
  },
  {
    NAME: 'Repair Café Groningen-Helpman-De Wijert',
    ADDRESS: 'Helper Westsingel 31',
    LATITUDE: 53.200111,
    LONGITUDE: 6.575571,
    PHONE: '',
    EMAIL: 'g.vanderweerd@lentis.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand open, van 10.00 tot 13.00 uur.',
  },
  {
    NAME: 'Repair Café Franeker',
    ADDRESS: 'Turfkade 14',
    LATITUDE: 53.18544,
    LONGITUDE: 5.547762,
    PHONE: '',
    EMAIL: 'p.veldhuizen@deskule.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke donderdag van 13.00 tot 15.00 uur.',
  },
  {
    NAME: 'Repair Café Blaricum-Centrum',
    ADDRESS: 'Schoolstraat 3',
    LATITUDE: 52.27243,
    LONGITUDE: 5.243708,
    PHONE: '',
    EMAIL: 'repaircafe.blaricum@gmail.com',
    WEBSITE: 'http://www.repaircafesbel.nl/blaricum/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de oneven maanden zaterdag van de maand, van 10.00 tot 13.00 uur. Voor exacte data zie de website.\n\n\n\nEr is ook een Repair Café in de wijk <a href="https://repaircafe.org/locatie/repair-cafe-blaricum-bijvanck/">Bijvanck</a>.',
  },
  {
    NAME: 'Repair Café Drachten',
    ADDRESS: 'Vogelweide 5',
    LATITUDE: 53.113567,
    LONGITUDE: 6.104872,
    PHONE: '',
    EMAIL: 'meitsje@repaircafedrachten.nl',
    WEBSITE: 'https://www.repaircafedrachten.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Voor openingstijden zie de website hieronder. Repair Café Drachten houdt ook bijeenkomsten in de bibliotheek in Drachten. ',
  },
  {
    NAME: 'Repair Café Heerde',
    ADDRESS: 'Van Meurlaan 3',
    LATITUDE: 52.388275,
    LONGITUDE: 6.046426,
    PHONE: '',
    EMAIL: 'reobein@gmail.com',
    WEBSITE: 'https://www.vhdheerde.nl/projecten/repair-cafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde woensdag van de maand, van 14.00 tot 16.00 uur. In juli en augustus is dit Repair Café gesloten.',
  },
  {
    NAME: 'Repair Café America',
    ADDRESS: 'Pastoor Jeukenstraat 6',
    LATITUDE: 51.436348,
    LONGITUDE: 5.978487,
    PHONE: '',
    EMAIL: 'info@repaircafeamerica.nl',
    WEBSITE: 'http://www.repaircafeamerica.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand, van 10.00 tot 12.00 uur.',
  },
  {
    NAME: 'RepairCafé',
    ADDRESS: 'Weteringschans 106',
    LATITUDE: 0,
    LONGITUDE: 0,
    PHONE: '',
    EMAIL: '',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Die konkreten Termine findest Du auf der <strong>Website</strong> dieses Repair Cafés:\n\n\n\n<a href="https://www.facebook.com/repaircafesproetze">Repair Café Sprötze</a>\n\n\n\n<strong>Kontakt mit den Organisatoren:</strong>\n\n\n\nEmail:repaircafesproetze [at] gmx.de (Evelien Nissen)\n\n\n\n&nbsp;',
  },
  {
    NAME: 'RepairCafé',
    ADDRESS: 'Weteringschans 106',
    LATITUDE: 52.359203,
    LONGITUDE: 4.895535,
    PHONE: '',
    EMAIL: '',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: '',
  },
  {
    NAME: 'Repair Café Rotterdam-Prins Alexander-Zevenkamp',
    ADDRESS: 'Kooikerweg 10',
    LATITUDE: 51.965988,
    LONGITUDE: 4.570812,
    PHONE: '',
    EMAIL: 'm.ginneken@live.nl',
    WEBSITE: 'http://makersendoeners.ning.com/page/repaircafe-s',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke woensdag van 13.30 tot 15.30 uur. De repareerbijeenkomsten worden soms op Kooikerweg 14 gehouden, in plaats van Kooikerweg 10.\n\n\n\nEr is ook een Repair Café in <a href="https://repaircafe.org/locatie/repair-cafe-rotterdam-prins-alexander-lage-land/">Rotterdam-Prins Alexander - Lage Land</a>.',
  },
  {
    NAME: 'Repaircafé Van Ons',
    ADDRESS: 'Weteringschans 106',
    LATITUDE: 52.359203,
    LONGITUDE: 4.895535,
    PHONE: '',
    EMAIL: 'donny+repaircafelive@van-ons.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: '',
  },
  {
    NAME: 'Repair Café Lingewaard-Huissen',
    ADDRESS: 'Langekerkstraat 15',
    LATITUDE: 51.93655,
    LONGITUDE: 5.941132,
    PHONE: '',
    EMAIL: 'w.vandenhurk@swlingewaard.nl',
    WEBSITE: 'http://www.swlingewaard.nl/sub.php?page=599',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 14.00 tot 16.00 uur. Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Lingewaard-Bemmel',
    ADDRESS: 'Cuperstraat 9',
    LATITUDE: 51.890556,
    LONGITUDE: 5.894167,
    PHONE: '',
    EMAIL: 'w.vandenhurk@swlingewaard.nl',
    WEBSITE: 'http://www.swlingewaard.nl/sub.php?page=639',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand, van 14.00 tot 16.00 uur. Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Lingewaard-Gendt',
    ADDRESS: 'Kloosterplein 5',
    LATITUDE: 51.873993,
    LONGITUDE: 5.965543,
    PHONE: '',
    EMAIL: 'w.vandenhurk@swlingewaard.nl',
    WEBSITE: 'http://www.swlingewaard.nl/sub.php?page=537',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste woensdag van de maand, van 10.00 tot 12.30 uur. Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Lingewaard-Haalderen',
    ADDRESS: 'Kolkweg 5a',
    LATITUDE: 51.885162,
    LONGITUDE: 5.930302,
    PHONE: '',
    EMAIL: 'w.vandenhurk@swlingewaard.nl',
    WEBSITE: 'http://www.swlingewaard.nl/sub.php?page=641',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste woensdag van de maand, van 19.30 tot 21.30 uur. Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Lingewaard-Doornenburg',
    ADDRESS: 'Kerkstraat 3',
    LATITUDE: 51.890652,
    LONGITUDE: 6.000891,
    PHONE: '',
    EMAIL: 'w.vandenhurk@swlingewaard.nl',
    WEBSITE: 'http://www.swlingewaard.nl/sub.php?page=646',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Om de vier weken op vrijdag, van 10.00 tot 12.00 uur. Voor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Lingewaard-Angeren',
    ADDRESS: 'Emmastraat 23',
    LATITUDE: 51.915375,
    LONGITUDE: 5.955713,
    PHONE: '',
    EMAIL: 'w.vandenhurk@swlingewaard.nl',
    WEBSITE: 'http://www.swlingewaard.nl/sub.php?page=643',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Voor openingstijden zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Elshout',
    ADDRESS: 'Th. J. Rijkenstraat 10A',
    LATITUDE: 51.699696,
    LONGITUDE: 5.140469,
    PHONE: '',
    EMAIL: 'voorzitter@denelshof.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede dinsdag van de oneven maanden, van 19.00 tot 21.00 uur.',
  },
  {
    NAME: 'Repair Café Waddinxveen',
    ADDRESS: 'Kerkweg-Oost 159',
    LATITUDE: 52.044296,
    LONGITUDE: 4.655012,
    PHONE: '',
    EMAIL: 'info@repaircafewaddinxveen.nl',
    WEBSITE: 'https://www.repaircafewaddinxveen.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand van 9.00 tot 13.00 uur.',
  },
  {
    NAME: 'Repair Café Vogelenzang',
    ADDRESS: 'Henk Iensenlaan 2a',
    LATITUDE: 52.317642,
    LONGITUDE: 4.572217,
    PHONE: '',
    EMAIL: 'repaircafevogelenzang@yahoo.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Informeer naar de openingstijden via het hierondervermelde e-mailadres.',
  },
  {
    NAME: 'Repair Café Amsterdam-West Bos en Lommer',
    ADDRESS: 'Wachterliedplantsoen 1-3',
    LATITUDE: 52.377926,
    LONGITUDE: 4.853975,
    PHONE: '',
    EMAIL: 'info@wachterliedpaviljoen.nl',
    WEBSITE: 'https://wachterliedpaviljoen.nl/event/repair-cafe/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke zaterdag, van 10.30 tot 13.30 uur.',
  },
  {
    NAME: 'Repair Café Veendam',
    ADDRESS: 'Jakob Bruggemalaan 72a',
    LATITUDE: 53.110371,
    LONGITUDE: 6.879894,
    PHONE: '',
    EMAIL: 'g.lemain@cosis.nu',
    WEBSITE: 'https://www.debuurtschuurveendam.nl/repaircafe',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste donderdag van de maand, van 13.00 tot 15.30 uur. \n\nVoor exacte data zie de website en Facebookpagina.',
  },
  {
    NAME: 'Repair Café Kids Dordrecht',
    ADDRESS: 'Dudok-erf 58',
    LATITUDE: 51.802437,
    LONGITUDE: 4.718968,
    PHONE: '',
    EMAIL: 'repaircafekidsdordrecht@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Eén zaterdagochtend per maand van 10.00 tot 13.00 uur. Start: 19 januari 2019. Voor exacte data, zie de Facebookpagina hieronder.',
  },
  {
    NAME: 'Repair Café Alkmaar-Oud Overdie',
    ADDRESS: 'Bestevaerstraat 2',
    LATITUDE: 52.623714,
    LONGITUDE: 4.764246,
    PHONE: '',
    EMAIL: 'info@nmealkmaar.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke dinsdag, woensdag en donderdag, van 10.00 tot 15.00 uur.',
  },
  {
    NAME: 'Repair Café Overbetuwe-Randwijk',
    ADDRESS: 'Bredeweg 8A',
    LATITUDE: 51.950184,
    LONGITUDE: 5.71175,
    PHONE: '',
    EMAIL: '',
    WEBSITE: 'https://www.fortewelzijn.nl/home-en-actueel/overbetuwe/repair-café/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste vrijdag van de maand, van 19.00 tot 21.00 uur.',
  },
  {
    NAME: 'Repair Café Lopikerwaard',
    ADDRESS: 'Wagenmaker 1',
    LATITUDE: 51.97147,
    LONGITUDE: 4.937755,
    PHONE: '',
    EMAIL: 'repaircafe@duurzaamlopikerwaard.nl',
    WEBSITE: 'https://duurzaamlopikerwaard.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste donderdag van de maand, van 14.00 tot 17.00 uur.',
  },
  {
    NAME: 'Repair Café Santpoort-Zuid',
    ADDRESS: 'Bloemendaalsestraatweg 201',
    LATITUDE: 52.418633,
    LONGITUDE: 4.629262,
    PHONE: '',
    EMAIL: 'senioractiviteiten@santpoortsbelang.nl',
    WEBSITE:
      'https://www.santpoortsbelang.nl/detail_cursus2.phtml?cursusnaam=Repaircaf%E9&act_id=2762870&username=vua_guest&password=9999&publish=Y&username=vua_guest&password=9999&groups=SANTPOORTSBELANG&text05=&categorie=&activiteit=Overige&subcategorie=&publish=',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede zaterdag van de maand, van 13.30 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Ter Aar',
    ADDRESS: 'Beukenpad 1',
    LATITUDE: 52.168022,
    LONGITUDE: 4.711097,
    PHONE: '',
    EMAIL: 'corrie.korbee@gildenieuwkoop.nl',
    WEBSITE: 'https://www.gildenieuwkoop.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vierde maandag van de maand van 14.00 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Nijmegen-Brakkenstein',
    ADDRESS: 'Deken Hensburchstraat 2',
    LATITUDE: 51.813343,
    LONGITUDE: 5.861855,
    PHONE: '',
    EMAIL: '',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Worden nog gepubliceerd, evenals contactgegevens.',
  },
  {
    NAME: 'Repair Café Munstergeleen',
    ADDRESS: 'Pancratiusstraat 23',
    LATITUDE: 50.973179,
    LONGITUDE: 5.864327,
    PHONE: '',
    EMAIL: 'mwehrens@piw.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vierde zaterdag van de maand, van 10.00 tot 12.30 uur.',
  },
  {
    NAME: 'Repair Café Scherpenzeel-Renswoude',
    ADDRESS: 'Marktstraat 59',
    LATITUDE: 52.084827,
    LONGITUDE: 5.490156,
    PHONE: '',
    EMAIL: 'repaircafe@swo-sr.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste vrijdag van de maand, van 14.00 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Beemster-Zuidoostbeemster',
    ADDRESS: 'Middenpad 2',
    LATITUDE: 52.517578,
    LONGITUDE: 4.940772,
    PHONE: '',
    EMAIL: 'm.spliet@rswp.eu',
    WEBSITE: 'http://www.buurthuiszuidoostbeemster.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde dinsdag van de oneven maanden, van 9.30 tot 11.30 uur. Niet in juli.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Enschede-Helmerhoek',
    ADDRESS: 'Runenberghoek 12',
    LATITUDE: 52.193905,
    LONGITUDE: 6.854274,
    PHONE: '',
    EMAIL: 'eljee62@hotmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vrijdag, van 10.00 tot 12.00 uur.',
  },
  {
    NAME: 'Repair Café Belfeld',
    ADDRESS: 'Stevensplein 2',
    LATITUDE: 51.310699,
    LONGITUDE: 6.11409,
    PHONE: '',
    EMAIL: 'drefranssen@kpnplanet.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke derde zaterdag van de maand, van 10.00 tot 13.00 uur.',
  },
  {
    NAME: 'Repair Café Heesch',
    ADDRESS: 'Kerkstraat 2',
    LATITUDE: 51.733055,
    LONGITUDE: 5.52051,
    PHONE: '',
    EMAIL: 'ronverduin@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste woensdag van de maand, van 13.00 tot 16.00 uur.\n\nVoor exacte data zie de Facebookpagina.',
  },
  {
    NAME: 'Repair Café Breda-Haagse Beemden',
    ADDRESS: 'Heksenwiellaan 2',
    LATITUDE: 51.623951,
    LONGITUDE: 4.740672,
    PHONE: '',
    EMAIL: 'hb.repaircafe@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste zaterdag van de maand, van 12.00 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Breda-Princenhage',
    ADDRESS: 'Doelen 36',
    LATITUDE: 51.575279,
    LONGITUDE: 4.737889,
    PHONE: '',
    EMAIL: 'henk.oomen1948@hotmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke vierde zaterdag van de maand, van 12.00 tot 16.00 uur.',
  },
  {
    NAME: 'Fietsen Repair Café Zeist',
    ADDRESS: 'Joh. Postlaan 4',
    LATITUDE: 52.101566,
    LONGITUDE: 5.25237,
    PHONE: '',
    EMAIL: 'mkuipers@kringloopzeist.nl',
    WEBSITE: 'https://kringloopzeist.nl',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke laatste donderdag van de maand.',
  },
  {
    NAME: 'Repair Café Rotterdam-Delfshaven',
    ADDRESS: '1e IJzerstraat 63',
    LATITUDE: 51.906551,
    LONGITUDE: 4.455238,
    PHONE: '',
    EMAIL: 'vdmeyer@wmoradar.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke donderdagmiddag. Neem contact op met de organisator voor de exacte tijden.',
  },
  {
    NAME: 'Repair Café Amersfoort-Liendert-Rustenburg',
    ADDRESS: 'Wiekslag 50',
    LATITUDE: 52.161884,
    LONGITUDE: 5.409959,
    PHONE: '',
    EMAIL: 'p.liklikuwata@ziggo.nl',
    WEBSITE: 'http://www.repaircafeliendert.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste vrijdag van de maand, van 10.30 tot 14.00 uur.\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Wageningen-Noordwest',
    ADDRESS: 'Hooilandplein 47',
    LATITUDE: 51.981915,
    LONGITUDE: 5.648442,
    PHONE: '',
    EMAIL: 'repaircafe@wageningennoordwest.nl',
    WEBSITE: 'http://www.repaircafewageningen.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke laatste donderdag van de maand, van 19.00 tot 21.30 uur.\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Amstelveen-Patrimonium',
    ADDRESS: 'Smeenklaan 1',
    LATITUDE: 52.308109,
    LONGITUDE: 4.845379,
    PHONE: '',
    EMAIL: 'groenpa@xs4all.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Eerste dinsdag van de maand, van 14.00 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Amerongen',
    ADDRESS: 'Allemanswaard 13',
    LATITUDE: 52.00341,
    LONGITUDE: 5.462146,
    PHONE: '',
    EMAIL: 'hfruijs@wxs.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede woensdag van de maand, van 13.30 tot 16.30 uur.',
  },
  {
    NAME: 'Repair Café Zuidplas (Nieuwerkerk aan den IJssel)',
    ADDRESS: 'Kerklaan 98',
    LATITUDE: 51.970062,
    LONGITUDE: 4.606319,
    PHONE: '',
    EMAIL: 'peterrommens@online.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke dinsdag van 13.30 tot 16.30 uur.',
  },
  {
    NAME: 'Repair Café Haarlemmermeer-Spaarndam',
    ADDRESS: 'Ringweg 36',
    LATITUDE: 52.413452,
    LONGITUDE: 4.684087,
    PHONE: '',
    EMAIL: 'repaircafehmr@gmail.com',
    WEBSITE: 'http://www.repaircafehaarlemmermeer.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede zaterdag van de maand, van 13.30 tot 16.00 uur (inloop tot 15.30 uur).\n\nVoor exacte data zie de website.',
  },
  {
    NAME: 'Repair Café Haarlemmermeer-Vijfhuizen',
    ADDRESS: 'Kromme Spieringwerf 436',
    LATITUDE: 52.352428,
    LONGITUDE: 4.682585,
    PHONE: '',
    EMAIL: 'repaircafehmr@gmail.com',
    WEBSITE: 'http://www.repaircafehaarlemmermeer.nl/',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde maandagmiddag van de maand, van 13.30 uur tot 16.00 uur (inloop tot 15.30 uur).\n\nVoor exacte data zie de website hieronder.',
  },
  {
    NAME: 'Repair Café Ermelo-Zuid',
    ADDRESS: 'Herderlaan 12',
    LATITUDE: 52.291145,
    LONGITUDE: 5.609863,
    PHONE: '',
    EMAIL: 'djv5338@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke tweede en vierde dinsdag van de maand, van 9.30 tot 12.00 uur. In de maanden juli en augustus gesloten.',
  },
  {
    NAME: 'Repair Café Westland-Hoek van Holland',
    ADDRESS: 'Mercatorweg 50',
    LATITUDE: 51.977173,
    LONGITUDE: 4.137753,
    PHONE: '',
    EMAIL: 'rcwestland@outlook.com',
    WEBSITE:
      'http://www.vitiswelzijn.nl/site/nl/wijkcentra-en-activiteiten/activiteiten/overzicht-activiteiten/repair-cafe/nl-57-2500',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke derde donderdag van de maand van 13.30 tot 16.00 uur. Voor exacte data, zie de website hieronder.\n\n\n\nRepair Café Westland heeft nog twee andere locaties:\n\n<a href="https://repaircafe.org/locatie/repair-cafe-westland-monster/">Repair Café Westland-Monster</a>\n\n<a href="https://repaircafe.org/locatie/repair-cafe-westland-poeldijk-1/">Repair Café Westland-Poeldijk</a>',
  },
  {
    NAME: 'Repair Café Westland-Poeldijk',
    ADDRESS: 'Wittebrug 2',
    LATITUDE: 52.021709,
    LONGITUDE: 4.221588,
    PHONE: '',
    EMAIL: 'rcwestland@outlook.com',
    WEBSITE:
      'http://www.vitiswelzijn.nl/site/nl/wijkcentra-en-activiteiten/activiteiten/overzicht-activiteiten/repair-cafe/nl-57-2500',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Elke eerste dinsdag van de maand van 13.30 tot 16.00 uur (aanname tot 15.30 uur). Voor exacte data, zie de website hieronder.\n\n\n\nRepair Café Westland heeft nog twee andere locaties:\n\n<a href="https://repaircafe.org/locatie/repair-cafe-westland-hoek-van-holland/">Repair Café Westland-Hoek van Holland</a>\n\n<a href="https://repaircafe.org/locatie/repair-cafe-westland-monster/">Repair Café Westland-Monster</a>',
  },
  {
    NAME: 'Repair Café Zwolle-Kamperpoort',
    ADDRESS: 'Burgemeester Vos de Waelstraat 1',
    LATITUDE: 52.511608,
    LONGITUDE: 6.083496,
    PHONE: '',
    EMAIL: 'm.leemhuis@ijsselheem.nl',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke tweede vrijdag van de maand, van 13.00 tot 16.00 uur.',
  },
  {
    NAME: 'Repair Café Helmond-Stiphout/Warande',
    ADDRESS: 'Dorpsstraat 29',
    LATITUDE: 51.486542,
    LONGITUDE: 5.618826,
    PHONE: '',
    EMAIL: 'vogelsverwegen@gmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Elke eerste donderdag van de maand, van 14.30 tot 17.00 uur.',
  },
  {
    NAME: 'Repair Café Zevenbergen',
    ADDRESS: 'Kristallaan 25C',
    LATITUDE: 51.640232,
    LONGITUDE: 4.594716,
    PHONE: '',
    EMAIL: 'hansassmann@hotmail.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'In de even weken, op dinsdag, van 10.00 tot 14.00 uur.',
  },
  {
    NAME: 'Repair Café Stede Broec - Philadelphia Lamoraal',
    ADDRESS: 'Industrieweg 41',
    LATITUDE: 52.693329,
    LONGITUDE: 5.231808,
    PHONE: '',
    EMAIL: 'dave.vanderlee@icloud.com',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING:
      'Dit Repair Café start zaterdag 14 september 2019, 10.00 - 12.00 uur. Informatie over daaropvolgende repareerbijeenkomsten wordt nog gepubliceerd.',
  },
  {
    NAME: 'Repair Café Koffie Enzo - Nieuwerkerk a/d IJssel',
    ADDRESS: 'Kerklaan 98 (Oude Dorp)',
    LATITUDE: 51.9700614,
    LONGITUDE: 4.6041305,
    PHONE: '',
    EMAIL: '',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Iedere dinsdag 13:00-16:00',
  },
  {
    NAME: 'Repair Café de Kroon - Moordrecht',
    ADDRESS: 'Land voor Water 3',
    LATITUDE: 51.9894219,
    LONGITUDE: 4.6647223,
    PHONE: '',
    EMAIL: '',
    WEBSITE: '',
    TWITTER: '',
    FACEBOOK: '',
    WARNING: 'Iedere 2e en 4e maandag van de maand 13:00-16:00',
  },
];
