import styled from 'styled-components';

import fonts from '../../style/fonts';
import colors from '../../style/colors';
import mq from '../../style/mq';
import { Link } from 'react-router-dom';

export const CardWrapper = styled.div`
  width: ${props => (props.positionFixed ? 'calc(100% - 40px)' : '100%')};
  max-width: ${props => (props.positionFixed ? 'calc(100% - 40px)' : '100%')};
  display: flex;
  box-sizing: border-box;
  height: auto;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  font-family: ${fonts.RobotoBlack};
  font-size: 2em;
  padding: 20px;
  border-radius: 20px;
  background-color: ${colors.white};
  color: ${colors.black};
  z-index: 2;
  box-shadow: 0 12px 40px -10px rgba(0, 0, 0, 0.2);
  position: ${props => (props.positionFixed ? 'fixed' : 'static')};
  bottom: ${props => (props.positionFixed ? '20px' : '0')};
  right: ${props => (props.positionFixed ? '20px' : '0')};
  left: ${props => (props.positionFixed ? '20px' : '0')};
  border: 0;
  outline: none;
  cursor: pointer;
  margin: 5px;

  svg {
    width: 80px;
    height: 80px;
  }

  ${mq.medium`
    font-size: 3em;
    padding: 0 40px;
    padding: 30px;
    margin: 0;
    width: ${props => (props.positionFixed ? 'calc(100% - 80px)' : '100%')};
    max-width: ${props => (props.positionFixed ? 'calc(100% - 80px)' : '75%')};
    flex-direction: row;
    bottom: ${props => (props.positionFixed ? '40px' : '0')};
    right: ${props => (props.positionFixed ? '40px' : '0')};
    left: ${props => (props.positionFixed ? '40px' : '0')};
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  ${mq.medium`
    margin-bottom: 0;
  `}
`;

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 25px;

  ${mq.medium`
    margin-left: 50px;
  `}
`;

export const Title = styled.span`
  display: block;
  font-family: ${fonts.RobotoBlack};
  font-size: 1em;
  color: ${colors.black};
`;

export const SubTitle = styled(Title)`
  font-family: ${fonts.RobotoBold};
  opacity: 0.4;
`;

export const SmallButton = styled.div`
  display: block;
  padding: 20px;
  border-radius: 20px;
  font-family: ${fonts.RobotoBold};
  font-size: 1em;
  color: ${colors.white};
  box-shadow: 0 24px 80px -20px rgba(0, 0, 0, 0.2);
  background: ${colors.black};
  width: 90%;
  text-align: center;

  ${mq.medium`
    width: 100%;
  `}
`;

export const LinkElement = styled(Link)`
  display: block;
  width: 100%;
`;
