import React, { useContext } from 'react';
import ReactPlayer from 'react-player';

import { GlobalStore } from '../../stores/GlobalStore';
import { RepairStore } from '../../stores/RepairStore';

import Word from '../../components/Word';
import Button from '../../components/Button';
import Options from '../../components/Options';
import Giphy from '../../components/Giphy';

import { Wrapper, StartStoryWrapper } from './styles';

import { getDayOfWeek } from '../../helpers/getDayOfWeek';

const Story = () => {
  const {
    audio,
    story,
    player,
    currentAudio,
    audioPlaying,
    audioEnded,
    handleEndStory,
    handleStartStory,
    handleSkipStory,
    handleAudioLoaded,
    handleAudioProgress,
    goBackToStory,
    goToNextStory,
    storySkipping,
    showOptions,
    startStory,
    productSupplied,
    showGiphy,
  } = useContext(RepairStore);
  const { soundMuted, toggleShowMenu, cookies } = useContext(GlobalStore);

  const handleOnStart = () => {
    const start = [0, 1, 2, 3, 4, 5, 6];
    if (!audioEnded) {
      handleStartStory();
    }

    if (start.includes(currentAudio)) {
      toggleShowMenu(true);
    }
  };

  const renderSkipbutton = () => {
    if (currentAudio === 200) {
      const nextStory = getDayOfWeek();

      return (
        <StartStoryWrapper>
          <Button isActive onClick={() => goToNextStory(nextStory)}>
            Start met repareren
          </Button>
        </StartStoryWrapper>
      );
    } else if (!showOptions && audioPlaying) {
      return (
        <Button isActive positionFixed onClick={handleSkipStory}>
          Skip
        </Button>
      );
    }
  };

  return (
    <>
      {startStory && (
        <Wrapper id="story">
          {!cookies && currentAudio === 0 && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onPlay={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {!cookies && currentAudio === 1 && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onStart={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {!cookies && currentAudio === 2 && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onStart={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {!cookies && currentAudio === 3 && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onStart={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {!cookies && currentAudio === 4 && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onStart={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {!cookies && currentAudio === 5 && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onStart={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {!cookies && currentAudio === 6 && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onStart={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {currentAudio === 7 && !productSupplied && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onStart={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {currentAudio === 8 && !productSupplied && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onStart={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {currentAudio === 9 && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onStart={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {currentAudio === 10 && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onStart={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {currentAudio === 11 && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onStart={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {currentAudio === 12 && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onStart={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {currentAudio === 13 && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onStart={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {currentAudio === 14 && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onStart={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {currentAudio === 15 && (
            <ReactPlayer
              ref={player}
              url={`${process.env.PUBLIC_URL}/audio/${audio[currentAudio].file}`}
              playing={audioEnded ? false : audioPlaying}
              muted={soundMuted}
              onReady={() => handleAudioLoaded()}
              onStart={() => handleOnStart()}
              onEnded={() => handleEndStory()}
              onProgress={e => handleAudioProgress(e.playedSeconds)}
              progressInterval={50}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              width={'100%'}
              height={'100%'}
            />
          )}

          {story &&
            story.map((word, i) => {
              return (
                <Word
                  key={`word-${i}`}
                  isActive
                  option={word.option}
                  onClick={word.option && audioEnded && !storySkipping ? () => goBackToStory(word.story) : null}
                >
                  {word.word}
                </Word>
              );
            })}

          {renderSkipbutton()}

          {showOptions && <Options />}

          {showGiphy && <Giphy />}
        </Wrapper>
      )}
    </>
  );
};

export default Story;
