import styled from 'styled-components';

import colors from '../../style/colors';
import mq from '../../style/mq';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 15px;
  background-color: ${props => props.backgroundColor};
  transition: background-color 0.4s ease;

  ${mq.medium`
    padding-top: 40px;
    padding-bottom: 30px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.flex};
  align-items: center;
  width: 100%;
  height: 100%;
  height: 30px;

  ${mq.medium`
    height: 50px;
  `}
`;

export const Elements = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Element = styled.div`
  margin-right: 5px;
  cursor: ${props => (props.cursorPointer ? 'pointer' : 'auto')};

  svg {
    height: 20px;
  }

  ${mq.medium`
    margin-right: 20px;

    svg {
      height: 35px;
    }

    &:first-child {
      svg {
        height: 35px !important;
      }
    }
  `}
`;

export const Divider = styled.hr`
  border: 0;
  border-top: 4px solid ${colors.black};
  display: block;
  height: 0;
  width: ${props => (props.small ? 'calc(200% + 45px)' : '100%')};
  margin: 25px 0 0;
  position: absolute;
  bottom: 0;
  right: 0;

  ${mq.medium`
    border-top: 8px solid ${colors.black};
    width: ${props => (props.small ? 'calc(200% + 80px)' : '100%')};
  `}
`;
