export function startEvent() {
  if (window) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'sireButton',
      eventCategory: 'Button Click',
      eventAction: 'Fase: Start',
      eventLabel: 'Knop: Start',
    });
  }
}
