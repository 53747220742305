import styled from 'styled-components';

import fonts from '../../style/fonts';
import colors from '../../style/colors';
import mq from '../../style/mq';

export const ButtonEl = styled.div`
  display: inline-flex;
  height: auto;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  border-radius: 20px;
  background-color: ${props => (props.secondary ? colors.black : colors.white)};
  color: ${props => (props.secondary ? colors.white : colors.black)};
  z-index: 2;
  align-self: ${props => (props.positionFixed ? 'flex-end' : 'center')};
  position: ${props => (props.positionFixed ? 'fixed' : 'static')};
  bottom: ${props => (props.positionFixed ? '20px' : '0')};
  right: ${props => (props.positionFixed ? '20px' : '0')};
  border: 0;
  outline: none;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.4s ease, color 0.4s ease;
  box-shadow: 0 12px 40px -10px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;

  &:hover {
    background-color: ${props => (props.noHover || props.secondary ? colors.white : colors.black)};
    color: ${props => (props.noHover || props.secondary ? colors.black : colors.white)};
  }

  ${mq.medium`
    height: 80px;
    border-radius: 30px;
    padding: 0 40px;
    margin: ${props => (props.marginBottom ? '10px' : '0 10px')};
    bottom: ${props => (props.positionFixed ? '40px' : '0')};
    right: ${props => (props.positionFixed ? '40px' : '0')};
    
    &:last-child {
      margin: ${props => (props.marginBottom ? '10px' : '0 0 0 10px')};
    }
  `}
`;

export const Text = styled.span`
  font-family: ${fonts.RobotoBlack};
  font-size: 1.6em;

  ${mq.medium`
    font-size: 3em;
  `}
`;
