export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, value);
};

export const getLocalStorage = key => {
  const value = window.localStorage.getItem(key);
  if (value) {
    return value;
  }
  return false;
};

export const clearLocalStorage = key => {
  window.localStorage.removeItem(key);
};
