import React from 'react';

import { Paragraph } from './styles';

const Text = props => {
  return (
    <Paragraph onClick={props.onClick} style={props.style} small={props.small} marginBottom={props.marginBottom}>
      {props.children}
    </Paragraph>
  );
};

export default Text;
