import React, { useContext } from 'react';
import debounce from 'lodash/debounce';

import { VideosStore } from '../../stores/VideosStore';

import { Wrapper, InputField } from './styles';

const Input = props => {
  const { color } = useContext(VideosStore);

  const onChangeValue = debounce(value => {
    props.onChange(value);
  }, 500);

  return (
    <Wrapper backgroundColor={color}>
      <InputField type="text" placeholder="Zoek product..." onChange={e => onChangeValue(e.target.value)} />
    </Wrapper>
  );
};

export default Input;
