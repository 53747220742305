export function addOptionToStoryEvent(option, currentAudio) {
  if (window) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'sireButton',
      eventCategory: 'Button Click',
      eventAction: `Fase: ${getStoryTitle(currentAudio)}`,
      eventLabel: `Knop: ${option}`,
    });
  }
}

// function getDayTitle(currentAudio) {
//   switch (true) {
//     case currentAudio === 0:
//       return 'zondag';
//     case currentAudio === 1:
//       return 'maandag';
//     case currentAudio === 2:
//       return 'dinsdag';
//     case currentAudio === 3:
//       return 'woensdag';
//     case currentAudio === 4:
//       return 'donderdag';
//     case currentAudio === 5:
//       return 'vrijdag';
//     case currentAudio === 6:
//       return 'zaterdag';
//     default:
//       return '';
//   }
// }

function getStoryTitle(currentAudio) {
  const start = [0, 1, 2, 3, 4, 5, 6];

  switch (true) {
    case start.includes(currentAudio):
      return `Introductie`;
    case currentAudio === 7:
      return 'Iets repareren';
    case currentAudio === 8:
      return 'Reparatie Methode';
    case currentAudio === 9:
      return 'Zelf';
    case currentAudio === 15:
      return 'Weggooien';
    default:
      return '';
  }
}
