import React from 'react';

export const MapsContext = React.createContext({});

export function useMaps() {
  const context = React.useContext(MapsContext);

  if (!context) throw new Error(`useMaps must be used within a MapsProvider`);

  return context;
}
