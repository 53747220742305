import styled from 'styled-components';

import mq from '../../style/mq';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.backgroundColor};
  color: white;
  z-index: 2;
`;

export const Item = styled.div`
  display: block;
  max-height: 40px;

  svg {
    width: 280px;
  }

  ${mq.medium`
    max-height: 70px;

    svg {
      width: 600px;
    }
  `}
`;
