import styled from 'styled-components';

import fonts from '../../style/fonts';
import mq from '../../style/mq';

export const Paragraph = styled.span`
  display: block;
  font-family: ${fonts.RobotoBlack};
  font-size: ${props => (props.small ? '1.8em' : '3.2em')};
  line-height: 1.4em;
  word-wrap: break-word;
  margin-bottom: ${props => (props.marginBottom ? `${props.marginBottom}px` : '0')};

  ${mq.medium`
    line-height: 1.2em;
    font-size: ${props => (props.small ? '3.5em' : '5em')};
  `}

  ${mq.large`
    font-size: ${props => (props.small ? '4.5em' : '8em')};
  `}
`;
