import styled from 'styled-components';

import colors from '../../style/colors';
import mq from '../../style/mq';
import fonts from '../../style/fonts';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
`;

export const ContentWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 500px;
  width: calc(100% - 60px);
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
  background-color: ${colors.white};
  border-radius: 20px;
  box-shadow: 0 12px 40px -10px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  z-index: 99;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  width: 100%;

  > div {
    &:first-child {
      margin-bottom: 10px;
    }
  }
`;

export const Paragraph = styled.span`
  display: block;
  font-family: ${fonts.RobotoRegular};
  color: ${colors.black};
  font-size: 14px;
  line-height: 25px;
  word-wrap: break-word;
  opacity: 0.4;
  margin-bottom: 5px;

  ${mq.medium`
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 30px;
  `}
`;

export const Content = styled.div``;
