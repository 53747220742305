import React from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  overflow: visible;
  outline: none;
  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize \`line-height\`. Cannot be changed from \`normal\` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable \`input\` types in iOS */
  -webkit-appearance: none;

  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 200ms ease-out;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &[disabled] {
    opacity: 0.3;
  }
`;

export const LocationButton = ({ locationLoading, children, ...props }) => (
  <ButtonWrapper disabled={locationLoading} {...props}>
    {children}
  </ButtonWrapper>
);
