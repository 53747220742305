import React from 'react';
import { animated, useSpring, config } from 'react-spring/web.cjs';

import { ButtonEl, Text } from './styles';

const AnimatedButton = animated(ButtonEl);

const Button = props => {
  const {
    isActive,
    children,
    onClick,
    positionFixed,
    noHover,
    secondary,
    delayIndex,
    delaySpeed = 100,
    marginBottom,
    fullWidth,
  } = props;

  const { buttonOpacity, buttonXyz } = useSpring({
    from: { buttonOpacity: 0, buttonXyz: [0, 15, 0] },
    to: { buttonOpacity: isActive ? 1 : 0, buttonXyz: [0, isActive ? 0 : 15, 0] },
    config: config.wobbly,
    delay: delayIndex * delaySpeed,
  });

  const { opacity, xyz } = useSpring({
    from: { opacity: 0, xyz: [0, 15, 0] },
    to: { opacity: isActive ? 1 : 0, xyz: [0, isActive ? 0 : 15, 0] },
    config: config.wobbly,
    delay: 100,
  });

  return (
    <>
      <AnimatedButton
        style={{
          opacity: buttonOpacity,
          transform: buttonXyz.interpolate((x, y, z) => `translate3d(${x}px, ${y}px, ${z}px)`),
        }}
        onClick={onClick}
        positionFixed={positionFixed}
        noHover={noHover}
        secondary={secondary}
        marginBottom={marginBottom}
        fullWidth={fullWidth}
      >
        <animated.div
          style={{
            opacity: opacity,
            transform: xyz.interpolate((x, y, z) => `translate3d(${x}px, ${y}px, ${z}px)`),
          }}
        >
          <Text>{children}</Text>
        </animated.div>
      </AnimatedButton>
    </>
  );
};

export default Button;
