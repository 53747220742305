import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';

import { ReactComponent as TaglineRepareer } from '../../assets/svg/repareer-het.svg';
import { ReactComponent as TaglineWaardeer } from '../../assets/svg/waardeer-het.svg';

import { Wrapper, Tagline } from './styles';

const AnimatedTagline = animated(Tagline);

const Taglines = () => {
  const [toggle, set] = useState(false);
  const transition = useTransition(toggle, null, {
    from: { position: 'absolute', top: '50', opacity: 0, transform: 'translateY(-50%) translate3d(0, -15px, 0)' },
    enter: { opacity: 1, transform: 'translateY(-50%) translate3d(0, 0px, 0)' },
    leave: { opacity: 0, transform: 'translateY(-50%) translate3d(0, 15px, 0)' },
  });

  setTimeout(() => {
    set(!toggle);
  }, 2000);

  return (
    <Wrapper onClick={() => window.open('/', '_self')}>
      {transition.map(({ item, key, props }) =>
        item ? (
          <AnimatedTagline key={key} style={props}>
            <TaglineRepareer style={{ height: '40px' }} />
          </AnimatedTagline>
        ) : (
          <AnimatedTagline key={key} style={props}>
            <TaglineWaardeer style={{ height: '60px' }} />
          </AnimatedTagline>
        )
      )}
    </Wrapper>
  );
};

export default Taglines;
