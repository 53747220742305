import React from 'react';
import { useSpring, animated } from 'react-spring';
import { GoogleMap, LoadScript, MarkerClusterer, Marker } from '@react-google-maps/api';

import { useMaps } from '../../stores/maps';
import { repairCafes } from '../../data/repairCafes';

import checkIfMobile from '../../helpers/checkIfMobile';

import Button from '../../components/Button';

export const MapComponent = ({ center, zoom, locations }) => {
  const {
    setLocationData,
    setActiveMarker,
    mapOptions,
    markerOptions,
    mapInstance,
    setMapInstance,
    fetchPlaces,
    fetchPlaceDetails,
    setLocations,
    setMapConfig,
    mapActive,
    setMapActive,
    showLocations,
    query,
    fetchNewPlaces,
    firstLoad,
    setFirstLoad,
  } = useMaps();

  const isMobile = checkIfMobile();
  const padding = isMobile ? 40 : 80;
  const position = isMobile ? 20 : 40;

  const handleMarkerClick = (marker, location) => {
    if (query) {
      fetchPlaceDetails(location, mapInstance);
      mapInstance.panTo(marker.getPosition());
      setActiveMarker(marker);
    } else {
      mapInstance.panTo(marker.getPosition());
      setLocationData(location);
      setActiveMarker(marker);
    }
  };

  const handleMapsClick = () => {
    setActiveMarker(null);
  };

  const handleClusterClick = () => {
    setActiveMarker(null);
  };

  const handleOnLoad = map => {
    if (query) {
      fetchPlaces(query, map);
    } else {
      setMapConfig({
        center: {
          lat: 52.1824267,
          lng: 5.6359729,
        },
        zoom: 8,
      });
      setLocations(repairCafes);
    }

    setMapInstance(map);
    map.addListener('click', () => handleMapsClick(map));
  };

  const getIcon = () => {
    if (query && query.category === 'experts') {
      return isMobile ? `${process.env.PUBLIC_URL}/ShopWhiteSmall.png` : `${process.env.PUBLIC_URL}/ShopWhiteBig.png`;
    } else if (query && query.category === 'gereedschap') {
      return isMobile ? `${process.env.PUBLIC_URL}/ToolWhiteSmall.png` : `${process.env.PUBLIC_URL}/ToolWhiteBig.png`;
    } else {
      return isMobile ? `${process.env.PUBLIC_URL}/CafeWhiteSmall.png` : `${process.env.PUBLIC_URL}/CafeWhiteBig.png`;
    }
  };

  const spring = useSpring({
    borderRadius: mapActive ? '0px' : '20px',
    width: [100, mapActive ? 0 : padding],
    left: mapActive ? '0px' : `${position}px`,
    bottom: mapActive ? '0px' : `${position}px`,
    height: mapActive ? '100%' : isMobile ? '15%' : '25%',
  });

  return (
    <animated.div
      style={{
        position: 'fixed',
        overflow: 'hidden',
        boxSizing: 'border-box',
        borderRadius: spring.borderRadius,
        left: spring.left,
        bottom: spring.bottom,
        height: spring.height,
        width: spring.width.interpolate((percentage, pixels) => `calc(${percentage}% - ${pixels}px)`),
      }}
    >
      {!mapActive && (
        <div
          style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 3,
          }}
        >
          {query && (
            <Button
              isActive
              secondary
              onClick={() => {
                setMapActive(true);
                handleOnLoad(mapInstance);
              }}
            >
              Bekijk experts
            </Button>
          )}
          {!query && (
            <Button
              isActive
              secondary
              onClick={() => {
                setMapActive(true);
                handleOnLoad(mapInstance);
              }}
            >
              Bekijk RepairCafé’s
            </Button>
          )}
        </div>
      )}
      <LoadScript id="script-loader" googleMapsApiKey="AIzaSyBlZcCXZULEw7YGrBRCut77lX8QDVWlPpM" libraries={['places']}>
        <GoogleMap
          center={center}
          zoom={zoom}
          options={mapOptions}
          onLoad={map => handleOnLoad(map)}
          onIdle={() => {
            if (query) {
              firstLoad ? setFirstLoad(false) : fetchNewPlaces(query, mapInstance);
            }
          }}
          mapContainerStyle={{
            height: '100vh',
            width: '100%',
          }}
        >
          {query ? (
            <MarkerClusterer
              animation={2}
              zoomOnClick={true}
              gridSize={isMobile ? 70 : 100}
              styles={[markerOptions]}
              onClick={handleClusterClick}
            >
              {clusterer =>
                (showLocations && locations && locations.length) > 0 &&
                locations.map((location, index) => (
                  <Marker
                    icon={mapActive ? getIcon() : ''}
                    key={index}
                    position={{ lat: location.geometry.location.lat(), lng: location.geometry.location.lng() }}
                    clusterer={clusterer}
                    animation={2}
                    onLoad={marker => {
                      marker.addListener('click', () => handleMarkerClick(marker, location));
                    }}
                  />
                ))
              }
            </MarkerClusterer>
          ) : (
            <MarkerClusterer
              animation={2}
              zoomOnClick={true}
              gridSize={isMobile ? 70 : 100}
              styles={[markerOptions]}
              onClick={handleClusterClick}
            >
              {clusterer =>
                (showLocations && locations && locations.length) > 0 &&
                locations.map((location, index) => (
                  <Marker
                    icon={mapActive ? getIcon() : ''}
                    key={index}
                    position={{ lat: location.LATITUDE, lng: location.LONGITUDE }}
                    clusterer={clusterer}
                    animation={2}
                    onLoad={marker => {
                      marker.addListener('click', () => handleMarkerClick(marker, location));
                    }}
                  />
                ))
              }
            </MarkerClusterer>
          )}
        </GoogleMap>
      </LoadScript>
    </animated.div>
  );
};
