import React, { useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import ReactPlayer from 'react-player';
import MediaQuery from 'react-responsive';

import { GlobalStore } from '../../stores/GlobalStore';
import { VideosStore } from '../../stores/VideosStore';

import { Wrapper, VideoStyles } from './styles';

const AnimatedWrapper = animated(Wrapper);

const Video = () => {
  const { soundMuted, toggleShowMenu } = useContext(GlobalStore);
  const {
    toggleVideoLoading,
    handleVideoEnded,
    handleVideoProgress,
    currentVideo,
    playing,
    color,
    loading,
  } = useContext(VideosStore);
  const smallTabletOrBigger = 'only screen and (min-width: 500px)';

  const spring = useSpring({
    opacity: loading ? 0 : 1,
  });

  return (
    <AnimatedWrapper style={{ opacity: spring.opacity }} backgroundColor={color}>
      {currentVideo && (
        <MediaQuery query={smallTabletOrBigger}>
          {matches => (
            <ReactPlayer
              url={`${matches ? currentVideo.desktop : currentVideo.mobile}?quality=1080p`}
              playing={playing}
              muted={soundMuted}
              playsinline={true}
              onReady={() => toggleVideoLoading()}
              onEnded={() => handleVideoEnded()}
              progressInterval={100}
              onProgress={e => handleVideoProgress(e.playedSeconds, toggleShowMenu)}
              config={{
                vimeo: {
                  playerOptions: {
                    controls: false,
                    backgroundColor: color,
                    quality: '1080p',
                  },
                  preload: true,
                },
              }}
              width={'100%'}
              height={'100%'}
              className="video-player"
              style={{ VideoStyles }}
            />
          )}
        </MediaQuery>
      )}
    </AnimatedWrapper>
  );
};

export default Video;
