import React, { useContext } from 'react';

import { RepairStore } from '../../stores/RepairStore';
import { OverlayStore } from '../../stores/OverlayStore';
import { GlobalStore } from '../../stores/GlobalStore';

import Button from '../../components/Button';
import Text from '../../components/Text';

import { addSearchOptionToStoryEvent } from '../../helpers/analytics';

import { Wrapper } from './styles';

const SearchResults = () => {
  const { addToStory, setProduct, searchResults } = useContext(RepairStore);
  const { toggleOverlay } = useContext(OverlayStore);
  const { cookies } = useContext(GlobalStore);

  const handleOnClickSearchResult = product => {
    addToStory(product, true, 8, true, cookies);
    setProduct(product);
    toggleOverlay('search', true);

    if (!cookies) {
      addSearchOptionToStoryEvent(product);
    }
  };

  return (
    <Wrapper>
      {searchResults &&
        searchResults.map((result, index) => {
          return (
            <Button
              key={index}
              isActive
              marginBottom
              delaySpeed={index > 30 ? 20 : 100}
              delayIndex={index}
              onClick={!cookies ? () => handleOnClickSearchResult(result) : null}
            >
              {result}
            </Button>
          );
        })}
      {searchResults.length === 0 && <Text>Er zijn (nog) geen producten gevonden...</Text>}
    </Wrapper>
  );
};

export default SearchResults;
