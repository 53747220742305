import React from 'react';

import { Box } from './styles';

const Container = props => {
  return (
    <Box backgroundColor={props.backgroundColor} style={props.style}>
      {props.children}
    </Box>
  );
};

export default Container;
