import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Spinner = styled.div`
  border-radius: 50%;
  width: 5em;
  height: 5em;
  margin: 60px auto;
  position: relative;
  border-top: 1em solid rgba(0, 0, 0, 0.7);
  border-right: 1em solid rgba(0, 0, 0, 0.7);
  border-bottom: 1em solid rgba(0, 0, 0, 0.7);
  border-left: 1em solid ${props => `rgba(${props.color[0] + 20}, ${props.color[1] + 20}, ${props.color[2] + 20}, 0.7)`};
  transform: translateZ(0);
  animation: loader 1.1s infinite linear;

  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  @keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
