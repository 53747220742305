import React from 'react';
import Main from './Main';
import { withRouter } from 'react-router-dom';

import { GlobalStyle } from '../style/global';

import { GlobalProvider } from '../stores/GlobalStore';
import { VideosProvider } from '../stores/VideosStore';
import { OverlayProvider } from '../stores/OverlayStore';
import { RepairProvider } from '../stores/RepairStore';
import { MapsProvider } from '../stores/maps';

class App extends React.Component {
  render() {
    return (
      <GlobalProvider>
        <VideosProvider>
          <OverlayProvider>
            <RepairProvider>
              <MapsProvider>
                <Main />
              </MapsProvider>
              <GlobalStyle />
            </RepairProvider>
          </OverlayProvider>
        </VideosProvider>
      </GlobalProvider>
    );
  }
}

export default withRouter(props => <App {...props} />);
