const STORY_INTRO = 7;
const STORY_HOW_TO_FIX = 8;
const STORY_DO_IT_YOURSELF = 9;
const STORY_I_HAVE_EVERY_THING = 10;
const STORY_BORROW = 11;
const STORY_BUY = 12;
const STORY_GO_TO_REPAIR_CAFE = 13;
const STORY_LET_IT_FIX = 14;
const STORY_THROW_AWAY = 15;
// const STORY_BYE_BYE = 16;
const STORY_OPEN_ABOUT_OVERLAY = 98;
const STORY_OPEN_SEARCH_OVERLAY = 99;
const STORY_OPEN_LINK = 100;
const STORY_OPEN_MAPS_REPAIR = 101;
const STORY_OPEN_MAPS_QUERY_BUY = 102;
const STORY_OPEN_MAPS_QUERY_TOOLS = 103;

let time = 0;

/**
 * getStartingTime - get the time for showing the word
 *
 * @param {Number} nextTime - the time to add
 * @param {Boolean} reset - reset time for new audio instance
 */
const getStartingTime = (nextTime, reset = false) => {
  if (reset) {
    time = nextTime;
  } else {
    time = time + nextTime;
  }

  return time;
};

export const audio = [
  // 0 - …En repareren dat begint hier. In een paar simpele stappen help ik je op weg. Wat wil je doen op deze mooie zondag?
  {
    file: `sound-01-00.mp3`,
    words: [
      { start: getStartingTime(0.1, true), part: '...En' },
      { start: getStartingTime(0.3), part: 'repareren,' },
      { start: getStartingTime(0.5), part: 'dat' },
      { start: getStartingTime(0.2), part: 'begint' },
      { start: getStartingTime(0.2), part: 'hier.' },
      { start: getStartingTime(0.6), part: 'In' },
      { start: getStartingTime(0.2), part: 'een' },
      { start: getStartingTime(0.2), part: 'paar' },
      { start: getStartingTime(0.2), part: 'simpele' },
      { start: getStartingTime(0.2), part: 'stappen,' },
      { start: getStartingTime(0.5), part: 'help' },
      { start: getStartingTime(0.1), part: 'ik' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'op' },
      { start: getStartingTime(0.1), part: 'weg.' },
      { start: getStartingTime(0.6), part: 'Wat' },
      { start: getStartingTime(0.1), part: 'wil' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'doen' },
      { start: getStartingTime(0.5), part: 'op' },
      { start: getStartingTime(0.2), part: 'deze' },
      { start: getStartingTime(0.3), part: 'mooie' },
      { start: getStartingTime(0.3), part: 'zondag?' },
    ],
    options: [
      {
        text: 'Ik wil iets repareren!',
        nextStory: STORY_INTRO,
      },
      {
        text: 'Meer informatie',
        nextStory: STORY_OPEN_ABOUT_OVERLAY,
      },
    ],
  },
  // 1 - …En repareren dat begint hier. In een paar simpele stappen help ik je op weg. Wat wil je doen op deze mooie maandag?
  {
    file: `sound-01-01.mp3`,
    words: [
      { start: getStartingTime(0.1, true), part: '...En' },
      { start: getStartingTime(0.3), part: 'repareren,' },
      { start: getStartingTime(0.5), part: 'dat' },
      { start: getStartingTime(0.2), part: 'begint' },
      { start: getStartingTime(0.2), part: 'hier.' },
      { start: getStartingTime(0.6), part: 'In' },
      { start: getStartingTime(0.2), part: 'een' },
      { start: getStartingTime(0.2), part: 'paar' },
      { start: getStartingTime(0.2), part: 'simpele' },
      { start: getStartingTime(0.2), part: 'stappen,' },
      { start: getStartingTime(0.5), part: 'help' },
      { start: getStartingTime(0.1), part: 'ik' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'op' },
      { start: getStartingTime(0.1), part: 'weg.' },
      { start: getStartingTime(0.6), part: 'Wat' },
      { start: getStartingTime(0.1), part: 'wil' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'doen' },
      { start: getStartingTime(0.5), part: 'op' },
      { start: getStartingTime(0.2), part: 'deze' },
      { start: getStartingTime(0.3), part: 'mooie' },
      { start: getStartingTime(0.3), part: 'maandag?' },
    ],
    options: [
      {
        text: 'Ik wil iets repareren!',
        nextStory: STORY_INTRO,
      },
      {
        text: 'Meer informatie',
        nextStory: STORY_OPEN_ABOUT_OVERLAY,
      },
    ],
  },
  // 2 - …En repareren dat begint hier. In een paar simpele stappen help ik je op weg. Wat wil je doen op deze mooie dinsdag?
  {
    file: `sound-01-02.mp3`,
    words: [
      { start: getStartingTime(0.1, true), part: '...En' },
      { start: getStartingTime(0.3), part: 'repareren,' },
      { start: getStartingTime(0.5), part: 'dat' },
      { start: getStartingTime(0.2), part: 'begint' },
      { start: getStartingTime(0.2), part: 'hier.' },
      { start: getStartingTime(0.6), part: 'In' },
      { start: getStartingTime(0.2), part: 'een' },
      { start: getStartingTime(0.2), part: 'paar' },
      { start: getStartingTime(0.2), part: 'simpele' },
      { start: getStartingTime(0.2), part: 'stappen,' },
      { start: getStartingTime(0.5), part: 'help' },
      { start: getStartingTime(0.1), part: 'ik' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'op' },
      { start: getStartingTime(0.1), part: 'weg.' },
      { start: getStartingTime(0.6), part: 'Wat' },
      { start: getStartingTime(0.1), part: 'wil' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'doen' },
      { start: getStartingTime(0.5), part: 'op' },
      { start: getStartingTime(0.2), part: 'deze' },
      { start: getStartingTime(0.3), part: 'mooie' },
      { start: getStartingTime(0.3), part: 'dinsdag?' },
    ],
    options: [
      {
        text: 'Ik wil iets repareren!',
        nextStory: STORY_INTRO,
      },
      {
        text: 'Meer informatie',
        nextStory: STORY_OPEN_ABOUT_OVERLAY,
      },
    ],
  },
  // 3 - …En repareren dat begint hier. In een paar simpele stappen help ik je op weg. Wat wil je doen op deze mooie maandag?
  {
    file: `sound-01-03.mp3`,
    words: [
      { start: getStartingTime(0.1, true), part: '...En' },
      { start: getStartingTime(0.3), part: 'repareren,' },
      { start: getStartingTime(0.5), part: 'dat' },
      { start: getStartingTime(0.2), part: 'begint' },
      { start: getStartingTime(0.2), part: 'hier.' },
      { start: getStartingTime(0.6), part: 'In' },
      { start: getStartingTime(0.2), part: 'een' },
      { start: getStartingTime(0.2), part: 'paar' },
      { start: getStartingTime(0.2), part: 'simpele' },
      { start: getStartingTime(0.2), part: 'stappen,' },
      { start: getStartingTime(0.5), part: 'help' },
      { start: getStartingTime(0.1), part: 'ik' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'op' },
      { start: getStartingTime(0.1), part: 'weg.' },
      { start: getStartingTime(0.6), part: 'Wat' },
      { start: getStartingTime(0.1), part: 'wil' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'doen' },
      { start: getStartingTime(0.5), part: 'op' },
      { start: getStartingTime(0.2), part: 'deze' },
      { start: getStartingTime(0.3), part: 'prachtige' },
      { start: getStartingTime(0.3), part: 'woensdag?' },
    ],
    options: [
      {
        text: 'Ik wil iets repareren!',
        nextStory: STORY_INTRO,
      },
      {
        text: 'Meer informatie',
        nextStory: STORY_OPEN_ABOUT_OVERLAY,
      },
    ],
  },
  // 4 - …En repareren dat begint hier. In een paar simpele stappen help ik je op weg. Wat wil je doen op deze mooie donderdag?
  {
    file: `sound-01-04.mp3`,
    words: [
      { start: getStartingTime(0.1, true), part: '...En' },
      { start: getStartingTime(0.3), part: 'repareren,' },
      { start: getStartingTime(0.5), part: 'dat' },
      { start: getStartingTime(0.2), part: 'begint' },
      { start: getStartingTime(0.2), part: 'hier.' },
      { start: getStartingTime(0.6), part: 'In' },
      { start: getStartingTime(0.2), part: 'een' },
      { start: getStartingTime(0.2), part: 'paar' },
      { start: getStartingTime(0.2), part: 'simpele' },
      { start: getStartingTime(0.2), part: 'stappen,' },
      { start: getStartingTime(0.5), part: 'help' },
      { start: getStartingTime(0.1), part: 'ik' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'op' },
      { start: getStartingTime(0.1), part: 'weg.' },
      { start: getStartingTime(0.6), part: 'Wat' },
      { start: getStartingTime(0.1), part: 'wil' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'doen' },
      { start: getStartingTime(0.5), part: 'op' },
      { start: getStartingTime(0.2), part: 'deze' },
      { start: getStartingTime(0.3), part: 'mooie' },
      { start: getStartingTime(0.3), part: 'donderdag?' },
    ],
    options: [
      {
        text: 'Ik wil iets repareren!',
        nextStory: STORY_INTRO,
      },
      {
        text: 'Meer informatie',
        nextStory: STORY_OPEN_ABOUT_OVERLAY,
      },
    ],
  },
  // 5 - …En repareren dat begint hier. In een paar simpele stappen help ik je op weg. Wat wil je doen op deze mooie vrijdag?
  {
    file: `sound-01-05.mp3`,
    words: [
      { start: getStartingTime(0.1, true), part: '...En' },
      { start: getStartingTime(0.3), part: 'repareren,' },
      { start: getStartingTime(0.5), part: 'dat' },
      { start: getStartingTime(0.2), part: 'begint' },
      { start: getStartingTime(0.2), part: 'hier.' },
      { start: getStartingTime(0.6), part: 'In' },
      { start: getStartingTime(0.2), part: 'een' },
      { start: getStartingTime(0.2), part: 'paar' },
      { start: getStartingTime(0.2), part: 'simpele' },
      { start: getStartingTime(0.2), part: 'stappen,' },
      { start: getStartingTime(0.5), part: 'help' },
      { start: getStartingTime(0.1), part: 'ik' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'op' },
      { start: getStartingTime(0.1), part: 'weg.' },
      { start: getStartingTime(0.6), part: 'Wat' },
      { start: getStartingTime(0.4), part: 'wil' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'doen' },
      { start: getStartingTime(0.5), part: 'op' },
      { start: getStartingTime(0.2), part: 'deze' },
      { start: getStartingTime(0.3), part: 'mooie' },
      { start: getStartingTime(0.2), part: 'vrijdag?' },
    ],
    options: [
      {
        text: 'Ik wil iets repareren!',
        nextStory: STORY_INTRO,
      },
      {
        text: 'Meer informatie',
        nextStory: STORY_OPEN_ABOUT_OVERLAY,
      },
    ],
  },
  // 6 - …En repareren dat begint hier. In een paar simpele stappen help ik je op weg. Wat wil je doen op deze mooie zaterdag?
  {
    file: `sound-01-06.mp3`,
    words: [
      { start: getStartingTime(0.1, true), part: '...En' },
      { start: getStartingTime(0.3), part: 'repareren,' },
      { start: getStartingTime(0.5), part: 'dat' },
      { start: getStartingTime(0.2), part: 'begint' },
      { start: getStartingTime(0.2), part: 'hier.' },
      { start: getStartingTime(0.6), part: 'In' },
      { start: getStartingTime(0.2), part: 'een' },
      { start: getStartingTime(0.2), part: 'paar' },
      { start: getStartingTime(0.2), part: 'simpele' },
      { start: getStartingTime(0.2), part: 'stappen,' },
      { start: getStartingTime(0.5), part: 'help' },
      { start: getStartingTime(0.1), part: 'ik' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'op' },
      { start: getStartingTime(0.1), part: 'weg.' },
      { start: getStartingTime(0.6), part: 'Wat' },
      { start: getStartingTime(0.1), part: 'wil' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'doen' },
      { start: getStartingTime(0.5), part: 'op' },
      { start: getStartingTime(0.2), part: 'deze' },
      { start: getStartingTime(0.3), part: 'mooie' },
      { start: getStartingTime(0.3), part: 'zaterdag?' },
    ],
    options: [
      {
        text: 'Ik wil iets repareren!',
        nextStory: STORY_INTRO,
      },
      {
        text: 'Meer informatie',
        nextStory: STORY_OPEN_ABOUT_OVERLAY,
      },
    ],
  },
  // 7 - Fantastisch! Repareren is goed voor je portemonnee, voor de wereld en voor je trots. Wat wil je repareren?
  {
    file: 'sound-02.mp3',
    words: [
      { start: getStartingTime(0.1, true), part: 'Fantastisch!' },
      { start: getStartingTime(0.7), part: 'Repareren' },
      { start: getStartingTime(0.6), part: 'is' },
      { start: getStartingTime(0.2), part: 'goed' },
      { start: getStartingTime(0.1), part: 'voor' },
      { start: getStartingTime(0.1), part: 'de' },
      { start: getStartingTime(0.1), part: 'portemonnee,' },
      { start: getStartingTime(0.8), part: 'voor' },
      { start: getStartingTime(0.1), part: 'het' },
      { start: getStartingTime(0.1), part: 'milieu' },
      { start: getStartingTime(0.8), part: 'en' },
      { start: getStartingTime(0.6), part: 'voor' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'trots.' },
      { start: getStartingTime(0.5), part: 'Wat' },
      { start: getStartingTime(0.1), part: 'wil' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'repareren?' },
    ],
    options: [
      {
        text: 'Broek',
        nextStory: STORY_HOW_TO_FIX,
        product: true,
      },
      {
        text: 'Speelgoed',
        nextStory: STORY_HOW_TO_FIX,
        product: true,
      },
      {
        text: 'Fiets',
        nextStory: STORY_HOW_TO_FIX,
        product: true,
      },
      {
        text: 'Koffer',
        nextStory: STORY_HOW_TO_FIX,
        product: true,
      },
      {
        text: 'Anders...',
        nextStory: STORY_OPEN_SEARCH_OVERLAY,
      },
    ],
  },
  // 8 - Wat heb je daar meegedaan joh? Goed, dat is niet mijn zaak.
  // Jij waardeert je spullen. En dat waardeer ik. Hoe wil je het repareren?
  {
    file: 'sound-04.mp3',
    words: [
      { start: getStartingTime(0.1, true), part: 'Wat' },
      { start: getStartingTime(0.1), part: 'heb' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'daarmee' },
      { start: getStartingTime(0.1), part: 'gedaan?' },
      { start: getStartingTime(0.7), part: 'Goed,' },
      { start: getStartingTime(0.2), part: 'dat' },
      { start: getStartingTime(0.1), part: 'is' },
      { start: getStartingTime(0.2), part: 'niet' },
      { start: getStartingTime(0.1), part: 'mijn' },
      { start: getStartingTime(0.1), part: 'zaak.' },
      { start: getStartingTime(0.6), part: 'Jij' },
      { start: getStartingTime(0.2), part: 'waardeert' },
      { start: getStartingTime(0.2), part: 'je' },
      { start: getStartingTime(0.2), part: 'spullen.' },
      { start: getStartingTime(0.5), part: 'En' },
      { start: getStartingTime(0.4), part: 'dat' },
      { start: getStartingTime(0.1), part: 'waardeer' },
      { start: getStartingTime(0.1), part: 'ik.' },
      { start: getStartingTime(0.8), part: 'Hoe' },
      { start: getStartingTime(0.2), part: 'wil' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'het' },
      { start: getStartingTime(0.1), part: 'repareren?' },
    ],
    options: [
      {
        text: 'Zelf doen',
        nextStory: STORY_DO_IT_YOURSELF,
      },
      {
        text: 'Samen doen',
        nextStory: STORY_GO_TO_REPAIR_CAFE,
      },
      {
        text: 'Laten doen',
        nextStory: STORY_LET_IT_FIX,
      },
      {
        text: 'Weggooien',
        nextStory: STORY_THROW_AWAY,
      },
    ],
  },
  // 9 - Je hebt de reparatie spirit. Maar heb je ook het gereedschap en onderdelen om het te repareren?
  {
    file: 'sound-05.mp3',
    words: [
      { start: getStartingTime(0.1, true), part: 'Aha,' },
      { start: getStartingTime(0.7), part: 'je' },
      { start: getStartingTime(0.1), part: 'hebt' },
      { start: getStartingTime(0.2), part: 'de' },
      { start: getStartingTime(0.2), part: 'reparatie' },
      { start: getStartingTime(0.4), part: 'spirit.' },
      { start: getStartingTime(0.8), part: 'Maar' },
      { start: getStartingTime(0.2), part: 'heb' },
      { start: getStartingTime(0.1), part: 'je' },
      { start: getStartingTime(0.1), part: 'ook' },
      { start: getStartingTime(0.1), part: 'het' },
      { start: getStartingTime(0.1), part: 'gereedschap' },
      { start: getStartingTime(0.35), part: 'en' },
      { start: getStartingTime(0.35), part: 'de' },
      { start: getStartingTime(0.1), part: 'onderdelen' },
      { start: getStartingTime(0.35), part: 'om' },
      { start: getStartingTime(0.1), part: 'het' },
      { start: getStartingTime(0.1), part: 'te' },
      { start: getStartingTime(0.1), part: 'fixen?' },
    ],
    options: [
      {
        text: 'Ik heb alles',
        nextStory: STORY_I_HAVE_EVERY_THING,
      },
      {
        text: 'Lenen',
        nextStory: STORY_BORROW,
      },
      {
        text: 'Kopen',
        nextStory: STORY_BUY,
      },
    ],
  },
  // 10 - Als ik handen had zou ik je high-fiven! Hoe kan ik je het beste helpen?
  {
    file: 'sound-05a-newest.mp3',
    words: [
      { start: getStartingTime(0.05, true), part: 'Als' },
      { start: getStartingTime(0.1), part: 'ik' },
      { start: getStartingTime(0.1), part: 'handen' },
      { start: getStartingTime(0.1), part: 'had' },
      { start: getStartingTime(0.4), part: 'zou' },
      { start: getStartingTime(0.2), part: 'ik' },
      { start: getStartingTime(0.2), part: 'je' },
      { start: getStartingTime(0.2), part: 'high-fiven!' },
      { start: getStartingTime(0.8), part: 'Zie' },
      { start: getStartingTime(0.2), part: 'hieronder' },
      { start: getStartingTime(0.2), part: 'de' },
      { start: getStartingTime(0.3), part: 'beste' },
      { start: getStartingTime(0.2), part: 'instructie' },
      { start: getStartingTime(0.2), part: `video's.` },
    ],
    options: [
      {
        title: 'YouTube',
        text: 'Foto en video',
        nextStory: STORY_OPEN_LINK,
        link: 'youtube',
      },
    ],
  },
  // 11 - Beter een goede buur dan een gereedschaploze vriend. Kijk daarom even hier:
  {
    file: 'sound-05b.mp3',
    words: [
      { start: getStartingTime(0.1, true), part: 'Beter' },
      { start: getStartingTime(0.1), part: 'een' },
      { start: getStartingTime(0.1), part: 'goede' },
      { start: getStartingTime(0.1), part: 'buur' },
      { start: getStartingTime(0.6), part: 'dan' },
      { start: getStartingTime(0.1), part: 'een' },
      { start: getStartingTime(0.1), part: 'gereedschaploze' },
      { start: getStartingTime(0.5), part: 'vriend.' },
      { start: getStartingTime(1), part: 'Kijk' },
      { start: getStartingTime(0.15), part: 'maar' },
      { start: getStartingTime(0.15), part: 'eens' },
      { start: getStartingTime(0.15), part: 'op' },
      { start: getStartingTime(0.15), part: 'Peerby.' },
      { start: getStartingTime(0.9), part: 'Je' },
      { start: getStartingTime(0.2), part: 'kunt' },
      { start: getStartingTime(0.2), part: 'hier' },
      { start: getStartingTime(0.2), part: 'heel' },
      { start: getStartingTime(0.2), part: 'gemakkelijk' },
      { start: getStartingTime(0.3), part: 'gereedschap' },
      { start: getStartingTime(0.3), part: 'lenen' },
      { start: getStartingTime(0.5), part: 'of' },
      { start: getStartingTime(0.1), part: 'huren,' },
      { start: getStartingTime(0.5), part: 'bij' },
      { start: getStartingTime(0.15), part: 'jou' },
      { start: getStartingTime(0.15), part: 'in' },
      { start: getStartingTime(0.15), part: 'de' },
      { start: getStartingTime(0.15), part: 'buurt.' },
    ],
    options: [
      {
        title: 'Peerby',
        text: 'Lenen & huren van je buren',
        nextStory: STORY_OPEN_LINK,
        link: 'peerby',
      },
    ],
  },
  // 12 - Goed gereedschap is het halve werk. Hier vind je de juiste spullen voor je reparatie
  {
    file: 'sound-05c.mp3',
    words: [
      { start: getStartingTime(0.2, true), part: 'Goed' },
      { start: getStartingTime(0.2), part: 'gereedschap' },
      { start: getStartingTime(0.4), part: 'is' },
      { start: getStartingTime(0.2), part: 'het' },
      { start: getStartingTime(0.2), part: 'halve' },
      { start: getStartingTime(0.2), part: 'werk.' },
      { start: getStartingTime(0.6), part: 'Hier' },
      { start: getStartingTime(0.3), part: 'vind' },
      { start: getStartingTime(0.2), part: 'je' },
      { start: getStartingTime(0.2), part: 'de' },
      { start: getStartingTime(0.2), part: 'juiste' },
      { start: getStartingTime(0.2), part: 'spullen' },
      { start: getStartingTime(0.2), part: 'voor' },
      { start: getStartingTime(0.2), part: 'je' },
      { start: getStartingTime(0.2), part: 'reparatie.' },
    ],
    options: [
      {
        title: 'Gereedschap lenen',
        text: 'Ga naar locaties',
        nextStory: STORY_OPEN_MAPS_QUERY_TOOLS,
        link: 'google-maps-gereedschap',
      },
    ],
  },
  // 13 - Bij het Repair Café zitten reparateurs die samen met jou je product repareren. Leuk, makkelijk én leerzaam!
  {
    file: 'sound-06-new.mp3',
    words: [
      { start: getStartingTime(0.08, true), part: 'Bij' },
      { start: getStartingTime(0.15), part: 'het' },
      { start: getStartingTime(0.15), part: 'Repair' },
      { start: getStartingTime(0.1), part: 'Café' },
      { start: getStartingTime(0.4), part: 'zitten' },
      { start: getStartingTime(0.2), part: 'reparateurs' },
      { start: getStartingTime(0.6), part: 'die' },
      { start: getStartingTime(0.1), part: 'samen' },
      { start: getStartingTime(0.1), part: 'met' },
      { start: getStartingTime(0.1), part: 'jou' },
      { start: getStartingTime(0.4), part: 'je' },
      { start: getStartingTime(0.2), part: 'producten' },
      { start: getStartingTime(0.2), part: 'repareren.' },
      { start: getStartingTime(0.8), part: 'Leuk,' },
      { start: getStartingTime(0.6), part: 'makkelijk' },
      { start: getStartingTime(0.6), part: 'én' },
      { start: getStartingTime(0.2), part: 'leerzaam!' },
      { start: getStartingTime(0.8), part: 'Bij' },
      { start: getStartingTime(0.2), part: 'deze' },
      { start: getStartingTime(0.4), part: 'de' },
      { start: getStartingTime(0.2), part: 'dichtsbijzijnde' },
      { start: getStartingTime(0.3), part: 'Repair' },
      { start: getStartingTime(0.3), part: 'Cafés.' },
    ],
    options: [
      {
        title: 'Repair Cafés',
        text: 'Bekijk locaties',
        nextStory: STORY_OPEN_MAPS_REPAIR,
        link: 'google-maps-repair-cafe',
      },
    ],
  },
  // 14 - Ja, je kunt niet overal goed in zijn. Gelukkig vind je hier genoeg experts bij jou in de buurt die je kunnen helpen
  {
    file: 'sound-07.mp3',
    words: [
      { start: getStartingTime(0.08, true), part: 'Ja,' },
      { start: getStartingTime(0.3), part: 'je' },
      { start: getStartingTime(0.15), part: 'kunt' },
      { start: getStartingTime(0.15), part: 'niet' },
      { start: getStartingTime(0.15), part: 'overal' },
      { start: getStartingTime(0.15), part: 'goed' },
      { start: getStartingTime(0.15), part: 'in' },
      { start: getStartingTime(0.15), part: 'zijn.' },
      { start: getStartingTime(0.4), part: 'Gelukkig' },
      { start: getStartingTime(0.4), part: 'vind' },
      { start: getStartingTime(0.15), part: 'je' },
      { start: getStartingTime(0.15), part: 'hier' },
      { start: getStartingTime(0.15), part: 'genoeg' },
      { start: getStartingTime(0.15), part: 'experts' },
      { start: getStartingTime(0.5), part: 'bij' },
      { start: getStartingTime(0.15), part: 'jou' },
      { start: getStartingTime(0.15), part: 'in' },
      { start: getStartingTime(0.15), part: 'de' },
      { start: getStartingTime(0.15), part: 'buurt' },
      { start: getStartingTime(0.5), part: 'die' },
      { start: getStartingTime(0.15), part: 'je' },
      { start: getStartingTime(0.15), part: 'kunnen' },
      { start: getStartingTime(0.15), part: 'helpen.' },
    ],
    options: [
      {
        title: 'Vraag hulp',
        text: 'Bekijk experts',
        nextStory: STORY_OPEN_MAPS_QUERY_BUY,
        link: 'google-maps',
      },
    ],
  },
  // 15 - Weggooien? Wij gaan zo geen vrienden worden. Met een klein beetje moeite breng je
  // het naar het dichtstbijzijnde milieustraat en kan het gerecycled worden.
  {
    file: 'sound-08.mp3',
    words: [
      { start: getStartingTime(0.1, true), part: 'Weggooien?' },
      { start: getStartingTime(0.7), part: 'Wij' },
      { start: getStartingTime(0.15), part: 'gaan' },
      { start: getStartingTime(0.15), part: 'zo' },
      { start: getStartingTime(0.15), part: 'geen' },
      { start: getStartingTime(0.15), part: 'vrienden' },
      { start: getStartingTime(0.4), part: 'worden.' },
      { start: getStartingTime(0.8), part: 'Met' },
      { start: getStartingTime(0.15), part: 'een' },
      { start: getStartingTime(0.15), part: 'klein' },
      { start: getStartingTime(0.15), part: 'beetje' },
      { start: getStartingTime(0.15), part: 'moeite' },
      { start: getStartingTime(0.5), part: 'breng' },
      { start: getStartingTime(0.15), part: 'je' },
      { start: getStartingTime(0.15), part: 'het' },
      { start: getStartingTime(0.15), part: 'naar' },
      { start: getStartingTime(0.15), part: 'de' },
      { start: getStartingTime(0.15), part: 'dichtstbijzijnde' },
      { start: getStartingTime(0.5), part: 'milieustraat' },
      { start: getStartingTime(0.8), part: 'en' },
      { start: getStartingTime(0.2), part: 'kan' },
      { start: getStartingTime(0.2), part: 'het' },
      { start: getStartingTime(0.2), part: 'gerecycled' },
      { start: getStartingTime(0.25), part: 'worden.' },
    ],
    options: [
      {
        text: 'Nee, ik wil toch repareren!',
        nextStory: STORY_INTRO,
      },
    ],
  },
];
