import styled from 'styled-components';

import mq from '../../style/mq';

export const Wrapper = styled.div`
  display: block;
  cursor: pointer;
`;

export const Tagline = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-right: 20px;

  svg {
    width: 150px;
  }

  ${mq.medium`
    svg {
      width: 250px;
      height: 100%;
    }
  `}
`;
