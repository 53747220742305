import React from 'react';
import { animated, useTransition } from 'react-spring/web.cjs';

import { ReactComponent as YouTubeLogo } from '../../assets/svg/youtube.svg';
import { ReactComponent as PeerbyLogo } from '../../assets/svg/peerby.svg';

import { CardWrapper, ContentWrapper, TitlesWrapper, Title, SubTitle, SmallButton, LinkElement } from './styles';

const AnimatedButton = animated(CardWrapper);

const ButtonLink = props => {
  const { isActive, onClick, positionFixed, option, to } = props;

  const transitions = useTransition(isActive, null, {
    from: { opacity: 0, transform: 'scale(0.7)' },
    enter: [{ opacity: 1, transform: 'scale(1)' }],
    leave: [{ opacity: 0, transform: 'scale(0.7)' }],
    config: {
      tension: 100,
      friction: 15,
    },
  });

  return (
    <>
      {to
        ? transitions.map(({ props: { ...rest }, key }) => (
            <LinkElement key={`link-element-${key}`} to={to}>
              <AnimatedButton key={key} style={rest} onClick={onClick} positionFixed={positionFixed}>
                <ContentWrapper>
                  {option.link === 'youtube' && <YouTubeLogo />}
                  {option.link === 'peerby' && <PeerbyLogo />}
                  <TitlesWrapper>
                    <Title>{option.link}</Title>
                    <SubTitle>{option.text}</SubTitle>
                  </TitlesWrapper>
                </ContentWrapper>
                <SmallButton>Bekijk op {option.link}</SmallButton>
              </AnimatedButton>
            </LinkElement>
          ))
        : transitions.map(({ props: { ...rest }, key }) => (
            <AnimatedButton key={key} style={rest} onClick={onClick} positionFixed={positionFixed}>
              <ContentWrapper>
                {option.link === 'youtube' && <YouTubeLogo />}
                {option.link === 'peerby' && <PeerbyLogo />}
                <TitlesWrapper>
                  <Title>{option.title}</Title>
                  <SubTitle>{option.text}</SubTitle>
                </TitlesWrapper>
              </ContentWrapper>
              {option.link === 'youtube' && <SmallButton>Bekijk tutorials</SmallButton>}
              {option.link === 'peerby' && <SmallButton>Bekijk op {option.title}</SmallButton>}
              {option.link !== 'youtube' && option.link !== 'peerby' && <SmallButton>Bekijken</SmallButton>}
            </AnimatedButton>
          ))}
    </>
  );
};

export default ButtonLink;
